// src/components/Footer.js
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

// Styled-components for the footer layout
const FooterContainer = styled.div`
  background-color: #222;
  color: #fff;
  padding: 20px 0;
  text-align: center;
  position: relative;
  bottom: 0;
  width: 100%;
`;

const FooterLinks = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 10px;

  a {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s;
    
    &:hover {
      color: #ff6347; /* Tomato color on hover */
    }
  }
`;

const CopyrightText = styled.p`
  font-size: 14px;
  margin: 0;
`;

const Footer = () => (
  <FooterContainer>
    <FooterLinks>
      <Link to="/terms-and-conditions">Terms and Conditions</Link>
      <Link to="/privacy-policy">Privacy Policy</Link>
      <Link to="/refund-policy">Refund Policy</Link>
      <Link to="/aboutus">Contact Us</Link>
    </FooterLinks>
    <CopyrightText>© 2024 Hubio. All Rights Reserved.</CopyrightText>
  </FooterContainer>
);

export default Footer;
