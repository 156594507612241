import React, { useEffect } from 'react';

const RefundPolicy = () => {
  // Ensure the page scrolls to the top on mount
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      style={{
        padding: '20px',
        maxWidth: '800px',
        margin: 'auto',
        textAlign: 'left',
        lineHeight: '1.6',
      }}
    >
      <h1>Refund Policy</h1>

      <h2>1. Refund Eligibility</h2>
      <ul>
        <li>
          <strong>Service Not Provided:</strong> If the car wash service is not provided as agreed upon.
        </li>
        <li>
          <strong>Unsatisfactory Service:</strong> If the service is deemed unsatisfactory and cannot be resolved.
        </li>
        <li>
          <strong>Technical Issues:</strong> If technical issues prevent you from booking or using the service.
        </li>
      </ul>

      <h2>2. Refund Process</h2>
      <p>
        To request a refund, contact us within 7 days of the scheduled service. You may need to provide evidence of the
        issue. Refunds will typically be processed to the original payment method used.
      </p>

      <h2>3. Refund Amount</h2>
      <p>The refund amount may vary based on the circumstances. You may receive a full or partial refund.</p>
      <p>Refunds will be processed within 7 working days from the approval date.</p>


      <h2>4. Non-Refundable Items</h2>
      <p>Certain fees, such as booking or processing fees, may not be refundable.</p>

      <h2>5. Exceptions</h2>
      <p>
        Refunds may not be granted for issues beyond our control, such as extreme weather conditions or unforeseen events.
      </p>

      <h2>6. Contact Information</h2>
      <p>
        For refund requests or inquiries, contact us at:
      </p>
      <address>
        <strong>Hubio, 14, Seethammapeta Main Rd,<br /> 
        Dwaraka Nagar, Visakhapatnam, Andhra Pradesh 530016</strong>
      </address>

      <p>Last Updated: 15 October 2024</p>
    </div>
  );
};

export default RefundPolicy;
