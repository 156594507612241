import React, { useState, useEffect } from 'react'
import { useDevice } from '../../Components/Context/deviceContext'
import { LoadingSpinner } from '../../Components/LoadingSpinner '
import styled from 'styled-components'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import pleaselogin from '../../Components/Assets/pleaselogin.svg'
import profile from '../../Components/Assets/profile.svg'
import Footer from '../../Components/fotter'
import { Dialog } from '@mui/material'
import { useLiveLocationTracking } from '../../Components/useLiveLocationTracking'

const GridContainer = styled.div`
  display: grid;
  height: auto;
  grid-template-columns: repeat(1, 1fr);
  @media (min-width: 501px) and (max-width: 1200px) {
    margin-top: 2vh;
    grid-template-columns: repeat(1, 1fr);
    gap: 2vh;
  }
  @media (max-width: 500px) {
    margin-top: 2vh;
    grid-template-columns: repeat(1, 1fr);
    gap: 2vh;
  }
`

const GridItem = styled.div`
  border: 1.5px solid #faba49;
  border-radius: 3vw;
  display: flex;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 2vw;
  @media (min-width: 501px) and (max-width: 1200px) {
    border-radius: 4vw;
    padding: 4vw;
     width: 88%;
  }

  @media (max-width: 500px) {
    border-radius: 7vw;
    padding: 5vw;
     width: 88%;
  }
`
const StyledDiv4 = styled.div`
  margin-left: 5%;
  margin-top: 2.5vh;
  font-size: 6vw;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: #faba49;
  }
`

const StyledButton1 = styled.button`
  cursor: pointer;
  padding: 0rem 1.2rem;
  height: 32px;
  font-family: inherit;
  font-size: inherit;
  position: relative;
  letter-spacing: 0.05rem;
  font-weight: 400;
  font-size: 16px;
  border-radius: 400px;
  border: 2px solid #faba49;
  overflow: hidden;
  background: #faba49;
  color: black;
  transition: color 0.4s;
  position: relative;

  &::before,
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    content: '';
  }

  &::before {
    background: #fadaa1;

    width: 120%;
    left: -10%;
    transform: skew(30deg);
    transition: transform 0.4s cubic-bezier(0.3, 1, 0.8, 1);
  }

  &:hover::before {
    transform: translate3d(100%, 0, 0);
  }

  &:hover span {
    color: black;
  }

  span {
    position: relative;
    z-index: 10;
    transition: color 0.4s;
  }
`

const Heading1 = styled.div`
  font-size: 2vw;
  font-weight: 400;
  @media (min-width: 501px) and (max-width: 1200px) {
    font-size: 3.8vw;
    font-wieght: 600;
    letter-spacing: 0.5vw;
  }
  @media (max-width: 500px) {
    font-size: 4.7vw;
    font-wieght: 700;
    letter-spacing: 0.5vw;
  }
`

const GridItem1 = styled.div`
  border: 1.5px solid #faba49;
  border-radius: 3vw;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 2vw;
  width: 32vw;
  @media (min-width: 501px) and (max-width: 1200px) {
    border-radius: 4vw;
    padding: 4vw;
    width: 88%;
  }

  @media (max-width: 500px) {
    border-radius: 7vw;
    padding: 5vw;
    width: 88%;
  }
`

const BookingDetails = styled.div`
  font-size: 1.2rem;
  margin: 10px 0;
`

const Button = styled.button`
  padding: 0.5vw 2vw;
  width: 9.5vw;
  font-size: 1.2vw;
  cursor: pointer;
  background-color: ${(props) => (props.selected ? '#FADAA1' : '#fff')};
  border: 2px solid #faba49;
  border-radius: 2vw;
  color: black;

  &:hover {
    background-color: #fadaa1;
  }
  @media (min-width: 501px) and (max-width: 1200px) {
    padding: 0.5vw 2vw;
    border-radius: 2vw;
    width: 9.5vw;
    font-size: 4.5vw;
  }
  @media (max-width: 500px) {
    padding: 1.7vw 2vw;
    border-radius: 7vw;
    width: 30vw;
    font-size: 4.5vw;
  }
`
const Container = styled.div`
  min-height: 100vh;
`

const InnerContainer = styled.div`
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  @media (max-width: 1200px) {
    width: 88%;
  }
`

const Driverhome = () => {
  const navigate = useNavigate()
  const [bookings, setBookings] = useState([])
  const [loading, setLoading] = useState(false) // State to manage loading
  const { phoneNumber } = useDevice()
  const [isVisible, setIsVisible] = useState(true)
  const [acceptOrders, setAcceptOrders] = useState([])
  const [error, setError] = useState(null)
  const [hiddenClass, setHiddenClass] = useState('')
  const [hiddenClassmobile, setHiddenClassmobile] = useState('')
  const [isPhonePresent, setIsPhonePresent] = useState(false)
  const [isActive, setIsActive] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [dialogOpen1, setDialogOpen1] = useState(false)
  const [dialogOpen2, setDialogOpen2] = useState(false)
  const [isTrackingLocation, setIsTrackingLocation] = useState(false)
  const [bookingId, setBookingId] = useState(null)
  const [status, setStatus] = useState('')
  
  useLiveLocationTracking(isTrackingLocation ? bookingId : null, status)

  const handleOpenDialog = () => {
    setDialogOpen(true)
  }

  const handleCloseDialog = () => {
    setDialogOpen(false)
  }
  const handleOpenDialog1 = () => {
    setDialogOpen1(true)
  }

  const handleCloseDialog1 = () => {
    setDialogOpen1(false)
  }
  const handleOpenDialog2 = () => {
    setDialogOpen2(true)
  }

  const handleCloseDialog2 = () => {
    setDialogOpen2(false)
  }

  const getTodayDateInIST = () => {
    const now = new Date()

    // Convert the current time to UTC, then apply the IST offset (UTC+5:30)
    const istOffset = 330 // IST is UTC +5:30 in minutes
    const istTime = new Date(
      now.getTime() + (istOffset + now.getTimezoneOffset()) * 60 * 1000,
    )

    const year = istTime.getFullYear()
    const month = String(istTime.getMonth() + 1).padStart(2, '0') // Month is 0-indexed
    const day = String(istTime.getDate()).padStart(2, '0')

    return `${day}-${month}-${year}` // Format: DD-MM-YYYY
  }
  const today = getTodayDateInIST()

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1200) {
        setHiddenClass('hidden')
      } else {
        setHiddenClass('')
      }
    }

    window.addEventListener('resize', handleResize)
    handleResize() // Initial check

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    const handleResizemobile = () => {
      if (window.innerWidth < 500) {
        setHiddenClassmobile('hidden')
      } else {
        setHiddenClassmobile('')
      }
    }

    window.addEventListener('resize', handleResizemobile)
    handleResizemobile() // Initial check

    return () => {
      window.removeEventListener('resize', handleResizemobile)
    }
  }, [])

  const fetchDriverOrders = async () => {
    try {
      setLoading(true)
      const response = await axios.get(
        `https://www.dsctech.in/api/driver/bookings/accepted/${phoneNumber}/`,
      )

      // Get today's date in the same format as booking_date
      const today = new Date(new Date().toLocaleString("en-US", { timeZone: "Asia/Kolkata" }))
        .toISOString()
        .split('T')[0];
      // Filter orders with today’s booking date
      const filteredOrders = response.data.accepted_bookings.filter((order) => {
        return (
          order.booking_date === today && order.booking_status !== 'Completed'
        )
      })

      const onWayOrder = filteredOrders.find((order) => order.booking_status === 'On_Way_To_Pickup');

      // Set bookingId if such an order is found, else set to null
      if (onWayOrder) {
        setIsTrackingLocation(true)
        setStatus('started')
        setBookingId(onWayOrder.id);
      } else {
        setBookingId(null);
      }

      setAcceptOrders(filteredOrders)
    } catch (err) {
      console.error('Error fetching orders:', err)
      setError('Failed to fetch orders')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (phoneNumber) {
      setIsPhonePresent(true)
      fetchDriverOrders()
    } else {
      setIsPhonePresent(false)
      setAcceptOrders([])
    }
  }, [phoneNumber])

  useEffect(() => {
    if (phoneNumber) {
      setIsPhonePresent(true)
    } else {
      setIsPhonePresent(false)
    }
  }, [phoneNumber])

  const fetchBookings = () => {
    if (phoneNumber) {
      setLoading(true) // Start loading
      fetch(`https://www.dsctech.in/api/driver/bookings/${phoneNumber}/`)
        .then((response) => response.json())
        .then((data) => {
          setBookings(data.bookings || []) // Default to empty array if undefined
          setLoading(false) // Stop loading after data is fetched
        })
        .catch((error) => {
          console.error('Error fetching bookings:', error)
          setLoading(false) // Stop loading even if there is an error
        })
    }
  }

  // Handle visibility change
  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsVisible(!document.hidden) // Update visibility state
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    // Clean up the event listener on unmount
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  // Fetch the bookings data when the component mounts or phoneNumber changes
  useEffect(() => {
    fetchBookings()
  }, [])

  // Function to handle booking response (Accept/Reject)
  const respondToBooking = (bookingId, response) => {
    setLoading(true) // Start loading

    fetch(`https://www.dsctech.in/respond_to_booking/${bookingId}/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        phone: phoneNumber,
        response: response,
      }),
    })
      .then((response) => {
        // Check if the response is not okay (status codes outside 200-299)
        if (!response.ok) {
          // If the booking is already accepted, handle it accordingly
          return response.json().then((data) => {
            if (
              data.error ===
              'This booking has already been accepted by another driver.'
            ) {
              alert('This booking has already been accepted.')
              window.location.reload()
              throw new Error('Booking already accepted')
            }
            throw new Error(`HTTP error! status: ${response.status}`)
          })
        }
        return response.json()
      })
      .then((data) => {
        fetchBookings()
        fetchDriverOrders()
      })
      .catch((error) => {
        console.error('Error responding to booking:', error)
      })
      .finally(() => {
        setLoading(false) // Stop loading regardless of success or failure
      })
  }

  const updateBookingStatus = async (bookingId) => {
    try {
      console.log('Updating booking status for booking ID:', bookingId)
      const url = `https://www.dsctech.in/booking/update-status/${bookingId}/` // Use https:// for secure requests
      const data = {
        booking_status: 'On_Way_To_Pickup',
      }

      const response = await axios.put(url, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (response.status === 200) {
        setDialogOpen(false)
        fetchDriverOrders()
        console.log(bookingId)
        setStatus('started')
        setBookingId(bookingId)
        setIsTrackingLocation(true)
        alert('Booking status updated successfully!')
      } else {
        alert('Failed to update booking status.')
      }
    } catch (error) {
      console.error('Error updating booking status:', error)
      alert('An error occurred while updating booking status.')
    }
  }

  const updateBookingStatus1 = async (bookingId) => {
    try {
      console.log('Updating booking status for booking ID:', bookingId)
      const url = `https://www.dsctech.in/booking/update-status/${bookingId}/` // Use https:// for secure requests
      const data = {
        booking_status: 'Completed',
      }

      const response = await axios.put(url, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (response.status === 200) {
        setDialogOpen1(false)
        setStatus('ended')
        fetchDriverOrders()
        setIsTrackingLocation(false)

        alert('Booking status updated successfully!')
      } else {
        alert('Failed to update booking status.')
      }
    } catch (error) {
      console.error('Error updating booking status:', error)
      alert('An error occurred while updating booking status.')
    }
  }

  // Function to handle accept button click
  const handleAccept = (bookingId) => {
    respondToBooking(bookingId, 'accepted') // Removed driverId
  }
  const handleUpdateonway = (bookingId) => {
    updateBookingStatus(bookingId) // Removed driverId
  }

  const handleUpdateonway1 = (bookingId) => {
    updateBookingStatus1(bookingId) // Removed driverId
  }

  if (loading) {
    return <LoadingSpinner /> // Display loading spinner when loading
  }

  const handleBack = () => navigate(-1)

  if (!isPhonePresent) {
    return (
      <div
        style={{
          width: '100%',
          height: hiddenClass ? '100vh' : '80vh',
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {hiddenClass ? (
          <div>
            <button
              onClick={handleBack} // Call handleBack on button click
              style={{
                position: 'absolute',
                top: '7vh',
                left: '7%',
                marginTop: '10px',
                padding: '15px',
                borderRadius: '30px',
                border: '1px solid #ffffff',
                backgroundColor: '#FADAA1',
                color: 'black',
                cursor: 'pointer',
                marginRight: '10px',
              }}
            >
              Back
            </button>
          </div>
        ) : (
          <div></div>
        )}
        Please login to view your previous orders.
        <img
          src={pleaselogin}
          alt="Please login"
          style={{ marginTop: '5vh' }}
          height="45%"
        />
      </div>
    )
  }

  const navLinks = [
    { name: 'Home', path: '/' },
    { name: 'About Us', path: '/aboutus' },
    { name: 'My Bookings', path: '/orders' },
    { name: 'Share App', path: '#' }, // Placeholder path for the Share App button
  ]

  const handleShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: 'Check out this app!',
          text: 'I thought you might like this app. Check it out!',
          url: window.location.href,
        })
        .then(() => console.log('Thanks for sharing!'))
        .catch((error) => console.log('Error sharing:', error))
    } else if (window.flutter_inappwebview) {
      // Notify Flutter to handle sharing
      window.flutter_inappwebview.callHandler('shareApp')
    } else {
      alert(
        'Web Share API not supported. Copy the URL manually: ' +
        window.location.href,
      )
    }
  }

  const handleNavigation = (path) => {
    navigate(path)
  }

  const handleLogout = () => {
    // Remove phone number from localStorage
    setIsActive(false)
    localStorage.removeItem('phoneNumber')
    navigate('/login')
  }

  const handleprofileClick = () => {
    navigate(`/profile`)
  }

  return (
    <div>
      <Container>
        {hiddenClass ? (
          <InnerContainer>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '8vh',
              }}
            >
              <div
                onClick={() => setIsActive(!isActive)}
                className="button"
                style={{ marginLeft: '0', zIndex: '7' }}
              >
                <div
                  className={`burger ${isActive ? 'burgerActive' : ''}`}
                ></div>
              </div>
              <div
                className={`dialog ${isActive ? 'dialogActive' : ''}`}
                style={{ zIndex: '6' }}
              >
                <div style={{ marginTop: '20vh' }}>
                  {navLinks.map((link, index) =>
                    link.name === 'Share App' ? (
                      <StyledDiv4 key={index} onClick={handleShare}>
                        {link.name}
                      </StyledDiv4>
                    ) : (
                      <StyledDiv4
                        key={index}
                        onClick={() => handleNavigation(link.path)}
                      >
                        {link.name}
                      </StyledDiv4>
                    ),
                  )}
                </div>
                <StyledButton1
                  style={{
                    marginRight: '5%',
                    width: '90%',
                    height: '6%',
                    marginLeft: '5%',
                    marginTop: '3.5vh',
                  }}
                  onClick={handleLogout}
                >
                  <span>Logout</span>
                </StyledButton1>
              </div>
              <div
                style={{
                  height: '6vh',
                  width: '6vh',
                  border: '1px solid #FABA49',
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                onClick={handleprofileClick}
              >
                <img src={profile} height="60%" width="auto" />
              </div>
            </div>
            <Heading1 style={{ marginTop: '5vh' }}>Driver Bookings</Heading1>
            {bookings.length === 0 ? (
              <div style={{ marginTop: '0.5vh' }}>No bookings available</div>
            ) : (
              <GridContainer>
                {bookings.map((booking) => (
                  <GridItem key={booking.booking_id}>
                    <div>
                      <div
                        style={{
                          fontSize: hiddenClassmobile ? '4.6vw' : '3.4vw',
                        }}
                      >
                        {booking.shop_name}
                      </div>
                      <div
                        style={{
                          color: 'rgb(0, 0, 0, 0.6)',
                          marginTop: '1vh',
                          width: '65%',
                          fontSize: hiddenClassmobile ? '3vw' : '2vw',
                        }}
                      >
                        <div
                          style={{
                            color: 'rgb(0, 0, 0, 1)',
                            fontSize: hiddenClassmobile ? '3vw' : '2vw',
                          }}
                        >
                          User Address :{' '}
                        </div>
                        {booking.address.street}, {booking.address.city},{' '}
                        {booking.address.state}, {booking.address.postal_code},{' '}
                        {booking.address.country}
                      </div>
                      <div
                        style={{
                          color: 'rgb(0, 0, 0, 0.6)',
                          marginTop: '1vh',
                          fontSize: hiddenClassmobile ? '3vw' : '2vw',
                        }}
                      >
                        {' '}
                        <span style={{ color: 'rgb(0, 0, 0, 1)' }}>
                          Car :
                        </span>{' '}
                        {booking.car_name}
                      </div>
                      <div style={{ marginTop: '1vh', display: 'flex' }}>
                        <div
                          style={{
                            color: 'rgb(0, 0, 0, 0.6)',
                            fontSize: hiddenClassmobile ? '3vw' : '2vw',
                          }}
                        >
                          <span style={{ color: 'rgb(0, 0, 0, 1)' }}>
                            Date :
                          </span>{' '}
                          {new Date(booking.booking_date).toLocaleDateString()}
                        </div>
                      </div>
                      <div
                        style={{
                          marginTop: '1vh',
                          color: 'rgb(0, 0, 0, 0.6)',
                          fontSize: hiddenClassmobile ? '3vw' : '2vw',
                        }}
                      >
                        <span style={{ color: 'rgb(0, 0, 0, 1)' }}>Slot :</span>{' '}
                        {booking.selected_slot}
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Button
                        onClick={() => handleAccept(booking.booking_id)}
                        style={{
                          height: '5vh',
                          padding: '5px',
                          borderRadius: '20px',
                          backgroundColor: '#FADAA1',
                          color: 'black',
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          fontSize: '14px',
                        }}
                      >
                        Accept
                      </Button>
                    </div>
                  </GridItem>
                ))}
              </GridContainer>
            )}
            <Heading1 style={{ marginTop: '5vh' }}>Today Orders</Heading1>
            <GridContainer>
              {acceptOrders.length === 0 ? (
                <div style={{ marginTop: '0.5vh' }}>
                  No orders available for today.
                </div>
              ) : (
                acceptOrders.map((order) => (
                  <GridItem1 key={order.id}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div
                        style={{
                          color: 'rgb(0, 0, 0, 1)',
                          fontSize: hiddenClassmobile ? '3vw' : '2vw',
                          height: '4.5vh',
                          backgroundColor: '#FADAA1',
                          width: 'auto',
                          paddingLeft: '2.5vw',
                          paddingRight: '2.5vw',
                          borderRadius: '5vw',
                          alignItems: 'center',
                          justifyContent: 'center',
                          display: 'flex',
                        }}
                      >
                        {order.booking_status}{' '}
                      </div>
                      <div
                        style={{
                          color: 'rgb(0, 0, 0, 0.6)',
                          fontSize: hiddenClassmobile ? '3vw' : '2vw',
                        }}
                      >
                        <span style={{ color: 'rgb(0, 0, 0, 1)' }}>
                          Date :{' '}
                        </span>{' '}
                        {new Date(order.booking_date).toLocaleDateString(
                          'en-GB',
                          {
                            day: 'numeric',
                            month: 'short',
                          },
                        )}
                      </div>
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div
                        style={{
                          fontSize: hiddenClassmobile ? '4.6vw' : '3.4vw',
                          marginTop: '2.5vh',
                        }}
                      >
                        {order.shop.shop_name}
                      </div>
                      <div
                        style={{
                          color: 'rgb(0, 0, 0, 0.6)',
                          marginTop: '1vh',
                          fontSize: hiddenClassmobile ? '3vw' : '2vw',
                        }}
                      >
                        <span style={{ color: 'rgb(0, 0, 0, 1)' }}>
                          Customer :
                        </span>{' '}
                        {order.customer.name}
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div
                        style={{
                          color: 'rgb(0, 0, 0, 0.6)',
                          marginTop: '1vh',
                          fontSize: hiddenClassmobile ? '3vw' : '2vw',
                        }}
                      >
                        <span style={{ color: 'rgb(0, 0, 0, 1)' }}>Car :</span>{' '}
                        {order.car.name}
                      </div>
                      <div
                        style={{
                          color: 'rgb(0, 0, 0, 0.6)',
                          marginTop: '1vh',
                          fontSize: hiddenClassmobile ? '3vw' : '2vw',
                        }}
                      >
                        <span style={{ color: 'rgb(0, 0, 0, 1)' }}>Slot :</span>{' '}
                        {order.selected_slot}
                      </div>
                    </div>
                    {order.booking_status === 'In_Progress' ? (
                      <Button
                        onClick={() => handleOpenDialog()}
                        style={{
                          height: '5vh',
                          width: '100%',
                          backgroundColor: '#FADAA1',
                          borderRadius: '6vw',
                          fontSize: hiddenClassmobile ? '3vw' : '2vw',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginTop: '2vh',
                        }}
                      >
                        Status Update
                      </Button>
                    ) : order.booking_status === 'On_Way_To_Pickup' ? (
                      <Button
                        onClick={() => handleOpenDialog1()}
                        style={{
                          height: '5vh',
                          width: '100%',
                          fontSize: hiddenClassmobile ? '3vw' : '2vw',
                          backgroundColor: '#FADAA1',
                          borderRadius: '6vw',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginTop: '2vh',
                        }}
                      >
                        Status Update
                      </Button>
                      
                    ) : (
                      <></>
                    )}
                     <Button
                            onClick={() => navigate(`/driverupload/${order.id}`)}
                            style={{
                              height: '5vh',
                              width: '100%',
                              fontSize: hiddenClassmobile ? '3vw' : '2vw',
                              backgroundColor: '#FADAA1',
                              borderRadius: '6vw',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              marginTop: '2vh',
                            }}
                          >
                            Upload Images
                          </Button>

                    {order.booking_status === 'In_Progress' && (
                      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                          }}
                        >
                          <Button
                            onClick={() => handleUpdateonway(order.id)}
                            style={{
                              width: 'auto',
                              padding: '5vw 10vw',
                              backgroundColor: '#FADAA1',
                              margin: '3vh',
                            }}
                          >
                            On Way to Pickup
                          </Button>
                          <div
                            onClick={() => handleCloseDialog()}
                            style={{
                              height: '5vh',
                              width: '25vw',
                              backgroundColor: '#FADAA1',
                              fontSize: hiddenClassmobile ? '5vw' : '3vw',
                              borderRadius: '6vw',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              marginBottom: '3vh',
                              cursor: 'pointer',
                            }}
                          >
                            Close
                          </div>
                        </div>
                      </Dialog>
                    )}
                    {order.booking_status === 'On_Way_To_Pickup' && (
                      <Dialog open={dialogOpen1} onClose={handleCloseDialog1}>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                          }}
                        >
                          <Button
                            onClick={() => handleUpdateonway1(order.id)}
                            style={{
                              width: 'auto',
                              padding: '5vw 10vw',
                              backgroundColor: '#FADAA1',
                              margin: '3vh',
                            }}
                          >
                            Completed
                          </Button>
                          <div
                            onClick={() => handleCloseDialog1()}
                            style={{
                              height: '5vh',
                              width: '25vw',
                              backgroundColor: '#FADAA1',
                              fontSize: hiddenClassmobile ? '5vw' : '3vw',
                              borderRadius: '6vw',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              marginBottom: '3vh',
                              cursor: 'pointer',
                            }}
                          >
                            Close
                          </div>
                        </div>
                      </Dialog>
                    )}
                  </GridItem1>
                ))
              )}
            </GridContainer>
          </InnerContainer>
        ) : (
          <div style={{ marginLeft: '5%', display: 'flex', justifyContent:'space-between', marginRight:'5%', marginTop:'5vh' }}>
            <div>
              <div
                style={{
                  fontSize: '1.8vw',
                  fontWeight: '500',
                  marginBottom: '2.5vh',
                }}
              >
                Driver Bookings
              </div>
              {bookings.length === 0 ? (
                <p style={{fontSize:'1.2vw'}}>No bookings available</p>
              ) : (
                <GridContainer>
                  {bookings.map((booking) => (
                    <GridItem key={booking.booking_id}>
                      <div>
                        <div style={{ fontSize: '1.5vw' }}>
                          {booking.shop_name}
                        </div>
                        <div
                          style={{
                            color: 'rgb(0, 0, 0, 0.6)',
                            marginTop: '1vh',
                            width: '65%',
                            fontSize: '1.2vw',
                          }}
                        >
                          <div
                            style={{
                              color: 'rgb(0, 0, 0, 1)',
                              fontSize: '1.2vw',
                            }}
                          >
                            User Address :{' '}
                          </div>
                          {booking.address.street}, {booking.address.city},{' '}
                          {booking.address.state}, {booking.address.postal_code},{' '}
                          {booking.address.country}
                        </div>
                        <div
                          style={{
                            color: 'rgb(0, 0, 0, 0.6)',
                            marginTop: '1vh',
                            fontSize: '1.2vw',
                          }}
                        >
                          {' '}
                          <span style={{ color: 'rgb(0, 0, 0, 1)' }}>
                            Car :
                          </span>{' '}
                          {booking.car_name}
                        </div>
                        <div style={{ marginTop: '1vh', display: 'flex' }}>
                          <div
                            style={{
                              color: 'rgb(0, 0, 0, 0.6)',
                              fontSize: '1.2vw',
                            }}
                          >
                            <span style={{ color: 'rgb(0, 0, 0, 1)' }}>
                              Date :
                            </span>{' '}
                            {new Date(booking.booking_date).toLocaleDateString()}
                          </div>
                          <div
                            style={{
                              color: 'rgb(0, 0, 0, 0.6)',
                              marginLeft: '5%',
                              fontSize: '1.2vw',
                            }}
                          >
                            <span style={{ color: 'rgb(0, 0, 0, 1)' }}>
                              Slot :
                            </span>{' '}
                            {booking.selected_slot}
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Button
                          onClick={() => handleAccept(booking.booking_id)}
                          style={{
                            height: '5vh',
                            padding: '5px',
                            borderRadius: '20px',
                            backgroundColor: '#FADAA1',
                            color: 'black',
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontSize: '14px',
                          }}
                        >
                          Accept
                        </Button>
                      </div>
                    </GridItem>
                  ))}
                </GridContainer>
              )}
            </div>
            <div style={{marginLeft:'5%'}}>
              <div
                style={{
                  fontSize: '1.8vw',
                  fontWeight: '500',
                  marginBottom: '2.5vh',
                }}
              >
                Today Orders
              </div>
              <GridContainer>
                {acceptOrders.length === 0 ? (
                  <p style={{fontSize:'1.2vw'}}>No orders available for today.</p>
                ) : (
                  acceptOrders.map((order) => (
                    <GridItem1 key={order.id}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div
                          style={{
                            color: 'rgb(0, 0, 0, 1)',
                            fontSize: '1vw',
                            height: '4.5vh',
                            backgroundColor: '#FADAA1',
                            width: 'auto',
                            paddingLeft: '1.5vw',
                            paddingRight: '1.5vw',
                            borderRadius: '2vw',
                            alignItems: 'center',
                            justifyContent: 'center',
                            display: 'flex',
                          }}
                        >
                          {order.booking_status}{' '}
                        </div>
                        <div
                          style={{
                            color: 'rgb(0, 0, 0, 0.6)',
                            fontSize: '1.2vw',
                          }}
                        >
                          <span style={{ color: 'rgb(0, 0, 0, 1)' }}>
                            Date :{' '}
                          </span>{' '}
                          {new Date(order.booking_date).toLocaleDateString(
                            'en-GB',
                            {
                              day: 'numeric',
                              month: 'short',
                            },
                          )}
                        </div>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div style={{ fontSize: '1.5vw', marginTop: '2.5vh' }}>
                          {order.shop.shop_name}
                        </div>
                        <div
                          style={{
                            color: 'rgb(0, 0, 0, 0.6)',
                            marginTop: '1vh',
                            fontSize: '1.2vw',
                          }}
                        >
                          <span style={{ color: 'rgb(0, 0, 0, 1)' }}>
                            Customer :
                          </span>{' '}
                          {order.customer.name}
                        </div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div
                          style={{
                            color: 'rgb(0, 0, 0, 0.6)',
                            marginTop: '1vh',
                            fontSize: '1.2vw',
                          }}
                        >
                          <span style={{ color: 'rgb(0, 0, 0, 1)' }}>Car :</span>{' '}
                          {order.car.name}
                        </div>
                        <div
                          style={{
                            color: 'rgb(0, 0, 0, 0.6)',
                            marginTop: '1vh',
                            fontSize: '1.2vw',
                          }}
                        >
                          <span style={{ color: 'rgb(0, 0, 0, 1)' }}>Slot :</span>{' '}
                          {order.selected_slot}
                        </div>
                      </div>
                      {order.booking_status === 'In_Progress' ? (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Button
                            onClick={() => handleOpenDialog()}
                            style={{
                              height: '5vh',
                              width: '47%',
                              backgroundColor: '#FADAA1',
                              fontSize: '1vw',
                              borderRadius: '3vw',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              marginTop: '2vh',
                            }}
                          >
                            Status Update
                          </Button>
                          <Button
                            onClick={() => navigate(`/driverupload/${order.id}`)}
                            style={{
                              height: '5vh',
                              width: '47%',
                              backgroundColor: '#FADAA1',
                              fontSize: '1vw',
                              borderRadius: '3vw',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              marginTop: '2vh',
                            }}
                          >
                            Upload Images
                          </Button>
                        </div>
                      ) : order.booking_status === 'On_Way_To_Pickup' ? (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Button
                            onClick={() => handleOpenDialog1()}
                            style={{
                              height: '5vh',
                              width: '47%',
                              backgroundColor: '#FADAA1',
                              fontSize: '1vw',
                              borderRadius: '3vw',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              marginTop: '2vh',
                            }}
                          >
                            Status Update
                          </Button>
                          <Button
                            onClick={() => navigate(`/driverupload/${order.id}`)}
                            style={{
                              height: '5vh',
                              width: '47%',
                              backgroundColor: '#FADAA1',
                              fontSize: '1vw',
                              borderRadius: '3vw',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              marginTop: '2vh',
                            }}
                          >
                            Upload Images
                          </Button>
                        </div>
                      ) : (
                        <></>
                      )}

                      {order.booking_status === 'In_Progress' && (
                        <Dialog open={dialogOpen} onClose={handleCloseDialog}>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                            }}
                          >
                            <Button
                              onClick={() => handleUpdateonway(order.id)}
                              style={{
                                width: '20vw',
                                backgroundColor: '#FADAA1',
                                margin: '3vh',
                              }}
                            >
                              On Way to Pickup
                            </Button>
                            <div
                              onClick={() => handleCloseDialog()}
                              style={{
                                height: '5vh',
                                width: '10vw',
                                backgroundColor: '#FADAA1',
                                fontSize: '1vw',
                                borderRadius: '3vw',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginBottom: '3vh',
                                cursor: 'pointer',
                              }}
                            >
                              Close
                            </div>
                          </div>
                        </Dialog>
                      )}
                      {order.booking_status === 'On_Way_To_Pickup' && (
                        <Dialog open={dialogOpen1} onClose={handleCloseDialog1}  style={{ minWidth: '25vw' }}>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              
                            }}
                          >
                            <Button
                              onClick={() => handleUpdateonway1(order.id)}
                              style={{
                                width: '10vw',
                                backgroundColor: '#FADAA1',
                                margin: '3vh',
                              }}
                            >
                              Completed
                            </Button>
                            <div
                              onClick={() => handleCloseDialog1()}
                              style={{
                                height: '5vh',
                                width: '10vw',
                                backgroundColor: '#FADAA1',
                                fontSize: '1vw',
                                borderRadius: '3vw',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginBottom: '3vh',
                                cursor: 'pointer',
                              }}
                            >
                              Close
                            </div>
                          </div>
                        </Dialog>
                      )}
                    </GridItem1>
                  ))
                )}
              </GridContainer>
            </div>
          </div>
        )}
      </Container>
      {hiddenClass ? <></> : <Footer />}
    </div>
  )
}

export default Driverhome
