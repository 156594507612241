// DriverProfile.js
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import { useDevice } from '../Components/Context/deviceContext'
import { LoadingSpinner } from '../Components/LoadingSpinner '
import { useNavigate } from 'react-router-dom'
import arrow from '../Components/Assets/arrow.svg'

// Styled components
const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 3vw;
  max-width: 30%;
  border: 1.5px solid #faba49;
  margin: 50px auto;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 2vw;
  flex-direction: column;
`

const ProfilePicture = styled.img`
  width: 14vh;
  height: 14vh;
  border-radius: 50%;
  object-fit: cover;
`

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Name = styled.h2`
  margin: 0;
  font-size: 24px;
  color: #333;
`

const Phone = styled.p`
  margin: 5px 0;
  font-size: 18px;
  color: #555;
`

const LicenseImage = styled.img`
  width: 100%;
  max-width: 300px;
  border-radius: 8px;
  margin-top: 10px;
`

const Button = styled.button`
  margin-top: 2vh;
  padding: 10px 20px;
  background-color: #fadaa1;
  border: none;
  border-radius: 3vw;
  cursor: pointer;

  &:hover {
    background-color: #fabe49;
  }
`

// Form Inputs styled
const Input = styled.input`
  margin-bottom: 15px;
  width: auto;
  padding: 10px;
  border-radius: 30px;
  background-color: #ffffff;
  border: 1px solid #ff8c00;
  font-size: 16px;

  &:focus {
    background-color: #fff7d4;
    border: 1px solid #ff8c00;
    outline: none;
  }
`

const EditForm = styled.form`
  display: flex;
  flex-direction: column;
`

const DriverProfile = () => {
    const navigate = useNavigate()
  const [driver, setDriver] = useState(null)
  const { phoneNumber } = useDevice()
  const [loading, setLoading] = useState(true)
  const [isEditing, setIsEditing] = useState(false)

  const [name, setName] = useState('')
  const [profilePicture, setProfilePicture] = useState(null)
  const [drivingLicenseNo, setDrivingLicenseNo] = useState('')
  const [drivingLicenseImage, setDrivingLicenseImage] = useState(null)
  const [hiddenClass, setHiddenClass] = useState('')
  const [hiddenClassmobile, setHiddenClassmobile] = useState('')

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1200) {
        setHiddenClass('hidden')
      } else {
        setHiddenClass('')
      }
    }

    window.addEventListener('resize', handleResize)
    handleResize() // Initial check

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    const handleResizemobile = () => {
      if (window.innerWidth < 500) {
        setHiddenClassmobile('hidden')
      } else {
        setHiddenClassmobile('')
      }
    }

    window.addEventListener('resize', handleResizemobile)
    handleResizemobile() // Initial check

    return () => {
      window.removeEventListener('resize', handleResizemobile)
    }
  }, [])


  useEffect(() => {
    const fetchDriverData = async () => {
      try {
        const response = await axios.get(
          `https://www.dsctech.in/update_user/${phoneNumber}/`,
        )
        setDriver(response.data)
        setName(response.data.name)
        setDrivingLicenseNo(response.data.driving_license_no)
      } catch (error) {
        console.error('Error fetching data:', error)
        alert('Failed to load driver data.')
      } finally {
        setLoading(false)
      }
    }

    fetchDriverData()
  }, [phoneNumber])

  const handleEditToggle = () => {
    setIsEditing((prev) => !prev)
  }

  const handleClick = () => {
    navigate(-1) // This goes back to the previous page
  }

  const handleUpdate = async (e) => {
    e.preventDefault()
    setLoading(true)
    const formData = new FormData()
    formData.append('name', name)
    if (profilePicture) formData.append('profile_picture', profilePicture)
    formData.append('driving_license_no', drivingLicenseNo)
    if (drivingLicenseImage)
      formData.append('driving_license', drivingLicenseImage)

    try {
      const response = await axios.put(
        `https://www.dsctech.in/update_user/${phoneNumber}/`,
        formData,
      )
      if (response.status === 200) {
        alert('Profile updated successfully!')
        setDriver((prev) => ({
          ...prev,
          name,
          driving_license_no: drivingLicenseNo,
          profile_picture: profilePicture
            ? response.data.profile_picture
            : prev.profile_picture,
        }))
        setLoading(false)
      }
    } catch (error) {
      console.error('Error updating profile:', error)
      alert('Failed to update profile.')
    } finally {
      setIsEditing(false)
      setLoading(false)
    }
  }

  if (loading) return <LoadingSpinner />
  if (!driver) return <p>No driver data found.</p>

  return (
    <div>
      {hiddenClass? (
        <div  style={{ width: '88%', marginLeft: 'auto', marginRight: 'auto' }}>
<div
            onClick={handleClick}
            style={{
              height: hiddenClassmobile ? '5vh' : '7vh',
              width: hiddenClassmobile ? '5vh' : '7vh',
              borderRadius: '50%',
              backgroundColor: '#FABA49',
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              marginTop: '6vh',
            }}
          >
            <img
              src={arrow}
              alt="arrow"
              height="50%"
              style={{
                transform: 'rotate(-180deg)',
              }}
            />
          </div>
          {isEditing ? (
        <EditForm onSubmit={handleUpdate} style={{ marginTop:'5vh',}}>
          <Input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter your name"
            required
          />
          <Input
            type="file"
            accept="image/*"
            onChange={(e) => setProfilePicture(e.target.files[0])}
          />
          <Input
            type="text"
            value={drivingLicenseNo}
            onChange={(e) => setDrivingLicenseNo(e.target.value)}
            placeholder="Driving License No"
            required
          />
          <Input
            type="file"
            accept="*/*"
            onChange={(e) => setDrivingLicenseImage(e.target.files[0])}
          />
          <Button type="submit">Save Changes</Button>
          <Button type="button" onClick={handleEditToggle} style={{backgroundColor:'#ffffff'}}>
            Cancel
          </Button>
        </EditForm>
      ) : (
        <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
          <div
            style={{
              height: '20vh',
              width: '20vh',
              marginTop:'5vh',
              borderRadius: '50%',
              border: '1.5px solid #faba49',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <ProfilePicture
              src={`https://www.dsctech.in${driver.profile_picture}`}
              alt="Profile"
            />
          </div>
          <Button onClick={handleEditToggle}>Edit</Button>
          <div
            style={{
              color: 'rgb(0, 0, 0, 0.6)',
              fontSize: hiddenClassmobile ? '4vw' : '3vw' ,
              marginTop: '2vh',
            }}
          >
            <span style={{ color: 'rgb(0, 0, 0, 1)' }}>Phone :</span>{' '}
            {driver.name}
          </div>
          <div
            style={{
              color: 'rgb(0, 0, 0, 0.6)',
              fontSize: hiddenClassmobile ? '4vw' : '3vw' ,
              marginTop: '2vh',
            }}
          >
            <span style={{ color: 'rgb(0, 0, 0, 1)' }}>Name :</span>{' '}
            {driver.phone}
          </div>
          <div
            style={{
              color: 'rgb(0, 0, 0, 0.6)',
              fontSize: hiddenClassmobile ? '4vw' : '3vw' ,
              marginTop: '2vh',
            }}
          >
            <span style={{ color: 'rgb(0, 0, 0, 1)' }}>License No :</span>{' '}
            {driver.driving_license_no}
          </div>
          <InfoContainer>
            <LicenseImage
              src={`https://www.dsctech.in${driver.driving_license}`}
              alt="Driving License"
            />
          </InfoContainer>
        </div>
      )}
        </div>
      ): (
        <Container>
            {isEditing ? (
        <EditForm onSubmit={handleUpdate}>
          <Input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter your name"
            required
          />
          <Input
            type="file"
            accept="image/*"
            onChange={(e) => setProfilePicture(e.target.files[0])}
          />
          <Input
            type="text"
            value={drivingLicenseNo}
            onChange={(e) => setDrivingLicenseNo(e.target.value)}
            placeholder="Driving License No"
            required
          />
          <Input
            type="file"
            accept="*/*"
            onChange={(e) => setDrivingLicenseImage(e.target.files[0])}
          />
          <Button type="submit">Save Changes</Button>
          <Button type="button" onClick={handleEditToggle} style={{backgroundColor:'#ffffff'}}>
            Cancel
          </Button>
        </EditForm>
      ) : (
        <>
          <div
            style={{
              height: '20vh',
              width: '20vh',
              borderRadius: '50%',
              border: '1.5px solid #faba49',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <ProfilePicture
              src={`https://www.dsctech.in${driver.profile_picture}`}
              alt="Profile"
            />
          </div>
          <Button onClick={handleEditToggle}>Edit</Button>
          <div
            style={{
              color: 'rgb(0, 0, 0, 0.6)',
              fontSize: '1.2vw',
              marginTop: '2vh',
            }}
          >
            <span style={{ color: 'rgb(0, 0, 0, 1)' }}>Phone :</span>{' '}
            {driver.name}
          </div>
          <div
            style={{
              color: 'rgb(0, 0, 0, 0.6)',
              fontSize: '1.2vw',
              marginTop: '2vh',
            }}
          >
            <span style={{ color: 'rgb(0, 0, 0, 1)' }}>Name :</span>{' '}
            {driver.phone}
          </div>
          <div
            style={{
              color: 'rgb(0, 0, 0, 0.6)',
              fontSize: '1.2vw',
              marginTop: '2vh',
            }}
          >
            <span style={{ color: 'rgb(0, 0, 0, 1)' }}>License No :</span>{' '}
            {driver.driving_license_no}
          </div>
          <InfoContainer>
            <LicenseImage
              src={`https://www.dsctech.in${driver.driving_license}`}
              alt="Driving License"
            />
          </InfoContainer>
        </>
      )}
        </Container>
      )}
    </div>
  )
}

export default DriverProfile
