import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import tyre from '../../Components/Assets/tyre.png'
import { useNavigate } from 'react-router-dom'
import CryptoJS from 'crypto-js'
import { LoadingSpinner } from '../../Components/LoadingSpinner '
import logo from '../../Components/Assets/Logo.png'
import login from '../../Components/Assets/login.png'
import otpimage from '../../Components/Assets/otp.png'

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 93px);
  background: #f2f2f2;
  @media (max-width: 1200px) {
    display: none;
  }
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  background-color: tranparent;
  border: 2px solid #faba49;
  padding: 40px;
  border-radius: 25px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 300px;
`

const Form1 = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: tranparent;
  border: none;
  width: 100%;
`

const Input = styled.input`
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 30px;
  background-color: #f2f2f2;
  border: 1px solid #ff8c00;
  font-size: 16px;
  &:focus {
    background-color: #fff7d4;
    border: 1px solid #ff8c00;
    outline: none;
  }

  @media (min-width: 768px) and (max-width: 1200px) {
    width: 88%;
  }
  @media (max-width: 767px) {
    font-size: 14px;
    width: 88%;
    padding: 15px 10px;
    margin-left: auto;
    margin-right: auto;
  }
`

const Button = styled.button`
  position: relative; /* Ensure the button is positioned relative to its container */
  padding: 10px;
  background-color: #faba49;
  color: #000;
  font-size: 16px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  overflow: hidden; /* Ensure no content overflows the button */
  display: flex; /* Use flex to align text properly */
  align-items: center; /* Center text vertically */
  justify-content: center; /* Center text horizontally */

  img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover; /* Ensure image covers the button */
    z-index: 1; /* Ensure image is behind the text */
    opacity: 0.1;
  }

  span {
    position: relative;
    z-index: 2; /* Ensure text is above the image */
  }
  @media (min-width: 768px) and (max-width: 1200px) {
    width: 90%;
    border-radius: 30px;
  }
  @media (max-width: 767px) {
    width: 93%;
    padding: 15px 10px;
    border: 1px solid #ff8c00;
    border-radius: 30px;
    margin-left: auto;
    margin-right: auto;
  }
`

const Driver = () => {
  const navigate = useNavigate()
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [otp, setOtp] = useState('')
  const [otpSent, setOtpSent] = useState(false)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [locationAllowed, setLocationAllowed] = useState(false)
  const hasRequestedPermission = useRef(false)
  const [hiddenClass, setHiddenClass] = useState('')
  const [hiddenClassmobile, setHiddenClassmobile] = useState('')

  useEffect(() => {
    const handleResize = () => {
      setHiddenClass(window.innerWidth < 1200 ? 'hidden' : '')
      setHiddenClassmobile(window.innerWidth < 500 ? 'hidden' : '')
    }

    window.addEventListener('resize', handleResize)
    handleResize() // Initial check

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    // Check if location is already stored
    const latitude = localStorage.getItem('latitude')
    const longitude = localStorage.getItem('longitude')

    if (latitude && longitude) {
      setLocationAllowed(true)
      console.log('Location already saved.')
    } else if (!hasRequestedPermission.current) {
      promptForLocation()
    }
  }, [])

  const promptForLocation = async () => {
    console.log('promptForLocation called') // For debugging
    if (navigator.geolocation) {
      try {
        const position = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject, {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
          })
        })

        const { latitude, longitude } = position.coords
        localStorage.setItem('latitude', latitude)
        localStorage.setItem('longitude', longitude)
        setLocationAllowed(true)
        console.log('Location saved:', latitude, longitude)
      } catch (error) {
        console.error('Error getting location:', error)
        // alert('Failed to get location. Please enable location services and try again.');
      }
    } else {
      // alert('Geolocation is not supported by this browser.');
    }
  }

  const handleSendOtp = async (e) => {
    e.preventDefault()
    setLoading(true) // Start loading

     // Validate phone number
  if (!/^\d{10}$/.test(phone)) {
    alert('Please enter a valid 10-digit phone number.');
    setLoading(false); // Stop loading
    return;
  }


    // Save the name in localStorage
    localStorage.setItem('name', name)

    try {
      // Retrieve latitude, longitude, and name from localStorage
      const latitude = localStorage.getItem('latitude')
      const longitude = localStorage.getItem('longitude')
      const storedName = localStorage.getItem('name')
      const token = localStorage.getItem('token')

      const response = await axios.post(
        'https://www.dsctech.in/api/auth2/login/',
        {
          phone,
          name: storedName, // Use the retrieved name
          latitude,
          longitude,
          role: 'driver',
          token,
        },
      )

      console.log(response.data) // Handle success
      setOtpSent(true)
      // alert('OTP sent successfully!');
    } catch (err) {
      console.error(err)
      setError('Failed to send OTP. Please try again.')
    } finally {
      setLoading(false) // Stop loading
    }
  }

  const handleVerifyOtp = async (e) => {
    e.preventDefault()
    setLoading(true) // Start loading
    setError('') // Clear previous errors

    const verifyStatus = localStorage.getItem('verify')

    // Client-side OTP validation
    if (!otp || otp.length !== 6 || !/^\d{6}$/.test(otp)) {
      setError('OTP must be a 6-digit number.')
      setLoading(false) // Stop loading
      return // Exit if OTP is invalid
    }

    try {
      const response = await axios.post(
        'https://www.dsctech.in/api/auth2/verify-otp/',
        { phone, otp },
      )

      console.log(response.data) // Handle success

      // Check if OTP verification is successful
      if (response.status === 200 && response.data.status === 200) {
        // Encrypt the phone number
        const encryptedPhone = CryptoJS.AES.encrypt(
          phone,
          'your-secret-key',
        ).toString()

        // Store encrypted phone number and role in localStorage
        localStorage.setItem('phoneNumber', encryptedPhone)
        localStorage.setItem('role', 'driver')

        // Fetch carshop_id (driving_license_no in your case)
        const shopResponse = await axios.get(
          `https://www.dsctech.in/update_user/${phone}/`,
        )
        const carshopId = shopResponse.data.driving_license_no // Adjust based on API's response

        // Check if carshopId is a non-empty, valid string
        if (
          !carshopId ||
          carshopId.trim() === '' ||
          isNaN(Number(carshopId)) ||
          Number(carshopId) <= 0
        ) {
          navigate('/adddriverdetais') // Navigate if invalid
        } else {
          navigate('/') // Navigate if valid
        }

        window.location.reload()
      } else {
        localStorage.removeItem('phoneNumber')
        setError(response.data.message || 'Invalid OTP. Please try again.')
      }
    } catch (err) {
      localStorage.removeItem('phoneNumber')
      console.error(err)
      setError(
        'Failed to verify OTP. Please check your connection and try again.',
      )
    } finally {
      setLoading(false) // Stop loading
    }
  }

  return (
    <div>
      {hiddenClass ? (
        loading ? (
          <LoadingSpinner />
        ) : !otpSent ? (
          <div
            style={{ width: '88%', marginLeft: 'auto', marginRight: 'auto' }}
          >
            <div style={{ height: '4.5vh', width: 'auto', marginTop: '6vh' }}>
              <img src={logo} alt="" height="100%" width="auto" />
            </div>
            <div
              style={{
                height: '38vh',
                width: '100%',
                marginTop: '6vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img
                src={login}
                alt=""
                style={{ height: '100%', width: 'auto' }}
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  fontSize: '5vw',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  letterSpacing: '0.1vw',
                  marginTop: '3vh',
                }}
              >
                Welcome to Hubio Driver
              </div>
              <div
                style={{
                  fontSize: '3.5vw',
                  color: 'rgba(0, 0, 0, 0.6)',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  letterSpacing: '0.1vw',
                  marginBottom: '15px',
                }}
              >
                Your Ultimate Car Care Solution!
              </div>
              <Form1 onSubmit={handleSendOtp}>
                <Input
                  type="text"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  style={{ marginTop: '2vh', backgroundColor: '#fff' }}
                  required
                />
                <Input
                  type="tel"
                  placeholder="Phone Number"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  required
                  style={{ backgroundColor: '#fff' }}
                />
                {error && (
                  <div
                    style={{
                      color: 'red',
                      marginTop: '-12px',
                      fontSize: '10px',
                      marginBottom: '10px',
                    }}
                  >
                    {error}
                  </div>
                )}
                <Button type="submit">
                  <img src={tyre} alt="Tyre" />
                  <span>Send OTP</span>
                </Button>
              </Form1>
            </div>
          </div>
        ) : (
          <div
            style={{ width: '88%', marginLeft: 'auto', marginRight: 'auto' }}
          >
            <div style={{ height: '4.5vh', width: 'auto', marginTop: '6vh' }}>
              <img src={logo} alt="" height="100%" width="auto" />
            </div>
            <div
              style={{
                height: '7vh',
                width: '100%',
                marginTop: '10vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img
                src={otpimage}
                alt=""
                style={{ height: '100%', width: 'auto' }}
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  fontSize: '5vw',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  letterSpacing: '0.1vw',
                  marginTop: '3vh',
                }}
              >
                OTP Verification
              </div>
              <div
                style={{
                  fontSize: '3.5vw',
                  color: 'rgba(0, 0, 0, 0.6)',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  letterSpacing: '0.1vw',
                  marginBottom: '15px',
                }}
              >
                Enter the OTP sent to +91-{phone}
              </div>
              <Form1 onSubmit={handleVerifyOtp}>
                <Input
                  type="text"
                  placeholder="Enter OTP"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  required
                  style={{ backgroundColor: '#fff' }}
                />
                {error && (
                  <div
                    style={{
                      color: 'red',
                      marginTop: '-12px',
                      fontSize: '10px',
                      marginBottom: '10px',
                    }}
                  >
                    {error}
                  </div>
                )}
                <div
                  style={{
                    display: 'flex',
                    color: 'rgba(0,0,0,0.6)',
                    marginTop: '-1.1vh',
                    marginBottom: '2vh',
                    letterSpacing: '0.1vw',
                    marginLeft: '2vw',
                  }}
                >
                  <div
                    style={{
                      fontSize: '3.5vw',
                      color: '#FAA818',
                      cursor: 'pointer',
                    }}
                    type="button"
                    onClick={handleSendOtp}
                  >
                    {' '}
                    Resend OTP
                  </div>
                </div>

                <Button type="submit">
                  <img src={tyre} alt="Tyre" />
                  <span>Verify OTP</span>
                </Button>
              </Form1>
            </div>
          </div>
        )
      ) : (
        <FormContainer>
          {loading ? (
            <LoadingSpinner />
          ) : !otpSent ? (
            <Form onSubmit={handleSendOtp}>
              <div
                style={{
                  fontSize: '1.6vw',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  letterSpacing: '0.1vw',
                }}
              >
                Welcome to Hubio
              </div>
              <div
                style={{
                  fontSize: '1vw',
                  color: 'rgba(0, 0, 0, 0.6)',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  letterSpacing: '0.1vw',
                  marginBottom: '15px',
                }}
              >
                Your Ultimate Car Care Solution!
              </div>

              <Input
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
              <Input
                type="tel"
                placeholder="Phone Number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
              />
              {error && (
                <div
                  style={{
                    color: 'red',
                    marginTop: '-12px',
                    fontSize: '12px',
                    marginBottom: '10px',
                  }}
                >
                  {error}
                </div>
              )}
              <Button type="submit">
                <img src={tyre} alt="Tyre" />
                <span>Send OTP</span>
              </Button>
            </Form>
          ) : (
            <Form onSubmit={handleVerifyOtp}>
              <div
                style={{
                  fontSize: '1.6vw',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  letterSpacing: '0.1vw',
                }}
              >
                OTP Verification
              </div>
              <div
                style={{
                  fontSize: '1vw',
                  color: 'rgba(0, 0, 0, 0.6)',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  letterSpacing: '0.1vw',
                  marginBottom: '15px',
                }}
              >
                Enter the OTP sent to +91-{phone}
              </div>

              <Input
                type="text"
                placeholder="Enter OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                required
              />
              {error && (
                <div
                  style={{
                    color: 'red',
                    marginTop: '-12px',
                    fontSize: '12px',
                    marginBottom: '2vh',
                  }}
                >
                  {error}
                </div>
              )}
              <div
                style={{
                  display: 'flex',
                  color: 'rgba(0,0,0,0.6)',
                  marginTop: '-2.1vh',
                  marginBottom: '2vh',
                  letterSpacing: '0.1vw',
                }}
              >
                <div
                  style={{
                    fontSize: '1vw',
                    color: '#000000',
                    cursor: 'pointer',
                  }}
                  type="button"
                  onClick={handleSendOtp}
                >
                  {' '}
                  Resend OTP
                </div>
              </div>
              <Button type="submit">
                <img src={tyre} alt="Tyre" />
                <span>Verify OTP</span>
              </Button>
            </Form>
          )}
        </FormContainer>
      )}
    </div>
  )
}

export default Driver
