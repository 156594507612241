import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { useDevice } from '../Components/Context/deviceContext'
import styled from 'styled-components'
import location1 from '../Components/Assets/Location.svg'
import address from '../Components/Assets/address.svg'
import clock from '../Components/Assets/clock.svg'
import star from '../Components/Assets/star.svg'
import tyre from '../Components/Assets/tyre.png'
import MapComponent from './MapComponent'
import arrow from '../Components/Assets/arrow.svg'
import { LoadingSpinner } from '../Components/LoadingSpinner '

const Heading = styled.div`
  font-size: 2.2vw;
  font-weight: 500;
  margin-left: 5%;
  margin-top: 5vh;
  letter-spacing: 0.08vw;
`

const ButtonGroup = styled.div`
  display: flex;
  gap: 1vw;
  margin-top: 1vh;
  @media (min-width: 768px) and (max-width: 1200px) {
    gap: 3vw;
  }
  @media (max-width: 767px) {
    gap: 3vw;
  }
`

const Button = styled.button`
  padding: 0.5vw 2vw;
  width: 9.5vw;
  font-size: 1.2vw;
  cursor: pointer;
  background-color: ${(props) => (props.selected ? '#FADAA1' : '#fff')};
  border: 2px solid #faba49;
  border-radius: 2vw;
  color: black;

  &:hover {
    background-color: #fadaa1;
  }
  @media (min-width: 501px) and (max-width: 1200px) {
    padding: 0.5vw 2vw;
    border-radius: 2vw;
    width: 9.5vw;
    font-size: 4.5vw;
  }
  @media (max-width: 500px) {
    padding: 1.7vw 2vw;
    border-radius: 7vw;
    width: 30vw;
    font-size: 4.5vw;
  }
`

const DateInput = styled.input`
  font-size: 1.2vw;
  padding: 0.5vw;
  margin-top: 1vh;
  border-radius: 2vw;
  border: 2px solid #faba49;
  background-color: white;
  cursor: pointer; /* Makes it look clickable */

  /* Disable manual text entry (non-editable) */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  /* When input is focused, style the border and background */
  &:focus {
    border: 1px solid #faba49;
    background-color: #ffebcc;
  }

  @media (min-width: 768px) and (max-width: 1200px) {
    font-size: 4.5vw;
    padding: 1.7vw 2vw;
  }

  @media (max-width: 767px) {
    font-size: 4.5vw;
    padding: 1.7vw 2vw;
    border-radius: 6vw;
  }
`

const SlectInput = styled.select`
  font-size: 1vw;
  padding: 0.5vw;
  margin-top: 1vh;
  border-radius: 2vw;
  border: 2px solid #faba49;
  background-color: white;

  &:focus {
    border: 1px solid #faba49;
    background-color: #ffebcc;
  }
  @media (min-width: 768px) and (max-width: 1200px) {
    font-size: 4.5vw;
    padding: 1.7vw 2vw;
  }
  @media (max-width: 767px) {
    font-size: 4.5vw;
    padding: 1.7vw 2vw;
    border-radius: 6vw;
  }
`

const Button1 = styled.button`
  position: relative; /* Ensure the button is positioned relative to its container */
  padding: 10px;
  background-color: #faba49;
  color: #000;
  font-size: 16px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  overflow: hidden; /* Ensure no content overflows the button */
  display: flex; /* Use flex to align text properly */
  align-items: center; /* Center text vertically */
  justify-content: center; /* Center text horizontally */

  img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover; /* Ensure image covers the button */
    z-index: 1; /* Ensure image is behind the text */
    opacity: 0.1;
  }

  span {
    position: relative;
    z-index: 2; /* Ensure text is above the image */
  }

  @media (min-width: 768px) and (max-width: 1200px) {
    border-radius: 0px;
    padding: 15px;
  }
  @media (max-width: 767px) {
    border-radius: 7vw;
    padding: 15px;
  }
`

const HideContainer = styled.div`
  @media (max-width: 1200px) {
    display: none;
  }
`

const Input = styled.input`
  margin-bottom: 15px;
  width: auto;
  padding: 10px;
  border-radius: 30px;
  background-color: #ffffff;
  border: 1px solid #ff8c00;
  font-size: 16px;

  &:focus {
    background-color: #fff7d4;
    border: 1px solid #ff8c00;
    outline: none;
  }

  @media (min-width: 768px) and (max-width: 1200px) {
    width: 88%;
  }

  @media (max-width: 767px) {
    font-size: 14px;
    width: 88%;
    padding: 15px 10px;
    margin-left: auto;
    margin-right: auto;
  }
`

const CarWashDetails = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { phoneNumber } = useDevice()
  const [details, setDetails] = useState(null)
  const [loading, setLoading] = useState(true)
  const [loading1, setLoading1] = useState(true)
  const [error, setError] = useState(null)
  const [driverRequired, setDriverRequired] = useState(false)
  const [selectedDate, setSelectedDate] = useState('')
  const [services, setServices] = useState([])
  const [selectedService, setSelectedService] = useState('')
  const [selectedServiceCost, setSelectedServiceCost] = useState('');
  const [hiddenClass, setHiddenClass] = useState('')
  const [hiddenClassmobile, setHiddenClassmobile] = useState('')
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const distance = queryParams.get('distance')
  const today = new Date().toISOString().split('T')[0]
  const [availableSlots, setAvailableSlots] = useState([])
  const [selectedSlot, setSelectedSlot] = useState('')
  const [addresses, setAddresses] = useState([]) // State for addresses
  const [selectedAddress, setSelectedAddress] = useState('')
  const [loadingAddresses, setLoadingAddresses] = useState(false)
  const [newAddress, setNewAddress] = useState({
    street: '',
    city: '',
    state: '',
    postal_code: '',
    country: '',
  })
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [cars, setCars] = useState([]) // State for car details
  const [selectedCar, setSelectedCar] = useState('') // State for selected car
  const [loadingCars, setLoadingCars] = useState(false)
  const [slotSucces, setslotSucces] = useState(false)
  const [newCar, setNewCar] = useState({
    model: '',
    color: '',
    carNumber: '',
    carName: '',
    carType: '',
    rcPhoto: null,
    insurancePhoto: null,
  })


  const [isDialogOpen1, setIsDialogOpen1] = useState(false)


  // Function to open the dialog
  const openDialog1 = () => {
    setIsDialogOpen1(true)
  }

  // Function to close the dialog and reset car details form
  const closeDialog1 = () => {
    setIsDialogOpen1(false)
    setNewCar({
      model: '',
      color: '',
      carNumber: '',
      carName: '',
      carType: '',
      rcPhoto: null,
      insurancePhoto: null,
    })
  }

  // Function to handle input changes (text fields)
  const handleInputChange1 = (e) => {
    const { name, value } = e.target
    setNewCar((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  // Function to handle file uploads (for rcPhoto and insurancePhoto)
  const handleFileChange = (e) => {
    const { name, files } = e.target
    setNewCar((prev) => ({
      ...prev,
      [name]: files[0], // Store the selected file
    }))
  }

  // Function to submit the new car details
  const handleSubmitCar = async () => {
    if (!newCar.model) {
      alert('Please enter the car model.')
      return
    }

    if (!newCar.color) {
      alert('Please enter the car color.')
      return
    }

    if (!newCar.carNumber) {
      alert('Please enter the car number.')
      return
    }

    if (!newCar.carName) {
      alert('Please enter the car name.')
      return
    }

    if (!newCar.carType) {
      alert('Please enter the car type.')
      return
    }

    // Use FormData for file uploads
    const formData = new FormData()

    // Append fields to formData
    formData.append('phone', phoneNumber)
    formData.append('model', newCar.model)
    formData.append('color', newCar.color)
    formData.append('car_number', newCar.carNumber)
    formData.append('car_name', newCar.carName)
    formData.append('car_type', newCar.carType)
    if (newCar.rcPhoto) formData.append('rcPhoto', newCar.rcPhoto)
    if (newCar.insurancePhoto)
      formData.append('insurancePhoto', newCar.insurancePhoto)

    try {
      const response = await axios.post(
        'https://www.dsctech.in/api/add_car_details/',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data', // Set the proper headers for file upload
          },
        },
      )

      console.log('Car details added:', response.data)
      fetchCarDetails()
      closeDialog1()
    } catch (error) {
      setError(
        `Error adding car details: ${error.response ? error.response.data : error.message
        }`,
      )
      console.error('Error:', error)
    }
  }

  const openDialog = () => {
    setIsDialogOpen(true)
  }

  // Function to close the dialog
  const closeDialog = () => {
    setIsDialogOpen(false)
    // Reset new address fields if needed
    setNewAddress({
      street: '',
      city: '',
      state: '',
      postal_code: '',
      country: '',
    })
  }

  // Function to handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target
    setNewAddress((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  // Function to submit the new address
  const handleSubmitAddress = async () => {
    // Check for missing required fields
    if (!newAddress.street) {
      alert('Please enter your street address.')
      return
    }

    if (!newAddress.city) {
      alert('Please enter your city.')
      return
    }

    if (!newAddress.state) {
      alert('Please enter your state.')
      return
    }

    if (!newAddress.postal_code) {
      alert('Please enter your postal code.')
      return
    }

    if (!newAddress.country) {
      alert('Please enter your country.')
      return
    }

    try {
      const response = await axios.post(
        'https://www.dsctech.in/add-address/',
        {
          phone: phoneNumber,
          street: newAddress.street,
          city: newAddress.city,
          state: newAddress.state,
          postal_code: newAddress.postal_code,
          country: newAddress.country,
        },
        {
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
          },
        },
      )

      console.log('Address added:', response.data)
      // Optionally fetch addresses
      fetchAddresses() // Make sure this function is defined

      // Close dialog
      closeDialog()
    } catch (error) {
      console.error('Error adding address:', error)
      // Handle error state if needed
    }
  }

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1200) {
        setHiddenClass('hidden')
      } else {
        setHiddenClass('')
      }
    }

    window.addEventListener('resize', handleResize)
    handleResize() // Initial check

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    const handleResizemobile = () => {
      if (window.innerWidth < 500) {
        setHiddenClassmobile('hidden')
      } else {
        setHiddenClassmobile('')
      }
    }

    window.addEventListener('resize', handleResizemobile)
    handleResizemobile() // Initial check

    return () => {
      window.removeEventListener('resize', handleResizemobile)
    }
  }, [])

  useEffect(() => {
    const fetchDetails = async () => {
      setLoading(true)
      setError(null)

      if (!id || !phoneNumber) {
        setError('ID or Phone Number is missing')
        setLoading(false)
        return
      }

      try {
        const url = `https://www.dsctech.in/carshops/${id}`
        const response = await axios.get(url)

        if (response.data) {
          const shopDetails = response.data
          setDetails(shopDetails)
          setServices(shopDetails.services || []) // Set services from the API response
        } else {
          setError('No data found')
        }
      } catch (error) {
        setError(`Error fetching car shop details: ${error.message}`)
        console.error('Error:', error)
      } finally {
        setLoading(false)
      }
    }

    fetchDetails()
  }, [id, phoneNumber])

  const handleBooking = async () => {
    setLoading1(true)

    // Check if selectedDate or selectedService is empty
    if (!selectedService) {
      alert('Please select a service.')
      setLoading1(false)
      return
    }

    if (!selectedDate) {
      alert('Please select a date.')
      setLoading1(false)
      return
    }

    // Format the date only after checking it's not empty
    const formattedDate = new Date(selectedDate).toISOString().split('T')[0]

    const params = {
      shop: id,
      service: selectedService,
      booking_date: formattedDate,
    }

    try {
      const response = await axios.post(
        'https://www.dsctech.in/api/fetch_available_slots/',
        params,
      )

      const fetchedSlots = response.data.available_slots // Adjusted to match your API response
      console.log('Available Slots:', fetchedSlots)
      setAvailableSlots(fetchedSlots)
      fetchAddresses()
      fetchCarDetails()
      setslotSucces(true)
    } catch (error) {
      alert(
        `Error fetching available slots
        }`,
      )
      console.error('Error:', error)
    } finally {
      setLoading1(false)
    }
  }

  // Fetch addresses from the API
  const fetchAddresses = async () => {
    setLoadingAddresses(true)

    try {
      const response = await axios.get(
        `https://www.dsctech.in/addresses/${phoneNumber}/`,
      )
      console.log('Fetched Addresses:', response.data.addresses) // Log the addresses data

      if (
        Array.isArray(response.data.addresses) &&
        response.data.addresses.length > 0
      ) {
        const addressesList = response.data.addresses
        setAddresses(addressesList) // Set the fetched addresses from the 'addresses' key
        setSelectedAddress(addressesList[addressesList.length - 1].id) // Automatically select the last address
      } else {
        setAddresses([])
        setSelectedAddress('') // Reset selectedAddress if no addresses are found
      }
    } catch (error) {
      setError(
        `Error fetching addresses: ${error.response ? error.response.data : error.message
        }`,
      )
      console.error('Error:', error)
    } finally {
      setLoadingAddresses(false)
    }
  }

  if (loading) return <LoadingSpinner />
  if (error) return <div>{error}</div>
  if (!details) return <div>No details available</div>

  const handleClick = () => {
    navigate(-1)
  }

  const handleFocus = (e) => {
    e.target.showPicker()
  }

  // Fetch car details from the API
  const fetchCarDetails = async () => {
    setLoadingCars(true) // Start loading

    try {
      const response = await axios.get(
        `https://www.dsctech.in/api/car/${phoneNumber}/`, // API URL to fetch car details
      )
      console.log('Fetched Car Details:', response.data.cars) // Log the fetched car details
      if (Array.isArray(response.data.cars)) {
        if (response.data.cars.length > 0) {
          const carList = response.data.cars
          setCars(carList) // Set fetched car details
          setSelectedCar(carList[carList.length - 1].id) // Automatically select the last car
        } else {
          setCars([]) // Reset if no cars are found
          setSelectedCar('') // Reset selected car
        }
      } else {
        setCars([])
        setSelectedCar('')
        console.error('Invalid response format. Please try again later.') // Log for debugging
      }
    } catch (error) {
      // Handle API errors
      setError(
        `Error fetching car details: ${error.response
          ? error.response.data.message || error.message
          : error.message
        }`,
      )
      console.error('Error:', error) // Log the error for debugging
    } finally {
      setLoadingCars(false) // Stop loading
    }
  }

  const createBooking = async () => {
    if (!selectedSlot) {
      alert('Please select a time slot.')
      return
    }

    if (!selectedAddress && driverRequired) {
      // Check if address is needed
      alert('Please select an address.')
      return
    }

    if (!selectedCar) {
      alert('Please select a car.')
      return
    }

    setLoading(true) // Set loading to true

    const selectedServiceId = selectedService ? selectedService : null

    // Ensure selectedDate is a valid date before formatting
    const date = new Date(selectedDate)

    if (isNaN(date.getTime())) {
      alert('Please select a valid date.')
      setLoading(false)
      return
    }

    const formattedDate = date.toISOString().split('T')[0]

    const bookingData = {
      user_phone: phoneNumber,
      selected_slot: selectedSlot,
      booking_date: formattedDate,
      shop: id,
      service: selectedServiceId,
      address: selectedAddress, // ID for selected address
      car: selectedCar, // ID for selected car
      needs_driver: driverRequired, // Pass the driver requirement
    }

    try {
      const response = await axios.post(
        'https://www.dsctech.in/api/create_booking/',
        bookingData,
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )

      if (response.status === 200) {
        console.log('Booking created:', response.data)
        const paymentData = {
          BookingID: String(response.data.booking.id), 
          amount: parseInt(selectedServiceCost, 10),
          phone: phoneNumber,
        }

        console.log(paymentData)

        const paymentResponse = await fetch(
          'https://www.dsctech.in/api/initiate-payment/',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(paymentData),
          },
        )

        const paymentResult = await paymentResponse.json()

        if (paymentResult.success) {
          // Redirect to payment URL if successful
          window.location.href =
            paymentResult.data.instrumentResponse.redirectInfo.url
        } else {
          console.error('Payment initiation failed:', paymentResult.message)
          setError('Failed to initiate payment. Please try again.')
        }
      } else {
        console.error(
          'Failed to create booking:',
          response.status,
          response.data,
        )
        setError('Failed to create booking. Please try again.')
      }
    } catch (error) {
      console.error('Error creating booking:', error)
      setError('An error occurred. Please try again later.')
    } finally {
      setLoading(false) // Set loading to false after the request
    }
  }

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value)
    setslotSucces(false) // Set slotSuccess to false when date is changed
  }

  const handleServiceChange = (e) => {
    const selectedId = e.target.value;
    setSelectedService(selectedId);

    // Find the selected service and set its cost
    const service = services.find((service) => String(service.id) === String(selectedId));

    if (service) {
      setSelectedServiceCost(service.cost);
    } else {
      setSelectedServiceCost('');
      console.log('No service found for selected ID');
    }
    setslotSucces(false)
  };

  return (
    <div
      style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}
    >
      {hiddenClass ? (
        <div style={{ width: '88%', marginLeft: 'auto', marginRight: 'auto' }}>
          <div
            onClick={handleClick}
            style={{
              height: hiddenClassmobile ? '5vh' : '7vh',
              width: hiddenClassmobile ? '5vh' : '7vh',
              borderRadius: '50%',
              backgroundColor: '#FABA49',
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              marginTop: '6vh',
            }}
          >
            <img
              src={arrow}
              alt="arrow"
              height="50%"
              style={{
                transform: 'rotate(-180deg)',
              }}
            />
          </div>
          <div style={{ marginTop: '4vh' }}>
            <div
              style={{
                height: '24vh',
                width: '24vh',
                borderRadius: '6vw',
                backgroundColor: 'transparent',
                overflow: 'hidden',
                marginLeft: 'auto',
                marginRight: 'auto',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: '1px solid #FABA49',
              }}
            >
              <img
                src={`https://www.dsctech.in${details.upload_carshop_image}`}
                alt="Car Wash Shop"
                height="85%"
                width="85%"
                style={{ objectFit: 'cover', borderRadius: '4vw' }}
              />
            </div>
          </div>
          <div
            style={{
              fontSize: '6vw',
              letterSpacing: '0.2vw',
              marginTop: '2vh',
              marginLeft: 'auto',
              marginRight: 'auto',
              textAlign: 'center', // Optional: centers the text horizontally
            }}
          >
            {details.shop_name || 'Shop Name Not Available'}
          </div>
          <div
            style={{
              marginTop: '3vh',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{ display: 'flex', gap: '0.3vw', alignItems: 'center' }}
            >
              <div
                style={{
                  height: '4.5vw',
                  width: '4.5vw',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <img
                  src={location1}
                  alt=""
                  style={{ height: '100%', width: '100%' }}
                />
              </div>
              <div
                style={{
                  fontSize: '4.5vw',
                  letterSpacing: '0.1vw',
                  lineHeight: '1.2vw',
                }}
              >
                {distance !== null ? `${distance} kms away` : 'Distance'}
              </div>
            </div>
            <div
              style={{ display: 'flex', gap: '0.3vw', alignItems: 'center' }}
            >
              <div
                style={{
                  height: '4.5vw',
                  width: '4.5vw',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <img
                  src={star}
                  alt=""
                  style={{ height: '100%', width: '100%' }}
                />
              </div>
              <div
                style={{
                  fontSize: '4.5vw',
                  letterSpacing: '0.1vw',
                  lineHeight: '1.2vw',
                }}
              >
                4.5
              </div>
            </div>
          </div>
          <div
            style={{
              marginTop: '3vh',
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '3vh',
            }}
          >
            <div
              style={{ display: 'flex', gap: '0.3vw', alignItems: 'center' }}
            >
              <div
                style={{
                  height: '4.5vw',
                  width: '4.5vw',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <img
                  src={address}
                  alt=""
                  style={{ height: '100%', width: '100%' }}
                />
              </div>
              <div style={{ fontSize: '4.5vw', letterSpacing: '0.1vw' }}>
                {details.address || 'Address not available'}
              </div>
            </div>
            <div
              style={{ display: 'flex', gap: '0.3vw', alignItems: 'center' }}
            >
              <div
                style={{
                  height: '4.5vw',
                  width: '4.5vw',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <img
                  src={clock}
                  alt=""
                  style={{ height: '100%', width: '100%' }}
                />
              </div>
              <div style={{ fontSize: '4.5vw', letterSpacing: '0.1vw' }}>
                Washtime-{services[0].duration_in_hours || 'Not available'} hrs
              </div>
            </div>
          </div>
          {!slotSucces ? (
            <div>
              <div style={{ marginTop: '3vh' }}>
                <div style={{ fontSize: '4.5vw' }}>Need Driver?</div>
                <ButtonGroup>
                  <Button
                    selected={driverRequired === true}
                    onClick={() => setDriverRequired(true)}
                  >
                    Yes
                  </Button>
                  <Button
                    selected={driverRequired === false}
                    onClick={() => setDriverRequired(false)}
                  >
                    No
                  </Button>
                </ButtonGroup>
              </div>
              <div
                style={{
                  marginTop: '3vh',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <label htmlFor="date-select" style={{ fontSize: '4.5vw' }}>
                  Select Date
                </label>
                <DateInput
                  type="date"
                  id="date-select"
                  min={today}
                  value={selectedDate}
                  onChange={handleDateChange}
                  onFocus={(e) => e.target.showPicker()}
                />
                <div
                  style={{
                    marginTop: '3vh',
                    display: 'flex',
                    gap: '1vw',
                    marginBottom: '15vh',
                  }}
                >
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <label
                      htmlFor="service-select"
                      style={{ fontSize: '4.5vw' }}
                    >
                      Select Services
                    </label>
                    <SlectInput
                      id="service-select"
                      value={selectedService}
                      onChange={handleServiceChange}
                      style={{ width: '88vw', textAlign: 'center' }}
                    >
                      <option value="">Select a service</option>
                      {services.map((service) => (
                        <option key={service.id} value={service.id}>
                          {`${service.service_name} (${service.cost} ₹)`}
                        </option>
                      ))}
                    </SlectInput>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div style={{ marginTop: '3vh' }}></div>
              <div style={{ fontSize: '4.5vw', color: 'rgba(0, 0, 0, 0.6)' }}>
                Select Slot
              </div>
              <SlectInput
                id="slot-select"
                value={selectedSlot}
                onChange={(e) => setSelectedSlot(e.target.value)}
                style={{
                  width: '88vw',
                  textAlign: 'center',
                  marginTop: '1vh',
                  height: '6vh',
                }}
                disabled={!availableSlots.length} // Disable if no slots are available
              >
                <option value="">Select an available slot</option>
                {availableSlots.map((slot, index) => (
                  <option key={index} value={slot}>
                    {slot}
                  </option>
                ))}
              </SlectInput>
              <div style={{ marginTop: '3vh' }}></div>
              {driverRequired && (
                <div>
                  <div
                    style={{ fontSize: '4.5vw', color: 'rgba(0, 0, 0, 0.6)' }}
                  >
                    Address
                  </div>
                  <SlectInput
                    id="address-select"
                    value={selectedAddress}
                    onChange={(e) => setSelectedAddress(e.target.value)}
                    style={{
                      width: '88vw',
                      textAlign: 'center',
                      marginTop: '1vh',
                    }}
                    disabled={loadingAddresses || !addresses.length} // Disable if loading addresses or none are available
                  >
                    <option value="">Select an address</option>
                    {addresses.map((address, index) => (
                      <option key={index} value={address.id}>
                        {`${address.street}, ${address.city}, ${address.state}`}{' '}
                        {/* Display relevant address fields */}
                      </option>
                    ))}
                  </SlectInput>
                  <div style={{ marginTop: '1vh' }}></div>
                  <Button
                    onClick={openDialog}
                    style={{
                      height: '5vh',
                      padding: '5px',
                      borderRadius: '20px',
                      backgroundColor: '#FADAA1',
                      color: 'black',
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '14px',
                    }}
                  >
                    Add Address
                  </Button>
                  <div style={{ marginTop: '3vh' }}></div>
                </div>
              )}
              <div style={{ fontSize: '4.5vw', color: 'rgba(0, 0, 0, 0.6)' }}>
                Car Details
              </div>
              <SlectInput
                id="car-select"
                value={selectedCar}
                onChange={(e) => setSelectedCar(e.target.value)} // Set selected car when user selects
                style={{ width: '88vw', textAlign: 'center', marginTop: '1vh' }}
                disabled={loadingCars || !cars.length} // Disable if loading or no cars available
              >
                <option value="">Select a car</option>
                {cars.map((car, index) => (
                  <option key={index} value={car.id}>
                    {`${car.car_name}, ${car.model}, ${car.car_type}, ${car.car_number}`}{' '}
                    {/* Display relevant car details */}
                  </option>
                ))}
              </SlectInput>
              <div style={{ marginTop: '1vh' }}></div>
              <Button
                onClick={openDialog1}
                style={{
                  height: '5vh',
                  padding: '5px',
                  borderRadius: '20px',
                  backgroundColor: '#FADAA1',
                  color: 'black',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '14px',
                }}
              >
                Add Car
              </Button>
              <div style={{ marginTop: '14vh' }}></div>
              {isDialogOpen1 && (
                <>
                  {/* Overlay with blur effect */}
                  <div
                    style={{
                      position: 'fixed',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      backgroundColor: 'rgba(0, 0, 0, 0.5)',
                      backdropFilter: 'blur(5px)',
                      zIndex: 1,
                    }}
                  />

                  {/* Dialog Container */}
                  <div
                    style={{
                      position: 'fixed',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      backgroundColor: '#ffffff',
                      padding: '20px',
                      borderRadius: '10px',
                      boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                      zIndex: 2,
                      width: '80%',
                    }}
                  >
                    <h4>Add New Car Details</h4>

                    {/* Error Message */}
                    {error && <p style={{ color: 'red' }}>{error}</p>}

                    {/* Container for Input Fields */}
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'start',
                        width: '100%',
                        gap: '5%',
                      }}
                    >
                      <Input
                        type="text"
                        placeholder="Car Model"
                        name="model"
                        value={newCar.model}
                        onChange={handleInputChange1}
                      />
                      <Input
                        type="text"
                        placeholder="Car Color"
                        name="color"
                        value={newCar.color}
                        onChange={handleInputChange1}
                      />
                      <Input
                        type="text"
                        placeholder="Car Number"
                        name="carNumber"
                        value={newCar.carNumber}
                        onChange={handleInputChange1}
                      />
                      <Input
                        type="text"
                        placeholder="Car Name"
                        name="carName"
                        value={newCar.carName}
                        onChange={handleInputChange1}
                      />
                      <Input
                        type="text"
                        placeholder="Car Type"
                        name="carType"
                        value={newCar.carType}
                        onChange={handleInputChange1}
                      />
                      <Input
                        type="file"
                        name="rcPhoto"
                        onChange={handleFileChange}
                      />
                      <Input
                        type="file"
                        name="insurancePhoto"
                        onChange={handleFileChange}
                      />
                    </div>

                    {/* Submit and Cancel Buttons */}
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <Button
                        onClick={handleSubmitCar}
                        style={{
                          marginTop: '10px',
                          padding: '10px',
                          borderRadius: '20px',
                          backgroundColor: '#FADAA1',
                          color: 'black',
                          cursor: 'pointer',
                          marginRight: '10px',
                        }}
                      >
                        Submit
                      </Button>
                      <Button
                        onClick={closeDialog1}
                        style={{
                          marginTop: '10px',
                          padding: '10px',
                          borderRadius: '20px',
                          backgroundColor: '#ffffff',
                          color: 'black',
                          cursor: 'pointer',
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </>
              )}
              {isDialogOpen && (
                <>
                  {/* Overlay with blur effect */}
                  <div
                    style={{
                      position: 'fixed',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
                      backdropFilter: 'blur(5px)', // Adjust the blur intensity
                      zIndex: 1, // Ensure overlay is above other content
                    }}
                  />

                  {/* Dialog Container */}
                  <div
                    style={{
                      position: 'fixed',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      backgroundColor: '#ffffff',
                      padding: '20px',
                      borderRadius: '10px',
                      boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                      zIndex: 2, // Ensure dialog is above the overlay
                      width: '80%', // Set a width for the dialog
                    }}
                  >
                    <h4>Add New Address</h4>

                    {/* Container for Input Fields */}
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'start',
                        width: '100%',
                        gap: '5%',
                      }}
                    >
                      <Input
                        type="text"
                        placeholder="Street"
                        name="street"
                        value={newAddress.street}
                        onChange={handleInputChange}
                      />
                      <Input
                        type="text"
                        placeholder="City"
                        name="city"
                        value={newAddress.city}
                        onChange={handleInputChange}
                      />
                      <Input
                        type="text"
                        placeholder="State"
                        name="state"
                        value={newAddress.state}
                        onChange={handleInputChange}
                      />
                      <Input
                        type="text"
                        placeholder="Postal Code"
                        name="postal_code"
                        value={newAddress.postal_code}
                        onChange={handleInputChange}
                      />
                      <Input
                        type="text"
                        placeholder="Country"
                        name="country"
                        value={newAddress.country}
                        onChange={handleInputChange}
                      />
                    </div>

                    {/* Submit and Cancel Buttons */}
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <Button
                        onClick={handleSubmitAddress}
                        style={{
                          marginTop: '10px',
                          padding: '10px',
                          borderRadius: '20px',
                          backgroundColor: '#FADAA1',
                          color: 'black',
                          cursor: 'pointer',
                          marginRight: '10px', // Space between buttons
                        }}
                      >
                        Submit
                      </Button>
                      <Button
                        onClick={closeDialog}
                        style={{
                          marginTop: '10px',
                          padding: '10px',
                          borderRadius: '20px',
                          backgroundColor: '#ffffff',
                          color: 'black',
                          cursor: 'pointer',
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}

          {!slotSucces ? (
            <div
              style={{
                position: 'fixed',
                bottom: '4vh',
                left: 'auto',
                width: '88%',
                marginTop: '3vh',
                display: 'flex',
                borderRadius: '7vh',
                justifyContent: 'center', // Center the button horizontally
                backgroundColor: 'white', // Optional: to ensure the button is visible against the page content
              }}
            >
              <Button1
                type="button"
                style={{ width: '100%', textAlign: 'center' }}
                onClick={handleBooking}
              >
                <img src={tyre} alt="Tyre" />
                <span>Select Slot</span>
              </Button1>
            </div>
          ) : (
            <div
              style={{
                position: 'fixed',
                bottom: '4vh',
                left: 'auto',
                width: '88%',
                marginTop: '3vh',
                display: 'flex',
                borderRadius: '7vh',
                justifyContent: 'center', // Center the button horizontally
                backgroundColor: 'white', // Optional: to ensure the button is visible against the page content
              }}
            >
              <Button1
                type="button"
                style={{ width: '100%', textAlign: 'center' }}
                onClick={createBooking}
              >
                <img src={tyre} alt="Tyre" />
                <span>Pay Now</span>
              </Button1>
            </div>
          )}
        </div>
      ) : (
        <HideContainer>
          <div style={{ height: '8vh' }} />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div
              style={{
                minHeight: '50vh',
                width: '50vh',
                borderRadius: '3vw',
                marginLeft: '13%',
                marginTop: '3vh',
                border: '1px solid #FABA49',
              }}
            >
              <div
                style={{
                  height: '44vh',
                  width: '44vh',
                  borderRadius: '1.5vw',
                  backgroundColor: 'transparent',
                  overflow: 'hidden',
                  marginLeft: '3vh',
                  marginTop: '3vh',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img
                  src={`https://www.dsctech.in${details.upload_carshop_image}`}
                  alt="Car Wash Shop"
                  height="100%"
                  width="100%"
                  style={{ objectFit: 'cover', borderRadius: '2.5vw' }}
                />
              </div>
              <div
                style={{
                  marginLeft: '3vh',
                  marginTop: '2vh',
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginRight: '3vh',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    gap: '0.3vw',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      height: '1.2vw',
                      width: '1.2vw',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      src={location1}
                      alt=""
                      style={{ height: '100%', width: '100%' }}
                    />
                  </div>
                  <div
                    style={{
                      fontSize: '1vw',
                      letterSpacing: '0.1vw',
                      lineHeight: '1.2vw',
                    }}
                  >
                    {distance !== null
                      ? `${distance} kms away`
                      : 'Distance not available'}
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    gap: '0.3vw',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      height: '1.2vw',
                      width: '1.2vw',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      src={star}
                      alt=""
                      style={{ height: '100%', width: '100%' }}
                    />
                  </div>
                  <div
                    style={{
                      fontSize: '1vw',
                      letterSpacing: '0.1vw',
                      lineHeight: '1.2vw',
                    }}
                  >
                    4.5
                  </div>
                </div>
              </div>
              <div
                style={{
                  marginLeft: '3vh',
                  marginTop: '2vh',
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginRight: '3vh',
                  marginBottom: '3vh',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    gap: '0.3vw',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      height: '1.2vw',
                      width: '1.2vw',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      src={address}
                      alt=""
                      style={{ height: '100%', width: '100%' }}
                    />
                  </div>
                  <div style={{ fontSize: '1vw', letterSpacing: '0.1vw' }}>
                    {details.address || 'Address not available'}
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    gap: '0.3vw',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      height: '1.2vw',
                      width: '1.2vw',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      src={clock}
                      alt=""
                      style={{ height: '100%', width: '100%' }}
                    />
                  </div>
                  <div style={{ fontSize: '1vw', letterSpacing: '0.1vw' }}>
                    Washtime-{services[0].duration_in_hours || '0'} hrs
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'start',
                marginTop: '2vh',
              }}
            >
              <div
                style={{
                  fontSize: '1.6vw',
                  letterSpacing: '0.2vw',
                  fontWeight: '500',
                }}
              >
                {details.shop_name || 'Shop Name Not Available'}
              </div>
              {/* Driver Selection */}
              <div style={{ marginTop: '3vh' }}>
                <div style={{ fontSize: '1.2vw', color: 'rgba(0, 0, 0, 0.6)' }}>
                  Need Driver ?
                </div>
                <ButtonGroup>
                  <Button
                    selected={driverRequired === true}
                    onClick={() => setDriverRequired(true)}
                  >
                    Yes
                  </Button>
                  <Button
                    selected={driverRequired === false}
                    onClick={() => setDriverRequired(false)}
                  >
                    No
                  </Button>
                </ButtonGroup>
              </div>
              {/* Date and Time Selection */}
              <div
                style={{
                  marginTop: '3vh',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <div
                  htmlFor="date-select"
                  style={{ fontSize: '1.2vw', color: 'rgba(0, 0, 0, 0.6)' }}
                >
                  Select Date
                </div>
                <DateInput
                  type="date"
                  id="date-select"
                  min={today}
                  value={selectedDate}
                  onChange={handleDateChange}
                  style={{ width: '19vw', textAlign: 'center' }}
                  onFocus={(e) => e.target.showPicker()} // Opens the date picker on focus
                />
                <div style={{ marginTop: '3vh', display: 'flex', gap: '1vw' }}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <label
                      htmlFor="service-select"
                      style={{ fontSize: '1.2vw', color: 'rgba(0, 0, 0, 0.6)' }}
                    >
                      Select Services
                    </label>
                    <SlectInput
                      id="service-select"
                      value={selectedService}
                      onChange={handleServiceChange}
                      style={{ width: '20vw', textAlign: 'center' }}
                    >
                      <option value="">Select a service</option>
                      {services.map((service) => (
                        <option key={service.id} value={service.id}>
                          {`${service.service_name} (${service.cost} ₹)`}
                        </option>
                      ))}
                    </SlectInput>
                  </div>
                </div>
                <div style={{ marginTop: '3vh', display: 'flex', gap: '1vw' }}>
                  {!slotSucces ? (
                    <Button1
                      type="button"
                      style={{ width: '20vw', textAlign: 'center' }}
                      onClick={handleBooking}
                    >
                      <img src={tyre} alt="Tyre" />
                      <span>Select Slot</span>
                    </Button1>
                  ) : null}
                </div>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginRight: '13%',
              }}
            >
              <div style={{ marginTop: '2vh' }}></div>
              <div style={{ fontSize: '1.2vw', color: 'rgba(0, 0, 0, 0.6)' }}>
                Select Slot
              </div>
              <SlectInput
                id="slot-select"
                value={selectedSlot}
                onChange={(e) => setSelectedSlot(e.target.value)}
                style={{
                  width: '20vw',
                  textAlign: 'center',
                  marginTop: '1vh',
                  height: '6vh',
                }}
                disabled={!availableSlots.length} // Disable if no slots are available
              >
                <option value="">Select an available slot</option>
                {availableSlots.map((slot, index) => (
                  <option key={index} value={slot}>
                    {slot}
                  </option>
                ))}
              </SlectInput>
              <div style={{ marginTop: '1.5vh' }}></div>
              {driverRequired && (
                <div>
                  <div
                    style={{ fontSize: '1.2vw', color: 'rgba(0, 0, 0, 0.6)' }}
                  >
                    Address
                  </div>
                  <SlectInput
                    id="address-select"
                    value={selectedAddress}
                    onChange={(e) => setSelectedAddress(e.target.value)}
                    style={{
                      width: '20vw',
                      textAlign: 'center',
                      marginTop: '1vh',
                    }}
                    disabled={loadingAddresses || !addresses.length} // Disable if loading addresses or none are available
                  >
                    <option value="">Select an address</option>
                    {addresses.map((address, index) => (
                      <option key={index} value={address.id}>
                        {`${address.street}, ${address.city}, ${address.state}`}{' '}
                        {/* Display relevant address fields */}
                      </option>
                    ))}
                  </SlectInput>
                  <div style={{ marginTop: '1vh' }}></div>
                  <Button
                    onClick={openDialog}
                    style={{
                      height: '5vh',
                      padding: '5px',
                      borderRadius: '20px',
                      backgroundColor: '#FADAA1',
                      color: 'black',
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '14px',
                    }}
                  >
                    Add Address
                  </Button>
                  <div style={{ marginTop: '1.5vh' }}></div>
                </div>
              )}
              <div style={{ fontSize: '1.2vw', color: 'rgba(0, 0, 0, 0.6)' }}>
                Car Details
              </div>
              <SlectInput
                id="car-select"
                value={selectedCar}
                onChange={(e) => setSelectedCar(e.target.value)} // Set selected car when user selects
                style={{ width: '20vw', textAlign: 'center', marginTop: '1vh' }}
                disabled={loadingCars || !cars.length} // Disable if loading or no cars available
              >
                <option value="">Select a car</option>
                {cars.map((car, index) => (
                  <option key={index} value={car.id}>
                    {`${car.car_name}, ${car.model}, ${car.car_type}, ${car.car_number}`}{' '}
                    {/* Display relevant car details */}
                  </option>
                ))}
              </SlectInput>
              <div style={{ marginTop: '1vh' }}></div>
              <Button
                onClick={openDialog1}
                style={{
                  height: '5vh',
                  padding: '5px',
                  borderRadius: '20px',
                  backgroundColor: '#FADAA1',
                  color: 'black',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '14px',
                }}
              >
                Add Car
              </Button>
              <div style={{ marginTop: '3vh', display: 'flex', gap: '1vw' }}>
                {slotSucces ? (
                  <Button1
                    type="button"
                    style={{ width: '20vw', textAlign: 'center' }}
                    onClick={createBooking}
                  >
                    <img src={tyre} alt="Tyre" />
                    <span>Pay Now</span>
                  </Button1>
                ) : null}
              </div>
              {isDialogOpen1 && (
                <>
                  {/* Overlay with blur effect */}
                  <div
                    style={{
                      position: 'fixed',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      backgroundColor: 'rgba(0, 0, 0, 0.5)',
                      backdropFilter: 'blur(5px)',
                      zIndex: 1,
                    }}
                  />

                  {/* Dialog Container */}
                  <div
                    style={{
                      position: 'fixed',
                      top: '55%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      backgroundColor: '#ffffff',
                      padding: '20px',
                      borderRadius: '10px',
                      boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                      zIndex: 2,
                      width: '500px',
                    }}
                  >
                    <h4>Add New Car Details</h4>

                    {/* Error Message */}
                    {error && <p style={{ color: 'red' }}>{error}</p>}

                    {/* Container for Input Fields */}
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'start',
                        width: '100%',
                        gap: '5%',
                      }}
                    >
                      <Input
                        type="text"
                        placeholder="Car Model"
                        name="model"
                        value={newCar.model}
                        onChange={handleInputChange1}
                      />
                      <Input
                        type="text"
                        placeholder="Car Color"
                        name="color"
                        value={newCar.color}
                        onChange={handleInputChange1}
                      />
                      <Input
                        type="text"
                        placeholder="Car Number"
                        name="carNumber"
                        value={newCar.carNumber}
                        onChange={handleInputChange1}
                      />
                      <Input
                        type="text"
                        placeholder="Car Name"
                        name="carName"
                        value={newCar.carName}
                        onChange={handleInputChange1}
                      />
                      <Input
                        type="text"
                        placeholder="Car Type"
                        name="carType"
                        value={newCar.carType}
                        onChange={handleInputChange1}
                      />
                      <Input
                        type="file"
                        name="rcPhoto"
                        onChange={handleFileChange}
                      />
                      <Input
                        type="file"
                        name="insurancePhoto"
                        onChange={handleFileChange}
                      />
                    </div>

                    {/* Submit and Cancel Buttons */}
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <Button
                        onClick={handleSubmitCar}
                        style={{
                          marginTop: '10px',
                          padding: '10px',
                          borderRadius: '20px',
                          backgroundColor: '#FADAA1',
                          color: 'black',
                          cursor: 'pointer',
                          marginRight: '10px',
                        }}
                      >
                        Submit
                      </Button>
                      <Button
                        onClick={closeDialog1}
                        style={{
                          marginTop: '10px',
                          padding: '10px',
                          borderRadius: '20px',
                          backgroundColor: '#ffffff',
                          color: 'black',
                          cursor: 'pointer',
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </>
              )}
              {isDialogOpen && (
                <>
                  {/* Overlay with blur effect */}
                  <div
                    style={{
                      position: 'fixed',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
                      backdropFilter: 'blur(5px)', // Adjust the blur intensity
                      zIndex: 1, // Ensure overlay is above other content
                    }}
                  />

                  {/* Dialog Container */}
                  <div
                    style={{
                      position: 'fixed',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      backgroundColor: '#ffffff',
                      padding: '20px',
                      borderRadius: '10px',
                      boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                      zIndex: 2, // Ensure dialog is above the overlay
                      width: '500px', // Set a width for the dialog
                    }}
                  >
                    <h4>Add New Address</h4>

                    {/* Container for Input Fields */}
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'start',
                        width: '100%',
                        gap: '5%',
                      }}
                    >
                      <Input
                        type="text"
                        placeholder="Street"
                        name="street"
                        value={newAddress.street}
                        onChange={handleInputChange}
                      />
                      <Input
                        type="text"
                        placeholder="City"
                        name="city"
                        value={newAddress.city}
                        onChange={handleInputChange}
                      />
                      <Input
                        type="text"
                        placeholder="State"
                        name="state"
                        value={newAddress.state}
                        onChange={handleInputChange}
                      />
                      <Input
                        type="text"
                        placeholder="Postal Code"
                        name="postal_code"
                        value={newAddress.postal_code}
                        onChange={handleInputChange}
                      />
                      <Input
                        type="text"
                        placeholder="Country"
                        name="country"
                        value={newAddress.country}
                        onChange={handleInputChange}
                      />
                    </div>

                    {/* Submit and Cancel Buttons */}
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <Button
                        onClick={handleSubmitAddress}
                        style={{
                          marginTop: '10px',
                          padding: '10px',
                          borderRadius: '20px',
                          backgroundColor: '#FADAA1',
                          color: 'black',
                          cursor: 'pointer',
                          marginRight: '10px', // Space between buttons
                        }}
                      >
                        Submit
                      </Button>
                      <Button
                        onClick={closeDialog}
                        style={{
                          marginTop: '10px',
                          padding: '10px',
                          borderRadius: '20px',
                          backgroundColor: '#ffffff',
                          color: 'black',
                          cursor: 'pointer',
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </HideContainer>
      )}
      {/* <MapComponent origin={origin} destination={destination} /> */}
    </div>
  )
}

export default CarWashDetails
