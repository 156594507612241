import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDevice } from '../Components/Context/deviceContext'
import { useNavigate } from 'react-router-dom'
import { LoadingSpinner } from '../Components/LoadingSpinner '

// Styled components for Input and Button
const Input = styled.input`
  margin-bottom: 15px;
  width: auto;
  padding: 10px;
  border-radius: 30px;
  background-color: #ffffff;
  border: 1px solid #ff8c00;
  font-size: 16px;

  &:focus {
    background-color: #fff7d4;
    border: 1px solid #ff8c00;
    outline: none;
  }

  @media (min-width: 768px) and (max-width: 1200px) {
    width: 88%;
  }

  @media (max-width: 767px) {
    font-size: 14px;
    width: 88%;
    padding: 15px 10px;
    margin-left: auto;
    margin-right: auto;
  }
`

const Button = styled.button`
  padding: 0.5vw 2vw;
  width: 9.5vw;
  font-size: 1.2vw;
  cursor: pointer;
  background-color: #fadaa1;
  border: 2px solid #faba49;
  border-radius: 2vw;
  color: black;

  &:hover {
    background-color: #fadaa1;
  }

  @media (min-width: 501px) and (max-width: 1200px) {
    padding: 0.5vw 2vw;
    border-radius: 2vw;
    width: 9.5vw;
    font-size: 4.5vw;
  }

  @media (max-width: 500px) {
    padding: 1.7vw 2vw;
    border-radius: 7vw;
    width: 30vw;
    font-size: 4.5vw;
  }
`

// Centering container
const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
`
const Heading1 = styled.div`
  font-size: 2vw;
  font-weight: 400;
  @media (min-width: 501px) and (max-width: 1200px) {
    font-size: 3.8vw;
    font-wieght: 600;
    letter-spacing: 0.5vw;
  }
  @media (max-width: 500px) {
    font-size: 4.7vw;
    font-wieght: 700;
    letter-spacing: 0.5vw;
  }
`

const Driverdetails = () => {
  const navigate = useNavigate()
  const { phoneNumber } = useDevice()
  const [drivingLicenseNo, setDrivingLicenseNo] = useState('')
  const [profilePicture, setProfilePicture] = useState(null)
  const [drivingLicenseFile, setDrivingLicenseFile] = useState(null)
  const [loading, setLoading] = useState(false)
  const [hiddenClass, setHiddenClass] = useState('')
  const [hiddenClassmobile, setHiddenClassmobile] = useState('')

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1200) {
        setHiddenClass('hidden')
      } else {
        setHiddenClass('')
      }
    }

    window.addEventListener('resize', handleResize)
    handleResize() // Initial check

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    const handleResizemobile = () => {
      if (window.innerWidth < 500) {
        setHiddenClassmobile('hidden')
      } else {
        setHiddenClassmobile('')
      }
    }

    window.addEventListener('resize', handleResizemobile)
    handleResizemobile() // Initial check

    return () => {
      window.removeEventListener('resize', handleResizemobile)
    }
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)

    // Validate inputs
    if (!drivingLicenseNo || !profilePicture || !drivingLicenseFile) {
      alert('Please fill in all fields.')
      setLoading(false) // Ensure loading state is reset
      return
    }

    // Validate driving license number for exactly 16 digits
    const licenseNoPattern = /^\d{16}$/ // Regex for exactly 16 digits
    if (!licenseNoPattern.test(drivingLicenseNo)) {
      alert('Driving License No must be exactly 16 digits.')
      setLoading(false) // Ensure loading state is reset
      return
    }

    const formData = new FormData()
    formData.append('driving_license_no', drivingLicenseNo)
    formData.append('profile_picture', profilePicture)
    formData.append('driving_license', drivingLicenseFile)

    try {
      const response = await fetch(
        `https://www.dsctech.in/update_user/${phoneNumber}/`,
        {
          method: 'PUT',
          body: formData,
        },
      )

      if (response.ok) {
        localStorage.setItem('verify', 'true')
        alert('User details updated successfully!')
        navigate('/')
        setLoading(false)
      } else {
        const errorData = await response.json()
        alert(
          `Failed to update user details: ${
            errorData.message || 'Unknown error'
          }`,
        )
      }
    } catch (error) {
      console.error('Error updating user details:', error)
      alert('An error occurred while updating user details.')
    } finally {
      setLoading(false) // Reset loading state in all cases
    }
  }

  if (loading) {
    return <LoadingSpinner />
  }

  return (
    <div>
      {hiddenClass ? (
        <div style={{ width: '88%', marginLeft: 'auto', marginRight: 'auto' }}>
            <Heading1 style={{ marginTop: '10vh' }}>Driver Details</Heading1>
          <form onSubmit={handleSubmit}>
            <div
              style={{
                color: 'rgb(0, 0, 0, 0.6)',
                fontSize: hiddenClassmobile ? '4vw' : '3vw' ,
                marginTop:"3vh"
              }}
            >
              Profile Picture :
            </div>
            <label></label>
            <div>
              <Input
                type="file"
                style={{ marginTop: '2%', width: 'auto' }}
                accept="image/*"
                onChange={(e) => setProfilePicture(e.target.files[0])}
                required
              />
            </div>
            <div
              style={{
                color: 'rgb(0, 0, 0, 0.6)',
                fontSize: hiddenClassmobile ? '4vw' : '3vw' ,
                marginTop:"3vh"
              }}
            >
              Driving License No :
            </div>
            <div>
              <Input
                type="text"
                style={{ marginTop: '2%', width: 'auto' }}
                value={drivingLicenseNo}
                onChange={(e) => setDrivingLicenseNo(e.target.value)}
                required
                placeholder="Enter Driving License No"
              />
            </div>
            <div
              style={{
                color: 'rgb(0, 0, 0, 0.6)',
                fontSize: hiddenClassmobile ? '4vw' : '3vw' ,
                marginTop:"3vh"
              }}
            >
              Driving License File :
            </div>
            <div>
              <Input
                type="file"
                style={{ marginTop: '2%', width: 'auto' }}
                accept="*/*" // Accept any file type
                onChange={(e) => setDrivingLicenseFile(e.target.files[0])}
                required
              />
            </div>
            <Button type="submit" style={{ width: 'auto' }}>
              Update
            </Button>
          </form>
        </div>
        
      ) : (
        <CenteredContainer>
          <h1 style={{ marginTop: '6%' }}>Driver Details</h1>
          <form onSubmit={handleSubmit}>
            <div
              style={{
                color: 'rgb(0, 0, 0, 0.6)',
                fontSize: '1.2vw',
              }}
            >
              Profile Picture :
            </div>
            <div>
              <Input
                type="file"
                style={{ marginTop: '2%', width: '100%' }}
                accept="image/*"
                onChange={(e) => setProfilePicture(e.target.files[0])}
                required
              />
            </div>
            <div
              style={{
                color: 'rgb(0, 0, 0, 0.6)',
                fontSize: '1.2vw',
              }}
            >
              Driving License No :
            </div>
            <div>
              <Input
                type="text"
                style={{ marginTop: '2%', width: '100%' }}
                value={drivingLicenseNo}
                onChange={(e) => setDrivingLicenseNo(e.target.value)}
                required
                placeholder="Enter Driving License No"
              />
            </div>
            <div
              style={{
                color: 'rgb(0, 0, 0, 0.6)',
                fontSize: '1.2vw',
              }}
            >
              Driving License File :
            </div>
            <div>
              <Input
                type="file"
                style={{ marginTop: '2%', width: '100%' }}
                accept="*/*" // Accept any file type
                onChange={(e) => setDrivingLicenseFile(e.target.files[0])}
                required
              />
            </div>
            <Button type="submit" style={{ width: 'auto' }}>
              Update
            </Button>
          </form>
        </CenteredContainer>
      )}
    </div>
  )
}

export default Driverdetails
