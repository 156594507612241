// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { getDatabase } from 'firebase/database'

const firebaseConfig = {
    apiKey: "AIzaSyCTX1Su5zMo78iKwVzg98dOgDT2-vEpXDM",
    authDomain: "hubio-8872c.firebaseapp.com",
    projectId: "hubio-8872c",
    storageBucket: "hubio-8872c.firebasestorage.app",
    messagingSenderId: "1027963512053",
    appId: "1:1027963512053:web:21fba7158f38ce9557d1bc",
    measurementId: "G-9HN3DMJLHV"
  };
  

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
export const database = getDatabase(app)

export const generateToken = async () => {
    const permission = await Notification.requestPermission();
    console.log(permission);
    
    if (permission === "granted") {
        try {
            const token = await getToken(messaging, {
                vapidKey: "BG151JI6JznS6rhqbMk5DeVCo2PWQCqeaIvlSWvUKffMyS5WWtY8HA6ofPwWfmG8xgOF3OuUZgT2Ao-EXEd2YYY",
            });
            
            if (token) {
                // Save the token in localStorage
                localStorage.setItem("token", token);
                console.log("Token saved to localStorage:", token);
            } else {
                console.log("Failed to get token");
            }
        } catch (error) {
            console.error("Error fetching token:", error);
        }
    }
};