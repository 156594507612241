import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import profileaddress from '../Components/Assets/profileaddress.svg'
import profilecar from '../Components/Assets/profilecar.svg'
import reward from '../Components/Assets/reward.svg'
import profileicon from '../Components/Assets/profileicon.svg'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import arrow from '../Components/Assets/arrow.svg'
import profile from '../Components/Assets/profile.svg'
import { useDevice } from '../Components/Context/deviceContext'
import Footer from '../Components/fotter'

// Styled components
const Container = styled.div`
  display: flex;
  @media (min-width: 768px) and (max-width: 1200px) {
    min-heigt: 100vh;
    width: 100%;
  }
  @media (max-width: 767px) {
    min-heigt: 100vh;
    width: 100%;
  }
`

const Sidebar = styled.div`
  margin-top: 8vh;
  width: 20vw;
  padding-left: 5vh;
  padding-top: 5vh;
`

const Content = styled.div`
  flex: 1;
  width: 70vw;
  padding: 5vh;
`

const Button = styled.button`
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #faba49;
  background-color: ${(props) => (props.active ? '#FABA49' : '#fff')};
  color: ${(props) => (props.active ? '#000' : '#000')};
  cursor: pointer;
  border-radius: 1vw;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  text-align: center; /* Center text inside button */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */

  &:hover {
    background-color: ${(props) => (props.active ? '#FABA49' : '#FCD184')};
  }
`

const HideContainer = styled.div`
  display: flex;
  @media (max-width: 1200px) {
    display: none;
  }
`

const Input = styled.input`
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 30px;
  background-color: #ffffff;
  border: 1px solid #ff8c00;
  font-size: 16px;

  &:focus {
    background-color: #fff7d4;
    border: 1px solid #ff8c00;
    outline: none;
  }

  &:disabled {
    background-color: #fff;
    cursor: not-allowed;
  }
`

const CarGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Create two columns */
  gap: 20px; /* Space between items */
  margin-left: 3vw; /* Align with other elements */
  margin-top: 1vw;
  margin-bottom: 5vh;
  margin-right: 3vw;
`

const CarCard = styled.div`
  border: 1px solid #faba49;
  border-radius: 10px;
  padding: 15px;
  background-color: #fff;
  width: 100%; /* Make the card take the full width of its grid cell */
  box-sizing: border-box; /* Include padding and border in the element's total width */
`

const AddressGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-left: 3vw;
  margin-top: 1vw;
  margin-bottom: 5vh;
  margin-right: 3vw;
`

const AddressCard = styled.div`
  border: 1px solid #faba49;
  border-radius: 10px;
  padding: 15px;
  background-color: #fff;
`

const Container1 = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8vh;
  height: 50vh;
  width: auto;
  border: 1px solid #faba49;
  border-radius: 1vw;
  margin-right: 2%;
  padding: 20px;
`

const ProfilePicWrapper = styled.div`
  width: 17vh;
  height: 17vh;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
  background-color: #f2f2f2;
  display: flex;
  justify-content: center;
  align-items: center;
`
const ProfilePicWrapper1 = styled.div`
  width: 20vh;
  height: 20vh;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
  border: 1px solid #faba49;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ProfileImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover; // Ensures the image covers the whole area
`

const CameraIcon = styled.label`
  cursor: pointer;
  font-size: 20px; // Size of the camera icon
  color: #ff8c00; // Icon color
`

const Input1 = styled.input`
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 30px;
  background-color: #ffffff;
  border: 1px solid #ff8c00;
  font-size: 16px;

  &:focus {
    background-color: #fff7d4;
    border: 1px solid #ff8c00;
    outline: none;
  }

  @media (min-width: 768px) and (max-width: 1200px) {
    width: 88%;
  }

  @media (max-width: 767px) {
    font-size: 14px;
    width: 88%;
    padding: 15px 10px;
    margin-left: auto;
    margin-right: auto;
  }
`

const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Profile = () => {
  const { phoneNumber } = useDevice()
  const [addresses, setAddresses] = useState([])
  const [newAddress, setNewAddress] = useState({
    street: '',
    city: '',
    state: '',
    postal_code: '',
    country: '',
  })

  const [cars, setCars] = useState([])
  const [selected, setSelected] = useState('profile')
  const navigate = useNavigate()
  const handleButtonClick = (section) => {
    setSelected(section)
  }
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const openDialog = () => {
    setIsDialogOpen(true)
  }

  // Function to close the dialog
  const closeDialog = () => {
    setIsDialogOpen(false)
    // Reset new address fields if needed
    setNewAddress({
      street: '',
      city: '',
      state: '',
      postal_code: '',
      country: '',
    })
  }

  // Function to handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target
    setNewAddress((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const fetchAddresses = async () => {
    try {
      const response = await fetch(
        `https://www.dsctech.in/addresses/${phoneNumber}/`,
      )
      const data = await response.json()
      setAddresses(data.addresses)
    } catch (error) {
      console.error('Error fetching addresses:', error)
    }
  }

  // Function to submit the new address
  // Function to submit the new address
  const handleSubmitAddress = async () => {
    // Check for missing required fields
    if (!phoneNumber) {
      alert('Please enter your phone number.')
      return
    }

    if (!newAddress.street) {
      alert('Please enter your street address.')
      return
    }

    if (!newAddress.city) {
      alert('Please enter your city.')
      return
    }

    if (!newAddress.state) {
      alert('Please enter your state.')
      return
    }

    if (!newAddress.postal_code) {
      alert('Please enter your postal code.')
      return
    }

    if (!newAddress.country) {
      alert('Please enter your country.')
      return
    }

    try {
      const response = await axios.post(
        'https://www.dsctech.in/add-address/',
        {
          phone: phoneNumber,
          street: newAddress.street,
          city: newAddress.city,
          state: newAddress.state,
          postal_code: newAddress.postal_code,
          country: newAddress.country,
        },
        {
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
          },
        },
      )

      console.log('Address added:', response.data)
      // Optionally fetch addresses
      fetchAddresses()
      // Close dialog
      closeDialog()
    } catch (error) {
      console.error('Error adding address:', error)
      // Handle error state if needed
    }
  }

  const [newCar, setNewCar] = useState({
    model: '',
    color: '',
    carNumber: '',
    carName: '',
    carType: '',
    rcPhoto: null,
    insurancePhoto: null,
  })

  const [error, setError] = useState(null)

  const [isDialogOpen1, setIsDialogOpen1] = useState(false)

  // Function to open the dialog
  const openDialog1 = () => {
    setIsDialogOpen1(true)
  }

  // Function to close the dialog and reset car details form
  const closeDialog1 = () => {
    setIsDialogOpen1(false)
    setNewCar({
      model: '',
      color: '',
      carNumber: '',
      carName: '',
      carType: '',
      rcPhoto: null,
      insurancePhoto: null,
    })
  }

  // Function to handle input changes (text fields)
  const handleInputChange1 = (e) => {
    const { name, value } = e.target
    setNewCar((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  // Function to handle file uploads (for rcPhoto and insurancePhoto)
  const handleFileChange = (e) => {
    const { name, files } = e.target
    setNewCar((prev) => ({
      ...prev,
      [name]: files[0], // Store the selected file
    }))
  }

  // Function to submit the new car details
  const handleSubmitCar = async () => {
    if (!newCar.model) {
      alert('Please enter the car model.')
      return
    }

    if (!newCar.color) {
      alert('Please enter the car color.')
      return
    }

    if (!newCar.carNumber) {
      alert('Please enter the car number.')
      return
    }

    if (!newCar.carName) {
      alert('Please enter the car name.')
      return
    }

    if (!newCar.carType) {
      alert('Please enter the car type.')
      return
    }

    // Use FormData for file uploads
    const formData = new FormData()

    // Append fields to formData
    formData.append('phone', phoneNumber)
    formData.append('model', newCar.model)
    formData.append('color', newCar.color)
    formData.append('car_number', newCar.carNumber)
    formData.append('car_name', newCar.carName)
    formData.append('car_type', newCar.carType)
    if (newCar.rcPhoto) formData.append('rcPhoto', newCar.rcPhoto)
    if (newCar.insurancePhoto)
      formData.append('insurancePhoto', newCar.insurancePhoto)

    try {
      const response = await axios.post(
        'https://www.dsctech.in/api/add_car_details/',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data', // Set the proper headers for file upload
          },
        },
      )

      console.log('Car details added:', response.data)
      fetchCars() // Ensure this function is defined
      closeDialog1() // Ensure this function is defined
    } catch (error) {
      setError(
        `Error adding car details: ${
          error.response ? error.response.data : error.message
        }`,
      )
      console.error('Error:', error)
    }
  }

  // Effect to fetch addresses whenever phoneNumber changes
  useEffect(() => {
    if (phoneNumber) {
      fetchAddresses()
    }
  }, [phoneNumber])

  const handleClick = () => {
    navigate(-1)
  }

  const handleorder = () => {
    navigate('/orders')
  }
  const handleorder1 = () => {
    navigate('/savedaddress')
  }

  const handleorder2 = () => {
    navigate('/savedcar')
  }

  const handleAddressChange = (e) => {
    const { name, value } = e.target
    setNewAddress({ ...newAddress, [name]: value })
  }

  const [image, setImage] = useState(null)

  const handleImageChange = (e) => {
    // Check if files are available before accessing them
    const file = e.target?.files?.[0]
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setImage(reader.result) // Set the image state to the base64 string
      }
      reader.readAsDataURL(file)
    }
  }

  const [hiddenClass, setHiddenClass] = useState('')
  const [hiddenClassmobile, setHiddenClassmobile] = useState('')

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1200) {
        setHiddenClass('hidden')
      } else {
        setHiddenClass('')
      }
    }

    window.addEventListener('resize', handleResize)
    handleResize() // Initial check

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    const handleResizemobile = () => {
      if (window.innerWidth < 500) {
        setHiddenClassmobile('hidden')
      } else {
        setHiddenClassmobile('')
      }
    }

    window.addEventListener('resize', handleResizemobile)
    handleResizemobile() // Initial check

    return () => {
      window.removeEventListener('resize', handleResizemobile)
    }
  }, [])

  const [name, setName] = useState('')

  useEffect(() => {
    // Retrieve the name from localStorage
    const storedName = localStorage.getItem('name')
    if (storedName) {
      setName(storedName)
    }
  }, [])

  const fetchCars = async () => {
    try {
      const response = await fetch(
        `https://www.dsctech.in/api/car/${phoneNumber}/`,
      )
      const data = await response.json()
      setCars(data.cars)
    } catch (error) {
      console.error('Error fetching cars:', error)
    }
  }

  useEffect(() => {
    if (phoneNumber) {
      fetchCars()
    }
  }, [phoneNumber])

  const renderContent = () => {
    switch (selected) {
      case 'profile':
        return (
          <div style={{ minHeight: '100vh' }}>
            <div
              style={{
                marginTop: '8vh',
                height: 'auto',
                width: 'auto',
                border: '1px solid #FABA49',
                borderRadius: '1vw',
                marginRight: '2%',
              }}
            >
              <div
                style={{
                  marginLeft: '3vw',
                  fontSize: '2vw',
                  fontWeight: '500',
                  marginTop: '2vw',
                }}
              >
                Profile
              </div>
              <Container
                style={{
                  marginLeft: '3vw',
                  marginTop: '2vw',
                  marginBottom: '6vh',
                }}
              >
                <ProfilePicWrapper1>
                  <ProfilePicWrapper
                    style={{
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      cursor: 'pointer',
                    }}
                    onClick={() =>
                      document.getElementById('profilePic').click()
                    } // Trigger file input on click
                  >
                    {image ? (
                      <ProfileImage src={image} alt="Profile" />
                    ) : (
                      <div></div>
                    )}
                    <input
                      type="file"
                      id="profilePic"
                      onChange={handleImageChange}
                      style={{ display: 'none' }}
                    />
                    {image ? (
                      <div>
                        {/* Optionally add a "Change Image" button or text */}
                        {/* <span>Change Image</span> */}
                      </div>
                    ) : (
                      <CameraIcon htmlFor="profilePic">📷</CameraIcon>
                    )}
                  </ProfilePicWrapper>
                </ProfilePicWrapper1>
                <NameWrapper>
                  <Input1
                    type="text"
                    placeholder="Enter your name"
                    defaultValue={localStorage.getItem('name') || ''}
                  />
                  <Input1 type="text" value={phoneNumber} readOnly />
                </NameWrapper>
              </Container>
            </div>
          </div>
        )
      case 'address':
        return (
          <div style={{ minHeight: '100vh' }}>
            <div
              style={{
                marginTop: '8vh',
                height: 'auto',
                width: 'auto',
                border: '1px solid #FABA49',
                borderRadius: '1vw',
                marginRight: '2%',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    marginLeft: '3vw',
                    fontSize: '2vw',
                    fontWeight: '500',
                    marginTop: '2vw',
                  }}
                >
                  Addresses
                </div>
                <Button
                  onClick={openDialog}
                  style={{
                    height: '5vh',
                    width: '25vh',
                    marginTop: '2vw',
                    marginRight: '3vw',
                    padding: '5px',
                    borderRadius: '20px',
                    backgroundColor: '#FADAA1',
                    color: 'black',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '14px',
                  }}
                >
                  Add Address
                </Button>
              </div>
              <AddressGrid>
                {addresses.length > 0 ? (
                  addresses
                    .slice()
                    .reverse()
                    .map((
                      address, // Use slice() to avoid mutating the original array
                    ) => (
                      <AddressCard key={address.id}>
                        <p>
                          <strong>Street:</strong> {address.street}
                        </p>
                        <p>
                          <strong>City:</strong> {address.city}
                        </p>
                        <p>
                          <strong>State:</strong> {address.state}
                        </p>
                        <p>
                          <strong>Postal Code:</strong> {address.postal_code}
                        </p>
                        <p>
                          <strong>Country:</strong> {address.country}
                        </p>
                      </AddressCard>
                    ))
                ) : (
                  <p>No addresses found for this phone number.</p>
                )}
              </AddressGrid>

              {isDialogOpen && (
                <>
                  {/* Overlay with blur effect */}
                  <div
                    style={{
                      position: 'fixed',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
                      backdropFilter: 'blur(5px)', // Adjust the blur intensity
                      zIndex: 1, // Ensure overlay is above other content
                    }}
                  />

                  {/* Dialog Container */}
                  <div
                    style={{
                      position: 'fixed',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      backgroundColor: '#ffffff',
                      padding: '20px',
                      borderRadius: '10px',
                      boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                      zIndex: 2, // Ensure dialog is above the overlay
                      width: '500px', // Set a width for the dialog
                    }}
                  >
                    <h4>Add New Address</h4>

                    {/* Container for Input Fields */}
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'start',
                        width: '100%',
                        gap: '5%',
                      }}
                    >
                      <Input
                        type="text"
                        placeholder="Street"
                        name="street"
                        value={newAddress.street}
                        onChange={handleInputChange}
                      />
                      <Input
                        type="text"
                        placeholder="City"
                        name="city"
                        value={newAddress.city}
                        onChange={handleInputChange}
                      />
                      <Input
                        type="text"
                        placeholder="State"
                        name="state"
                        value={newAddress.state}
                        onChange={handleInputChange}
                      />
                      <Input
                        type="text"
                        placeholder="Postal Code"
                        name="postal_code"
                        value={newAddress.postal_code}
                        onChange={handleInputChange}
                      />
                      <Input
                        type="text"
                        placeholder="Country"
                        name="country"
                        value={newAddress.country}
                        onChange={handleInputChange}
                      />
                    </div>

                    {/* Submit and Cancel Buttons */}
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <Button
                        onClick={handleSubmitAddress}
                        style={{
                          marginTop: '10px',
                          padding: '10px',
                          borderRadius: '20px',
                          backgroundColor: '#FADAA1',
                          color: 'black',
                          cursor: 'pointer',
                          marginRight: '10px', // Space between buttons
                        }}
                      >
                        Submit
                      </Button>
                      <Button
                        onClick={closeDialog}
                        style={{
                          marginTop: '10px',
                          padding: '10px',
                          borderRadius: '20px',
                          backgroundColor: '#ffffff',
                          color: 'black',
                          cursor: 'pointer',
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        )
      case 'car':
        return (
          <div style={{ minHeight: '100vh' }}>
            <div
              style={{
                marginTop: '8vh',
                width: 'auto',
                border: '1px solid #FABA49',
                borderRadius: '1vw',
                marginRight: '2%',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    marginLeft: '3vw',
                    fontSize: '2vw',
                    fontWeight: '500',
                    marginTop: '2vw',
                  }}
                >
                  Addresses
                </div>
                <Button
                  onClick={openDialog1}
                  style={{
                    height: '5vh',
                    width: '25vh',
                    marginTop: '2vw',
                    marginRight: '3vw',
                    padding: '5px',
                    borderRadius: '20px',
                    backgroundColor: '#FADAA1',
                    color: 'black',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '14px',
                  }}
                >
                  Add Car
                </Button>
              </div>
              <CarGrid>
                {cars.length > 0 ? (
                  cars.map((car) => (
                    <CarCard key={car.id}>
                      <p>
                        <strong>Car Name:</strong> {car.car_name}
                      </p>
                      <p>
                        <strong>Model:</strong> {car.model}
                      </p>
                      <p>
                        <strong>Color:</strong> {car.color}
                      </p>
                      <p>
                        <strong>Car Number:</strong> {car.car_number}
                      </p>
                      <p>
                        <strong>Car Type:</strong> {car.car_type}
                      </p>
                    </CarCard>
                  ))
                ) : (
                  <p>No car details found for this phone number.</p>
                )}
              </CarGrid>
              {isDialogOpen1 && (
                <>
                  {/* Overlay with blur effect */}
                  <div
                    style={{
                      position: 'fixed',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      backgroundColor: 'rgba(0, 0, 0, 0.5)',
                      backdropFilter: 'blur(5px)',
                      zIndex: 1,
                    }}
                  />

                  {/* Dialog Container */}
                  <div
                    style={{
                      position: 'fixed',
                      top: '55%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      backgroundColor: '#ffffff',
                      padding: '20px',
                      borderRadius: '10px',
                      boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                      zIndex: 2,
                      width: '500px',
                    }}
                  >
                    <h4>Add New Car Details</h4>

                    {/* Error Message */}
                    {error && <p style={{ color: 'red' }}>{error}</p>}

                    {/* Container for Input Fields */}
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'start',
                        width: '100%',
                        gap: '5%',
                      }}
                    >
                      <Input
                        type="text"
                        placeholder="Car Model"
                        name="model"
                        value={newCar.model}
                        onChange={handleInputChange1}
                      />
                      <Input
                        type="text"
                        placeholder="Car Color"
                        name="color"
                        value={newCar.color}
                        onChange={handleInputChange1}
                      />
                      <Input
                        type="text"
                        placeholder="Car Number"
                        name="carNumber"
                        value={newCar.carNumber}
                        onChange={handleInputChange1}
                      />
                      <Input
                        type="text"
                        placeholder="Car Name"
                        name="carName"
                        value={newCar.carName}
                        onChange={handleInputChange1}
                      />
                      <Input
                        type="text"
                        placeholder="Car Type"
                        name="carType"
                        value={newCar.carType}
                        onChange={handleInputChange1}
                      />
                      <Input
                        type="file"
                        name="rcPhoto"
                        onChange={handleFileChange}
                      />
                      <Input
                        type="file"
                        name="insurancePhoto"
                        onChange={handleFileChange}
                      />
                    </div>

                    {/* Submit and Cancel Buttons */}
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <Button
                        onClick={handleSubmitCar}
                        style={{
                          marginTop: '10px',
                          padding: '10px',
                          borderRadius: '20px',
                          backgroundColor: '#FADAA1',
                          color: 'black',
                          cursor: 'pointer',
                          marginRight: '10px',
                        }}
                      >
                        Submit
                      </Button>
                      <Button
                        onClick={closeDialog1}
                        style={{
                          marginTop: '10px',
                          padding: '10px',
                          borderRadius: '20px',
                          backgroundColor: '#ffffff',
                          color: 'black',
                          cursor: 'pointer',
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        )

      case 'rewards':
        return (
          <div style={{ minHeight: '100vh' }}>
            <div
              style={{
                marginTop: '8vh',
                height: '50vh',
                width: 'auto',
                border: '1px solid #FABA49',
                borderRadius: '1vw',
                marginRight: '2%',
              }}
            >
              <div
                style={{
                  marginLeft: '3vw',
                  fontSize: '2vw',
                  fontWeight: '500',
                  marginTop: '2vw',
                }}
              >
                Reward Points
              </div>
              <div style={{ marginLeft: '3vw', marginTop: '1vw' }}>
                Reward points details go here.
              </div>
            </div>
          </div>
        )
      default:
        return null
    }
  }

  return (
    <div>
      <Container>
        {hiddenClass ? (
          <div
            style={{
              width: '88%',
              marginLeft: 'auto',
              marginRight: 'auto',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                width: '100%',
                marginLeft: 'auto',
                marginRight: 'auto',
                height: hiddenClassmobile ? '5vh' : '7vh',
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                marginTop: '6vh',
                alignItems: 'center',
              }}
            >
              <div
                onClick={handleClick}
                style={{
                  height: hiddenClassmobile ? '5vh' : '7vh',
                  width: hiddenClassmobile ? '5vh' : '7vh',
                  borderRadius: '50%',
                  backgroundColor: '#FABA49',
                  alignItems: 'center',
                  justifyContent: 'center',
                  display: 'flex',
                  position: 'absolute',
                  left: '0',
                }}
              >
                <img
                  src={arrow}
                  alt="arrow"
                  height="50%"
                  style={{
                    transform: 'rotate(-180deg)',
                  }}
                />
              </div>
              <div style={{ fontSize: '6vw', letterSpacing: '0.1vw' }}>
                Profile
              </div>
            </div>
            <div
              style={{
                height: '10vh',
                width: '10vh',
                border: '2px solid #FABA49',
                marginLeft: 'auto',
                marginRight: 'auto',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                marginTop: '5vh',
              }}
            >
              <img src={profile} height="70%" />
            </div>
            <div
              style={{
                fontSize: hiddenClassmobile ? '4.5vw' : '2.6vw',
                letterSpacing: '0.5vw',
                marginTop: '2vh',
                color: 'rgba(0, 0, 0,1)',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            >
              {name}
            </div>
            <div style={{ fontSize: '4.5vw', marginTop: '3vh' }}>
              Phone number
            </div>
            <Input
              style={{ marginTop: '1vh' }}
              type="tel"
              value={phoneNumber}
              disabled="true" // Control the disabled state here
              placeholder="Enter phone number"
            />
            <div
              style={{
                marginTop: '1vh',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div
                style={{
                  height: '15vh',
                  width: '46.6%',
                  backgroundColor: '#FFE9C2',
                  borderRadius: '5vh',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                }}
                onClick={handleorder}
              >
                <div
                  style={{
                    width: '6vh',
                    height: '6vh',
                    borderRadius: '1.5vh',
                    backgroundColor: '#fff',
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                  }}
                >
                  <img src={profileicon} height="40%" width="auto" />
                </div>
                <div style={{ marginTop: '0.5vh' }}>Previous Orders</div>
              </div>
              <div
                style={{
                  height: '15vh',
                  width: '46.6%',
                  backgroundColor: '#FFE9C2',
                  borderRadius: '5vh',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                onClick={handleorder1}
              >
                <div
                  style={{
                    width: '6vh',
                    height: '6vh',
                    borderRadius: '1.5vh',
                    backgroundColor: '#fff',
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                  }}
                >
                  <img src={profileaddress} height="40%" width="auto" />
                </div>
                <div style={{ marginTop: '0.5vh' }}>Saved Address</div>
              </div>
            </div>
            <div
              style={{
                marginTop: '3vh',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div
                style={{
                  height: '15vh',
                  width: '46.6%',
                  backgroundColor: '#FFE9C2',
                  borderRadius: '5vh',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                onClick={handleorder2}
              >
                <div
                  style={{
                    width: '6vh',
                    height: '6vh',
                    borderRadius: '1.5vh',
                    backgroundColor: '#fff',
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                  }}
                >
                  <img src={profilecar} height="40%" width="auto" />
                </div>
                <div style={{ marginTop: '0.5vh' }}>Car Details</div>
              </div>
              <div
                style={{
                  height: '15vh',
                  width: '46.6%',
                  backgroundColor: '#FFE9C2',
                  borderRadius: '5vh',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <div
                  style={{
                    width: '6vh',
                    height: '6vh',
                    borderRadius: '1.5vh',
                    backgroundColor: '#fff',
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                  }}
                >
                  <img src={reward} height="40%" width="auto" />
                </div>
                <div style={{ marginTop: '0.5vh' }}>Reward Points</div>
              </div>
            </div>
          </div>
        ) : (
          <HideContainer>
            <Sidebar>
              <Button
                active={selected === 'profile'}
                onClick={() => handleButtonClick('profile')}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                >
                  <img
                    src={profileicon}
                    height="16px"
                    style={{ marginRight: '0.3vw' }}
                    alt="Profile Address"
                  />
                  <div style={{ letterSpacing: '0.1vw' }}>Profile</div>
                </div>
              </Button>
              <Button
                active={selected === 'address'}
                onClick={() => handleButtonClick('address')}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                >
                  <img
                    src={profileaddress}
                    height="16px"
                    style={{ marginRight: '0.3vw' }}
                    alt="Profile Address"
                  />
                  <div style={{ letterSpacing: '0.1vw' }}>Address</div>
                </div>
              </Button>

              <Button
                active={selected === 'car'}
                onClick={() => handleButtonClick('car')}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                >
                  <img
                    src={profilecar}
                    height="14px"
                    style={{ marginRight: '0.3vw' }}
                    alt="Profile Address"
                  />
                  <div style={{ letterSpacing: '0.1vw' }}>Car Details</div>
                </div>
              </Button>
              <Button
                active={selected === 'rewards'}
                onClick={() => handleButtonClick('rewards')}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                >
                  <img
                    src={reward}
                    height="14px"
                    style={{ marginRight: '0.3vw' }}
                    alt="Profile Address"
                  />
                  <div style={{ letterSpacing: '0.1vw' }}>Reward Points</div>
                </div>
              </Button>
            </Sidebar>
            <Content>{renderContent()}</Content>
          </HideContainer>
        )}
      </Container>
    </div>
  )
}

export default Profile
