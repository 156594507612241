
export const getDeviceType = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.includes('mobile')) {
      return 'Mobile';
    } else if (userAgent.includes('tablet')) {
      return 'Tablet';
    } else {
      return 'Desktop';
    }
  };
  