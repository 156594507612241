import React, { useEffect, useState } from 'react';

const LoadingSpinner = () => {
    const [hiddenClass, setHiddenClass] = useState('');

    useEffect(() => {
        const handleResize = () => {
          if (window.innerWidth < 1200) {
            setHiddenClass('hidden');
          } else {
            setHiddenClass('');
          }
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Initial check

        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div
            className={`spinner-container ${hiddenClass}`}
            style={{
                textAlign: 'center',
                marginTop: '20px',
                width: '100vw',
                height: hiddenClass ? '100vh': '80vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <div className="spinner" />
            <style>
                {`
                .spinner {
                    border: 16px solid #FFE9C2;
                    border-radius: 50%;
                    border-top: 16px solid #FABA49;
                    width: 5vw;
                    height: 5vw;
                    -webkit-animation: spin 2s linear infinite;
                    animation: spin 2s linear infinite;
                }

                @-webkit-keyframes spin {
                    0% { -webkit-transform: rotate(0deg); }
                    100% { -webkit-transform: rotate(360deg); }
                }

                @keyframes spin {
                    0% { transform: rotate(0deg); }
                    100% { transform: rotate(360deg); }
                }

                .hidden {
                    display: none;
                }
                `}
            </style>
        </div>
    );
};

export { LoadingSpinner };
