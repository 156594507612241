// src/components/PrivacyPolicy.js
import React, { useEffect } from 'react';

const PrivacyPolicy = () => {
  // Ensure the page scrolls to the top on mount
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      style={{
        padding: '20px',
        maxWidth: '800px',
        margin: 'auto',
        textAlign: 'left',
        lineHeight: '1.6',
      }}
    >
      <h1>Privacy Policy</h1>
      <p>
        This Privacy Policy outlines how Hubio collects, uses, and protects your
        personal information when you use our website and services.
      </p>

      <h2>Information We Collect</h2>
      <ul>
        <li>Contact information: Name, email address, phone number</li>
        <li>Location information: Your location to find nearby car washes</li>
        <li>Payment information: Credit or debit card details (if paying online)</li>
        <li>Booking information: Details about your car wash appointments</li>
        <li>Feedback and reviews: Information provided in surveys or reviews</li>
      </ul>

      <h2>How We Use Your Information</h2>
      <p>
        We use your information to provide services, process bookings, improve the
        website, and personalize your experience.
      </p>

      <h2>Sharing Your Information</h2>
      <p>
        Your information may be shared with car wash providers, payment processors,
        and third-party service providers as needed.
      </p>

      <h2>Your Rights</h2>
      <ul>
        <li>Access and correct your personal information</li>
        <li>Request the deletion of your personal information</li>
        <li>Restrict or object to the processing of your personal information</li>
        <li>Withdraw consent to the processing of your personal information</li>
      </ul>

      <h2>Security</h2>
      <p>
        We use appropriate measures to protect your information, but no transmission
        method is entirely secure.
      </p>

      <h2>Cookies and Tracking</h2>
      <p>
        We use cookies to collect information about your usage. You can manage cookies
        through browser settings.
      </p>

      <h2>Changes to This Privacy Policy</h2>
      <p>
        We may update this policy. Significant changes will be notified on our website
        or by direct contact.
      </p>

      <h2>Contact Us</h2>
      <p>If you have questions about this Privacy Policy, please contact us at:</p>
      <address>
        Hubio, 14, Seethammapeta Main Rd,<br />
        Dwaraka Nagar, Visakhapatnam, Andhra Pradesh 530016
      </address>
      <p>Last Updated: 15 October 2024</p>
    </div>
  );
};

export default PrivacyPolicy;
