import { useState, useEffect } from "react";
import { ref, set, remove } from "firebase/database";
import { database } from "../notifications/firebase";

// Function to update the driver's location and status in Firebase Realtime Database
const updateLocation = (userId, latitude, longitude, status) => {
  set(ref(database, `drivers/${userId}`), {
    latitude,
    longitude,
    timestamp: Date.now(),
    status, // Add status to the location data
  });
};

// Function to remove the driver's location from Firebase
const removeLocation = (userId) => {
  remove(ref(database, `drivers/${userId}`));
};

// Custom hook to track and update driver's live location
export const useLiveLocationTracking = (userId, status) => {
  useEffect(() => {

    // Start tracking the location if userId is valid
    updateLocation(userId, 0, 0, "started"); // Set initial status to "started" when tracking starts
    const watchId = navigator.geolocation.watchPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        updateLocation(userId, latitude, longitude, status); // Update with live location
      },
      (error) => console.error("Error tracking location:", error),
      { enableHighAccuracy: true }
    );

    // Cleanup the watchPosition on component unmount or when userId changes
    return () => {
      navigator.geolocation.clearWatch(watchId);
      // Update location to "ended" when tracking stops
      updateLocation(userId, 0, 0, "ended"); 
      removeLocation(userId); // Remove location from Firebase
    };
  }, [userId, status]);
};
