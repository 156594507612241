'use client'
import React, { useEffect, useState } from 'react'
import "./Navbar.css";
import logo from '../Assets/Logo.png';
import notification from '../Assets/notification.svg';
import profile from '../Assets/profile.svg';
import heart from '../Assets/Heart.svg';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';

const StyledButton = styled.button`
  cursor: pointer;
  padding: 0rem 1.2vw;
  height:6vh;
  font-family: inherit;
  font-size: inherit;
  position: relative;
  letter-spacing: 0.05rem;
  font-weight: 400;
  font-size: 1.1vw;
  border-radius: 400px;
  border: 1px solid #FABA49;
  overflow: hidden;
  background: #FABA49;
  color: black;
  transition: color 0.4s;
  position: relative;

  &::before,
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    content: '';
  }

  &::before {
    background: #FFE9C2;
    
    width: 120%;
    left: -10%;
    transform: skew(30deg);
    transition: transform 0.4s cubic-bezier(0.3, 1, 0.8, 1);
  }

  &:hover::before {
    transform: translate3d(100%, 0, 0);
  }

  &:hover span {
    color: black;
  }

  span {
    position: relative;
    z-index: 10;
    transition: color 0.4s;
  }
     @media (max-width: 1200px) {
       display: none;
  }
`;

const StyledButton1 = styled.button`
  cursor: pointer;
  padding: 0rem 1.2rem;
  height:32px;
  font-family: inherit;
  font-size: inherit;
  position: relative;
  letter-spacing: 0.05rem;
  font-weight: 400;
  font-size: 2vw;
  border-radius: 400px;
  border: 2px solid #FABA49;
  overflow: hidden;
  background: #FABA49;
  color: black;
  transition: color 0.4s;
  position: relative;

  &::before,
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    content: '';
  }

  &::before {
    background: #FADAA1;
    
    width: 120%;
    left: -10%;
    transform: skew(30deg);
    transition: transform 0.4s cubic-bezier(0.3, 1, 0.8, 1);
  }

  &:hover::before {
    transform: translate3d(100%, 0, 0);
  }

  &:hover span {
    color: black;
  }

  span {
    position: relative;
    z-index: 10;
    transition: color 0.4s;
  }
`;

const NotificationContainer = styled.div`
  width: 2.5vh;
  height: 2.5vh;
  border-radius: 50%;
  background-color: #FABA49;
  font-size: 10px;
  color: #000; 
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -0.6vh;
  top: -0.6vh;
`;

const StyledDiv = styled.div`
  margin-left: 1.5%;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: #FABA49;
  }
  @media (max-width: 1200px) {
       display: none;
  }

`;

const StyledDiv1 = styled.div`
  margin-left: auto;
  position: relative;
   @media (max-width: 1200px) {
       display: none;
  }
`;


const StyledDiv4 = styled.div`
  margin-left: 5%;
  margin-top: 1.5%;
  font-size: 2vw;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: #FABA49;
  }
`;

// Define HoverMenu first
const HoverMenu = styled.div`
  display: none; 
  position: absolute;
  top: 110%;
  left: -7.5vw ;
  background-color: white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  width: 10vw; /* Adjust width as needed */
  z-index: 10;
  padding: 0; /* Optional: adjust padding if needed */

  &::before {
    content: '';
    position: absolute;
    top: -8px;
    left: 8.2vw; /* Adjust the position of the small triangle */
    border-width: 0 8px 8px 8px;
    border-style: solid;
    border-color: transparent transparent #f2f2f2 transparent;
  }
`;

const MenuItem = styled.div`
  padding: 0.6vw;
  font-size: 1.1vw;
  cursor: pointer;
background-color: #f2f2f2;
  &:hover {
    background-color: #FFE9C2;
  }
`;

// Define CircleContainer next
const CircleContainer = styled.div`
  width: 5vh;
  height: 5vh;
  border: 1px solid black;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease; 

  &:hover {
    background-color: #FADAA1; 
  }
`;

// Define StyledDiv2 last, using HoverMenu
const StyledDiv2 = styled.div`
  margin-left: 1%;
  position: relative;
  display: inline-block; /* Ensure it fits around the CircleContainer */
  
  @media (max-width: 1200px) {
    margin-left: auto;
    margin-right: 3%;
  }

  &:hover ${HoverMenu} {
    display: block;
  }
`;

// Define StyledDiv2 last, using HoverMenu
const StyledDiv3 = styled.div`
  margin-left: 1%;
  position: relative;
  display: inline-block; /* Ensure it fits around the CircleContainer */
  
  @media (max-width: 1200px) {
    display: none;
  }

  &:hover ${HoverMenu} {
    display: block;
  }
`;

const Container1 = styled.div`
  margin-left: 1.5%;
  display: flex;
  width: auto;
  height: 5vh;
   @media (max-width: 1200px) {
   display: none;
  }
`;

const LogoImage = styled.img`
  height: 100%;
  width: auto;
`;


const HoverMenu1 = styled.div`
    position: absolute;
     top: 110%;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-height: 300px;
    overflow-y: auto;
    width: 30vw;
`;
const Navbar = () => {
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false);
  const [navbar, setNavbar] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [isMenuVisible, setIsMenuVisible] = useState(false);


  useEffect(() => {
    if (isActive) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto'; // Clean up when component unmounts
    };
  }, [isActive]);

  const changeboxshadow = () => {
    if (window.scrollY >= 30) {
      setNavbar(true)
    }
    else {
      setNavbar(false)
    }
  };

  window.addEventListener('scroll', changeboxshadow);

  const navLinks = [
    { name: 'Home', path: '/' },
    { name: 'About Us', path: '/aboutus' },
    { name: 'My Bookings', path: '/orders' },
  ];

  const handleNavigation = (path) => {
    navigate(path);
  };

  const handleLoginClick = () => {
    navigate('/login');
  };

  const handleprofileClick = () => {
    navigate('/profile');
  };

  const encryptedPhoneNumber = localStorage.getItem('phoneNumber');
  const decryptedPhoneNumber = encryptedPhoneNumber
    ? CryptoJS.AES.decrypt(encryptedPhoneNumber, 'your-secret-key').toString(CryptoJS.enc.Utf8)
    : '';


  const fetchNotifications = async () => {
    const phoneNumber = decryptedPhoneNumber; // Get the phone number after decryption
    if (phoneNumber) {
      try {
        const response = await fetch(`https://www.dsctech.in/api/get_notifications/${phoneNumber}`);
        const data = await response.json();
        setNotifications(data.notifications); // Save notifications in state
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    }
  };



  const handleLogout = () => {
    // Remove phone number from localStorage
    localStorage.removeItem('phoneNumber');
    localStorage.removeItem('role')
    navigate('/login');
    window.location.reload();
  };

  return (
    <div className={navbar ? 'navbar scrolled' : 'navbar'} >
      <Container1>
        <LogoImage src={logo} alt="Premium Car Wash at Your Doorstep" />
      </Container1>
      {navLinks.map((link, index) => (
        <StyledDiv
          key={index}
          onClick={() => handleNavigation(link.path)}
        >
          <span style={{ fontSize: '1.2vw' }}>{link.name}</span>
        </StyledDiv>
      ))}
      <StyledDiv1
        onMouseEnter={() => {
          setIsMenuVisible(true);
          fetchNotifications(); // Fetch notifications when hovering
        }}
        onMouseLeave={() => setIsMenuVisible(false)}
      >
        <CircleContainer>
          <img src={notification} height="55%" width="auto" />
        </CircleContainer>
        <NotificationContainer>{notifications.length}</NotificationContainer>

        {/* HoverMenu to display notifications */}
        {isMenuVisible && (
          <HoverMenu1>
            {decryptedPhoneNumber ? (
              notifications.length > 0 ? (
                notifications.map((notification) => (
                  <div
                    key={notification.id}
                    style={{
                      border: '1px solid #FABA49',
                      padding: '2vh',
                      borderRadius: '1.1vw',
                      marginBottom: '2vh',
                    }}
                  >
                    <div style={{ fontSize: '1.2vw' }}>{notification.message}</div>
                    <span style={{ color: 'gray' }}>
                      {new Date(notification.created_at).toLocaleDateString('en-GB')}
                    </span>
                  </div>
                ))
              ) : (
                <p style={{ fontSize: '1.2vw' }}>No notifications</p>
              )
            ) : (
              <p style={{ fontSize: '1.2vw' }}>Please log in</p>
            )}
          </HoverMenu1>
        )}


      </StyledDiv1>

      {!decryptedPhoneNumber ? (
        <StyledButton style={{ marginRight: '1.5%', marginLeft: '1%' }} onClick={handleLoginClick}>
          <span>Login</span>
        </StyledButton>
      ) : (
        <StyledDiv3 style={{ marginLeft: '1%', marginRight: '1%' }}>
          <CircleContainer>
            <img src={profile} alt="Profile" height="55%" width="auto" />
          </CircleContainer>
          <HoverMenu>
            <MenuItem onClick={handleprofileClick}>Profile</MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </HoverMenu>
        </StyledDiv3>
      )}
      <div onClick={() => setIsActive(!isActive)} className="button" style={{ marginLeft: 'auto' }}>
        <div className={`burger ${isActive ? 'burgerActive' : ''}`}></div>
      </div>
      <div className={`dialog ${isActive ? 'dialogActive' : ''}`}>
        <div style={{ marginTop: '30%' }}>
          {navLinks.map((link, index) => (
            <StyledDiv4
              key={index}
              onClick={() => handleNavigation(link.path)}
            >
              {link.name}
            </StyledDiv4>
          ))}
        </div>
        <StyledButton1 style={{ marginRight: "5%", width: '90%', height: '6%', marginLeft: '5%', marginTop: '4%' }} >
          <span>Login</span>
        </StyledButton1>
      </div>



    </div>
  )
}

export default Navbar
