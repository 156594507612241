/* global google */
import React, { useEffect, useRef, useState } from 'react'
import houseIcon from '../Components/Assets/locationmap.svg'
import carWashIcon from '../Components/Assets/carshop.svg'
import carWashIcon1 from '../Components/Assets/cardriver.svg'
import { LoadingSpinner } from '../Components/LoadingSpinner '
import { useNavigate, useParams } from 'react-router-dom'
import arrow from '../Components/Assets/arrow.svg'
import { useDriverLocation } from '../Components/useDriverLocation'



const Mock = () => {
  const navigate = useNavigate()
  const mapRef = useRef(null)
  const driverMarkerRef = useRef(null);
  const mapInstance = useRef(null);
  const { id } = useParams()
  const [bookingData, setBookingData] = useState(null)
  const [hiddenClass, setHiddenClass] = useState('')
  const [hiddenClassmobile, setHiddenClassmobile] = useState('')
  const location = useDriverLocation(id);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1200) {
        setHiddenClass('hidden')
      } else {
        setHiddenClass('')
      }
    }

    window.addEventListener('resize', handleResize)
    handleResize() // Initial check

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    const handleResizemobile = () => {
      if (window.innerWidth < 500) {
        setHiddenClassmobile('hidden')
      } else {
        setHiddenClassmobile('')
      }
    }

    window.addEventListener('resize', handleResizemobile)
    handleResizemobile() // Initial check

    return () => {
      window.removeEventListener('resize', handleResizemobile)
    }
  }, [])

  const fetchBookingDetails = async () => {
    try {
      const response = await fetch(`https://www.dsctech.in/api/bookings/${id}/`)
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`)
      }
      const data = await response.json()
      setBookingData(data.booking_details)
    } catch (error) {
      console.error('Failed to fetch booking details:', error)
    }
  }

  useEffect(() => {
    if (id) {
      fetchBookingDetails()
    }
  }, [id])

  useEffect(() => {
    if (!bookingData) return;

    const loadGoogleMapsScript = () => {
      if (window.google) {
        initializeMap();
        return;
      }

      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDdi8tXldf0RfR_AqgKlikI9yPkJXpjZJk&libraries=places`;
      script.async = true;
      script.defer = true;
      script.onload = initializeMap;
      script.onerror = () => console.error('Google Maps script failed to load.');
      document.body.appendChild(script);
    };

    const initializeMap = () => {
      if (!mapRef.current) return;

      mapInstance.current = new window.google.maps.Map(mapRef.current, {
        center: {
          lat: parseFloat(bookingData.customer.latitude),
          lng: parseFloat(bookingData.customer.longitude),
        },
        zoom: 14,
        styles: [
          {
            featureType: 'all',
            elementType: 'labels',
          },
          {
            featureType: 'poi',
            elementType: 'all',
            stylers: [{ visibility: 'off' }],
          },
          {
            featureType: 'road',
            elementType: 'labels.icon',
            stylers: [{ visibility: 'off' }],
          },
        ],
      });

      // Add static markers
      addMarker(
        {
          lat: parseFloat(bookingData.customer.latitude),
          lng: parseFloat(bookingData.customer.longitude),
        },
        mapInstance.current,
        houseIcon,
        'User Location',
      );

      addMarker(
        {
          lat: parseFloat(bookingData.shop.latitude),
          lng: parseFloat(bookingData.shop.longitude),
        },
        mapInstance.current,
        carWashIcon,
        'Car Wash',
      );

      // Create an initial driver marker if location data is available
      if (location && location.latitude && location.longitude) {
        driverMarkerRef.current = new window.google.maps.Marker({
          position: {
            lat: parseFloat(location.latitude),
            lng: parseFloat(location.longitude),
          },
          map: mapInstance.current,
          icon: {
            url: carWashIcon1,
            scaledSize: new window.google.maps.Size(30, 30),
          },
          title: 'Driver',
        });
      }

      drawRoute(mapInstance.current);
    };

    const addMarker = (position, map, icon, title) => {
      const marker = new window.google.maps.Marker({
        position,
        map,
        icon,
        title,
      });

      const infoWindow = new window.google.maps.InfoWindow({
        content: `<div style="text-align: center;"><strong>${title}</strong></div>`,
      });

      marker.addListener('click', () => {
        infoWindow.close();
        infoWindow.open(map, marker);
      });
    };

    const drawRoute = (map) => {
      const directionsService = new window.google.maps.DirectionsService();
      const directionsRenderer = new window.google.maps.DirectionsRenderer({
        suppressMarkers: true,
        polylineOptions: { strokeColor: '#FAC68B', strokeWeight: 5 },
      });

      directionsRenderer.setMap(map);

      directionsService.route(
        {
          origin: {
            lat: parseFloat(bookingData.customer.latitude),
            lng: parseFloat(bookingData.customer.longitude),
          },
          destination: {
            lat: parseFloat(bookingData.shop.latitude),
            lng: parseFloat(bookingData.shop.longitude),
          },
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            directionsRenderer.setDirections(result);
          } else {
            console.error('Directions request failed:', status);
          }
        },
      );
    };

    loadGoogleMapsScript();
  }, [bookingData]);

  // Update the driver marker position on map when location changes
  useEffect(() => {
    if (driverMarkerRef.current && location && location.latitude && location.longitude) {
      driverMarkerRef.current.setPosition({
        lat: parseFloat(location.latitude),
        lng: parseFloat(location.longitude),
      });
    } else if (location && mapInstance.current) {
      // If no driver marker exists, create one when location updates for the first time
      driverMarkerRef.current = new window.google.maps.Marker({
        position: {
          lat: parseFloat(location.latitude),
          lng: parseFloat(location.longitude),
        },
        map: mapInstance.current,
        icon: {
          url: carWashIcon1,
          scaledSize: new window.google.maps.Size(30, 30),
        },
        title: 'Driver',
      });
    }
  }, [location]);

  if (!bookingData) return <LoadingSpinner />

  const handleClick = () => {
    navigate(-1) // This goes back to the previous page
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        minHeight: hiddenClass ? '100vh' : '85vh',
        width: '100%',
        flexDirection: hiddenClass ? 'column' : 'row',
      }}
    >
      {hiddenClass ? (
        <div
          onClick={handleClick}
          style={{
            height: hiddenClassmobile ? '5vh' : '7vh',
            width: hiddenClassmobile ? '5vh' : '7vh',
            borderRadius: '50%',
            backgroundColor: '#FABA49',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            marginTop: '6vh',
            marginRight: 'auto',
            marginLeft: '6%',
          }}
        >
          <img
            src={arrow}
            alt="arrow"
            height="50%"
            style={{
              transform: 'rotate(-180deg)',
            }}
          />
        </div>
      ) : (
        <></>
      )}
      <div
        ref={mapRef}
        style={{
          height: hiddenClass ? '40vh' : '70vh',
          marginTop: hiddenClass ? '5vh' : '0vh',
          marginLeft: hiddenClass ? '0%' : '5%',
          width: hiddenClass ? '85%' : '26.666%',
          borderRadius: '3vw',
          backgroundColor: '#f2f2f2',
        }}
      />
      <div
        style={{
          height: hiddenClass ? 'auto' : '70vh',
          marginTop: hiddenClass ? '5vh' : '0vh',
          width: hiddenClass ? '85%' : '30%',
          border: '1px solid #FABA49',
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '3vw',
          marginLeft: hiddenClass ? '0%' : '5%',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            backgroundColor: '#fadaa1',
            height: hiddenClass ? '12vh' : '9vh',
            marginTop: '5vh',
            width: '80%',
            borderRadius: '2vw',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          {bookingData.booking_status}
        </div>
        <div
          style={{
            border: '1px solid #FABA49',
            height: '25vh',
            marginTop: '3vh',
            width: '80%',
            borderRadius: '2vw',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div>Car Wash Details</div>
          <div>
            <strong>Name:</strong> {bookingData.shop.name}
          </div>
          <div>
            <strong>Owner:</strong> {bookingData.shop.owner}
          </div>
          <div>
            <strong>Phone:</strong> {bookingData.shop.phone}
          </div>
        </div>
        <div
          style={{
            border: '1px solid #FABA49',
            height: '25vh',
            marginTop: '3vh',
            marginBottom: '5vh',
            width: '80%',
            borderRadius: '2vw',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div>User Details</div>
          <div>
            <strong>Name:</strong> {bookingData.customer.name}
          </div>
          <div>
            <strong>Phone:</strong> {bookingData.customer.phone}
          </div>
        </div>
      </div>
      <div
        style={{
          height: hiddenClass ? 'auto' : '70vh',
          marginTop: hiddenClass ? '5vh' : '0vh',
          marginBottom: hiddenClass ? '5vh' : '0vh',
          width: hiddenClass ? '85%' : '30%',
          border: '1px solid #FABA49',
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '3vw',
          marginLeft: hiddenClass ? '0%' : '5%',
          marginRight: hiddenClass ? '0%' : '5%',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            backgroundColor: '#fadaa1',
            height: '17vh',
            marginTop: '5vh',
            width: '80%',
            borderRadius: '2vw',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div>Booking Details</div>
          <div>
            <strong>Date :</strong> {bookingData.booking_date}
          </div>
          <div>
            <strong>Slot :</strong> {bookingData.selected_slot}
          </div>
        </div>
        <div
          style={{
            border: '1px solid #FABA49',
            height: '40vh',
            marginTop: '3vh',
            marginBottom: '5vh',
            width: '80%',
            borderRadius: '2vw',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {bookingData.driver.driver_name === null ? (
            <div>Driver is not allotted</div>
          ) : (
            <>
              <div>Driver Details</div>
              <div>
                <strong>Name:</strong> {bookingData.driver.driver_name}
              </div>
              <div>
                <strong>Phone:</strong> {bookingData.driver.phone}
              </div>
              {/* Display pickup photos */}
              {bookingData.pickup_photos.length > 0 && (
                <div>
                  <h4>Pickup Photos</h4>
                  {bookingData.pickup_photos.map((photo, index) => (
                    <img
                      key={index}
                      src={photo}
                      alt={`Pickup ${index + 1}`}
                      style={{ width: '5vh', height: '5vh', margin: '5px 0' }}
                    />
                  ))}
                </div>
              )}

              {/* Display car wash photos */}
              {bookingData.car_wash_photos.length > 0 && (
                <div>
                  <h4>Car Wash Photos</h4>
                  {bookingData.car_wash_photos.map((photo, index) => (
                    <img
                      key={index}
                      src={photo}
                      alt={`Car Wash ${index + 1}`}
                      style={{ width: '5vh', height: '5vh', margin: '5px 0' }}
                    />
                  ))}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default Mock
