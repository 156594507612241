// src/useDriverLocation.js
import { useState, useEffect } from "react";
import { ref, onValue } from "firebase/database";
import { database } from "../notifications/firebase";

// Custom hook to listen for the driver's location updates from Firebase
export const useDriverLocation = (userId) => {
  const [location, setLocation] = useState(null);

  useEffect(() => {
    const locationRef = ref(database, `drivers/${userId}`);
    const unsubscribe = onValue(locationRef, (snapshot) => {
      const locationData = snapshot.val();
      setLocation(locationData);

    });

    // Cleanup listener on component unmount
    return () => unsubscribe();
  }, [userId]);

  return location;
};
