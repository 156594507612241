import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDevice } from '../Components/Context/deviceContext';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

const StyledFileInput = styled.input`
    border: 2px solid orange;
    border-radius: 8px;
    padding: 8px;
    font-size: 16px;
    color: orange;
    background-color: white;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
        border-color: darkorange;
    }

    &:focus {
        outline: none;
        border-color: darkorange;
    }

    &:disabled {
        cursor: not-allowed;
        border-color: lightgray;
        color: gray;
    }
`;

const Driverupload = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { phoneNumber } = useDevice();
    const [images, setImages] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [uploadStatus, setUploadStatus] = useState({});
    const [hiddenClass, setHiddenClass] = useState('')

    useEffect(() => {
        const handleResize = () => {
          if (window.innerWidth < 1200) {
            setHiddenClass('hidden')
          } else {
            setHiddenClass('')
          }
        }
    
        window.addEventListener('resize', handleResize)
        handleResize() // Initial check
    
        return () => {
          window.removeEventListener('resize', handleResize)
        }
      }, [])

    // Handle file selection
    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        if (selectedFiles.length + images.length > 4) {
            alert('You can only upload up to 4 images.');
            return;
        }
        setImages((prevImages) => [...prevImages, ...selectedFiles]);
    };

    // Upload images
    const handleUpload = async () => {
        if (images.length === 0) {
            alert('Please select at least one image.');
            return;
        }

        setUploading(true);

        const formData = new FormData();
        images.forEach((image) => formData.append('image', image));

        try {
            const response = await axios.post(
                `https://www.dsctech.in/api/booking/${id}/pickup_photos/${phoneNumber}/`,
                formData,
                { headers: { 'Content-Type': 'multipart/form-data' } }
            );
            alert('Images uploaded successfully!');
            // Update status and clear selected images from input
            const uploadedStatus = images.reduce((acc, image, idx) => {
                acc[URL.createObjectURL(image)] = 'uploaded';
                return acc;
            }, {});
            setUploadStatus((prevStatus) => ({ ...prevStatus, ...uploadedStatus }));
            setImages([]); 
            navigate(-1); 
        } catch (err) {
            alert('Failed to upload images. Please try again.');
            console.error(err);
        } finally {
            setUploading(false);
        }
    };

    // Remove a selected image
    const handleRemoveImage = (indexToRemove) => {
        setImages((prevImages) => prevImages.filter((_, index) => index !== indexToRemove));
    };

    const handleBack = () => {
        window.history.back() // Example to go back to the previous page
      }

    // Styled button component
    const StyledButton = styled.button`
        background-color: #FABA49;
        color: black;
        border: 2px solid #FABA49;
        border-radius: 25px;
        padding: 10px 20px;
        cursor: pointer;
        font-size: 16px;
        transition: all 0.3s ease;


        &:disabled {
            background-color: lightgray;
            border-color: lightgray;
            cursor: not-allowed;
        }
    `;
    
    return (
        <div style={{ height: '85vh', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
             {hiddenClass ? <div>
          <button
            onClick={handleBack} // Call handleBack on button click
            style={{
              position: 'absolute',
              top: '7vh',
              left: '7%',
              marginTop: '10px',
              padding: '15px',
              borderRadius: '30px',
              border: '1px solid #ffffff',
              backgroundColor: '#FADAA1',
              color: 'black',
              cursor: 'pointer',
              marginRight: '10px',
            }}
          >
            Back
          </button>
        </div> : <div></div>}
            <h3>Upload Images</h3>
            <StyledFileInput
                type="file"
                multiple
                accept="image/*"
                onChange={handleFileChange}
            />
            <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '2vh', marginBottom:'2vh' }}>
                {images.map((image, index) => (
                    <div key={index} style={{ marginRight: '10px', position: 'relative' }}>
                        <img
                            src={URL.createObjectURL(image)}
                            alt={`Selected ${index}`}
                            width="100"
                            height="100"
                            style={{ objectFit: 'cover' }}
                        />
                        <button
                            onClick={() => handleRemoveImage(index)}
                            style={{
                                position: 'absolute',
                                top: '5px',
                                right: '5px',
                                background: 'red',
                                color: 'white',
                                border: 'none',
                                borderRadius: '50%',
                                cursor: 'pointer'
                            }}
                        >
                            &times;
                        </button>
                        {uploadStatus[URL.createObjectURL(image)] === 'uploaded' && (
                            <span style={{ color: 'green', position: 'absolute', bottom: '-15px', left: '10px' }}>Uploaded</span>
                        )}
                    </div>
                ))}
            </div>
            <StyledButton
                onClick={handleUpload}
                disabled={uploading}
            >
                {uploading ? 'Uploading...' : 'Upload'}
            </StyledButton>
        </div>
    );
};

export default Driverupload;
