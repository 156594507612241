import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const Container = styled.div`
    height: 5vh;
    background-color:#FFF7D4;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 400;
    @media only screen and (max-width: 1200px) {
       display: none;
      }
`;

const Announcement = () => {
    // const [announcement, setAnnouncement] = useState('');

    // useEffect(() => {
    //     fetchAnnouncement();
    // }, []);

    // const fetchAnnouncement = async () => {
    //     try {
    //         const response = await fetch('https://andhraruchulu.onrender.com/announcement');
    //         const data = await response.json();
    //         if (data.success) {
    //             setAnnouncement(data.message);
    //         }
    //     } catch (error) {
    //         console.error('Error fetching announcement:', error);
    //     }
    // };

    return (
        <Container>
            <div style={{ marginLeft: '10%', marginRight: '10%', textAlign: 'center' }}>
                <div style={{ fontSize: '1vw' }}>Book, Relax, and Shine!</div>
            </div>
        </Container>
    );
};

export default Announcement;
