import React, { createContext, useContext, useState, useEffect } from 'react';
import { getDeviceType } from '../../Utils/deviceUtils';
import CryptoJS from 'crypto-js';

const DeviceContext = createContext();

export const useDevice = () => useContext(DeviceContext);

export const DeviceProvider = ({ children }) => {
  const [deviceType, setDeviceType] = useState(getDeviceType());
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [carShops, setCarShops] = useState([]);
  const [distances, setDistances] = useState([]);

  useEffect(() => {
    // Retrieve and decrypt the phone number from localStorage
    const encryptedPhoneNumber = localStorage.getItem('phoneNumber');
    if (encryptedPhoneNumber) {
      const bytes = CryptoJS.AES.decrypt(encryptedPhoneNumber, 'your-secret-key');
      const decryptedPhoneNumber = bytes.toString(CryptoJS.enc.Utf8);
      setPhoneNumber(decryptedPhoneNumber);
    }

    // Retrieve latitude and longitude from localStorage
    const storedLatitude = localStorage.getItem('latitude');
    const storedLongitude = localStorage.getItem('longitude');

    if (storedLatitude) {
      setLatitude(storedLatitude);
    }

    if (storedLongitude) {
      setLongitude(storedLongitude);
    }

    // Fetch car shop details from the API
    const fetchCarShops = async () => {
      try {
        if (latitude && longitude) {
          const response = await fetch(
            `https://www.dsctech.in/api/carshops/${latitude}/${longitude}/`
          );
    
          if (!response.ok) {
            // Handle non-2xx responses
            const errorMessage = await response.text();
            throw new Error(
              `Failed to fetch car shops: ${response.status} ${errorMessage}`
            );
          }
    
          const data = await response.json();
          setCarShops(data[0]); // Car shop details
          setDistances(data[1]); // Distances
        } 
      } catch (error) {
        console.error('Error fetching car shops:', error);
      }
    };
    

    fetchCarShops();

    // Handle device type updates on window resize
    const handleResize = () => setDeviceType(getDeviceType());
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [latitude, longitude, phoneNumber]);

  return (
    <DeviceContext.Provider value={{ deviceType, phoneNumber, latitude, longitude, carShops, distances }}>
      {children}
    </DeviceContext.Provider>
  );
};
