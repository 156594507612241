import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDevice } from '../Components/Context/deviceContext'
import axios from 'axios'

const AddressCard = styled.div`
  border: 1px solid #faba49;
  border-radius: 10px;
  padding: 15px;
  margin-top: 5vh;
  background-color: #fff;
`
const AddressGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin-left: 7%;
  margin-top: 2vh;
  margin-bottom: 2vh;
  margin-right: 7%;
`

const Button = styled.button`
  display: block;
  width: 100%;
  border: 1px solid #faba49;
  background-color: ${(props) => (props.active ? '#FABA49' : '#fff')};
  color: ${(props) => (props.active ? '#000' : '#000')};
  cursor: pointer;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  text-align: center; /* Center text inside button */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */

  &:hover {
    background-color: ${(props) => (props.active ? '#FABA49' : '#FCD184')};
  }
`
const Input = styled.input`
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 30px;
  background-color: #ffffff;
  border: 1px solid #ff8c00;
  font-size: 16px;

  &:focus {
    background-color: #fff7d4;
    border: 1px solid #ff8c00;
    outline: none;
  }

  &:disabled {
    background-color: #fff;
    cursor: not-allowed;
  }
`

const Savecar = () => {
    const [cars, setCars] = useState([])
  const { phoneNumber } = useDevice()
  const [newCar, setNewCar] = useState({
    model: '',
    color: '',
    carNumber: '',
    carName: '',
    carType: '',
    rcPhoto: null,
    insurancePhoto: null,
  })

  const [error, setError] = useState(null)

  const [isDialogOpen1, setIsDialogOpen1] = useState(false)

  // Function to open the dialog
  const openDialog1 = () => {
    setIsDialogOpen1(true)
  }

  // Function to close the dialog and reset car details form
  const closeDialog1 = () => {
    setIsDialogOpen1(false)
    setNewCar({
      model: '',
      color: '',
      carNumber: '',
      carName: '',
      carType: '',
      rcPhoto: null,
      insurancePhoto: null,
    })
  }

  // Function to handle input changes (text fields)
  const handleInputChange1 = (e) => {
    const { name, value } = e.target
    setNewCar((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  // Function to handle file uploads (for rcPhoto and insurancePhoto)
  const handleFileChange = (e) => {
    const { name, files } = e.target
    setNewCar((prev) => ({
      ...prev,
      [name]: files[0], // Store the selected file
    }))
  }

  // Function to submit the new car details
  const handleSubmitCar = async () => {

    if (!newCar.model) {
      alert('Please enter the car model.')
      return
    }

    if (!newCar.color) {
      alert('Please enter the car color.')
      return
    }

    if (!newCar.carNumber) {
      alert('Please enter the car number.')
      return
    }

    if (!newCar.carName) {
      alert('Please enter the car name.')
      return
    }

    if (!newCar.carType) {
      alert('Please enter the car type.')
      return
    }

    // Use FormData for file uploads
    const formData = new FormData()

    // Append fields to formData
    formData.append('phone', phoneNumber)
    formData.append('model', newCar.model)
    formData.append('color', newCar.color)
    formData.append('car_number', newCar.carNumber)
    formData.append('car_name', newCar.carName)
    formData.append('car_type', newCar.carType)
    if (newCar.rcPhoto) formData.append('rcPhoto', newCar.rcPhoto)
    if (newCar.insurancePhoto)
      formData.append('insurancePhoto', newCar.insurancePhoto)

    try {
      const response = await axios.post(
        'https://www.dsctech.in/api/add_car_details/',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data', // Set the proper headers for file upload
          },
        },
      )

      console.log('Car details added:', response.data)
      fetchCars() // Ensure this function is defined
      closeDialog1() // Ensure this function is defined
    } catch (error) {
      setError(
        `Error adding car details: ${
          error.response ? error.response.data : error.message
        }`,
      )
      console.error('Error:', error)
    }
  }

  const handleBack = () => {
    window.history.back()
  }

 const fetchCars = async () => {
    try {
      const response = await fetch(
        `https://www.dsctech.in/api/car/${phoneNumber}/`,
      )
      const data = await response.json()
      setCars(data.cars)
    } catch (error) {
      console.error('Error fetching cars:', error)
    }
  }

  useEffect(() => {
    if (phoneNumber) {
      fetchCars()
    }
  }, [phoneNumber])

  return (
    <div style={{ height: '100%', width: '100'}}>
        <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
        <button
        onClick={handleBack} // Call handleBack on button click
        style={{
          marginTop: '6vh',
          padding: '15px',
          height: '6vh',
          width:"20%",
          borderRadius: '30px',
          border: '1px solid #ffffff',
          backgroundColor: '#FADAA1',
          color: 'black',
          cursor: 'pointer',
          marginLeft: '7%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        Back
      </button>

        <Button
        onClick={openDialog1}
        style={{
          height: '6vh',
          marginTop: '6vh',
          borderRadius: '30px',
          width:'35%',
          marginRight: '7%',
          padding: '5px',
          backgroundColor: '#FADAA1',
          color: 'black',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '14px',
        }}
      >
        Add Car
      </Button>
        </div>
      <AddressGrid>
      {cars.length > 0 ? (
                cars.map((car) => (
                  <AddressCard key={car.id}>
                    <p>
                      <strong>Car Name:</strong> {car.car_name}
                    </p>
                    <p>
                      <strong>Model:</strong> {car.model}
                    </p>
                    <p>
                      <strong>Color:</strong> {car.color}
                    </p>
                    <p>
                      <strong>Car Number:</strong> {car.car_number}
                    </p>
                    <p>
                      <strong>Car Type:</strong> {car.car_type}
                    </p>
                  </AddressCard>
                ))
              ) : (
                <p>No car details found for this phone number.</p>
              )}
      </AddressGrid>
      
      <div style={{ height: '10vh' }} />
      {isDialogOpen1 && (
              <>
                {/* Overlay with blur effect */}
                <div
                  style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    backdropFilter: 'blur(5px)',
                    zIndex: 1,
                  }}
                />

                {/* Dialog Container */}
                <div
                  style={{
                    position: 'fixed',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: '#ffffff',
                    padding: '20px',
                    borderRadius: '10px',
                    boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                    zIndex: 2,
                    width: '80%',
                  }}
                >
                  <h4>Add New Car Details</h4>

                  {/* Error Message */}
                  {error && <p style={{ color: 'red' }}>{error}</p>}

                  {/* Container for Input Fields */}
                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      justifyContent: 'start',
                      width: '100%',
                      gap: '5%',
                    }}
                  >
                    <Input
                    style={{width:'100%'}}
                      type="text"
                      placeholder="Car Model"
                      name="model"
                      value={newCar.model}
                      onChange={handleInputChange1}
                    />
                    <Input
                    style={{width:'100%'}}
                      type="text"
                      placeholder="Car Color"
                      name="color"
                      value={newCar.color}
                      onChange={handleInputChange1}
                    />
                    <Input
                    style={{width:'100%'}}
                      type="text"
                      placeholder="Car Number"
                      name="carNumber"
                      value={newCar.carNumber}
                      onChange={handleInputChange1}
                    />
                    <Input
                    style={{width:'100%'}}
                      type="text"
                      placeholder="Car Name"
                      name="carName"
                      value={newCar.carName}
                      onChange={handleInputChange1}
                    />
                    <Input
                    style={{width:'100%'}}
                      type="text"
                      placeholder="Car Type"
                      name="carType"
                      value={newCar.carType}
                      onChange={handleInputChange1}
                    />
                    <Input
                    style={{width:'100%'}}
                      type="file"
                      name="rcPhoto"
                      onChange={handleFileChange}
                    />
                    <Input
                      style={{width:'100%'}}
                      type="file"
                      name="insurancePhoto"
                      onChange={handleFileChange}
                    />
                  </div>

                  {/* Submit and Cancel Buttons */}
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                      onClick={handleSubmitCar}
                      style={{
                        marginTop: '10px',
                        padding: '10px',
                        borderRadius: '20px',
                        backgroundColor: '#FADAA1',
                        color: 'black',
                        cursor: 'pointer',
                        marginRight: '10px',
                      }}
                    >
                      Submit
                    </Button>
                    <Button
                      onClick={closeDialog1}
                      style={{
                        marginTop: '10px',
                        padding: '10px',
                        borderRadius: '20px',
                        backgroundColor: '#ffffff',
                        color: 'black',
                        cursor: 'pointer',
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </>
            )}
    </div>
  )
}

export default Savecar
