// src/components/TermsAndConditions.js
import React, { useEffect } from 'react';

const TermsAndConditions = () => {
  // Ensure the page scrolls to the top on mount
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      style={{
        padding: '20px',
        maxWidth: '800px',
        margin: '0 auto',
        lineHeight: '1.6',
      }}
    >
      <h1>Terms and Conditions</h1>

      <h2>1. Introduction</h2>
      <p>
        These Terms and Conditions govern your use of Hubio (the "Website"), a platform that
        connects users with car wash providers. By accessing or using the Website, you agree to be bound
        by these Terms and Conditions.
      </p>

      <h2>2. Services</h2>
      <p>
        The Website provides a platform for users to book car wash services from nearby providers. 
        We do not provide car wash services ourselves.
      </p>

      <h2>3. User Registration</h2>
      <p>
        To use the Website, you may need to register for an account. You must provide accurate and
        up-to-date information. You are responsible for maintaining the confidentiality of your account credentials.
      </p>

      <h2>4. Bookings</h2>
      <p>
        You can search for car washes near your location and book appointments through the Website.
        Bookings are subject to availability and may be confirmed by the car wash provider.
        You may be required to pay a deposit or full payment upfront for your booking.
      </p>

      <h2>5. Cancellation and Rescheduling</h2>
      <p>
        You may be able to cancel or reschedule your booking subject to the terms and conditions of
        the car wash provider. Cancellation fees may apply.
      </p>

      <h2>6. Payment</h2>
      <p>
        Payment for car wash services may be made through the Website using the available payment methods.
        You are responsible for ensuring that your payment information is accurate and that you have sufficient 
        funds to complete the transaction.
      </p>

      <h2>7. Car Wash Services</h2>
      <p>
        Car wash providers are independent contractors and not employees of Hubio.
        We are not responsible for the quality of car wash services provided by third parties.
        Any disputes or issues related to car wash services should be resolved directly with the provider.
      </p>

      <h2>8. Intellectual Property</h2>
      <p>
        The Website, its content, and the technology used to deliver it are the property of Hubio
        and are protected by intellectual property laws. You may not use, reproduce, modify, or
        distribute any part of the Website without our prior written consent.
      </p>

      <h2>9. Limitation of Liability</h2>
      <p>
        Hubio is not liable for any direct, indirect, incidental, special, or consequential damages
        arising out of or related to your use of the Website or the services provided through it.
      </p>

      <h2>10. Governing Law</h2>
      <p>
        These Terms and Conditions shall be governed by and construed in accordance with the laws
        of Andhra Pradesh, India.
      </p>

      <h2>11. Changes to Terms and Conditions</h2>
      <p>
        We may update these Terms and Conditions from time to time. Any changes will be effective
        upon posting on the Website.
      </p>

      <h2>12. Contact Information</h2>
      <p>
        If you have any questions about these Terms and Conditions or the Website, please contact us at:
      </p>
      <address>
        <strong>
          Hubio, 14, Seethammapeta Main Rd,<br />
          Dwaraka Nagar, Visakhapatnam, Andhra Pradesh 530016
        </strong>
      </address>

      <p>Last Updated: 15 October 2024</p>
    </div>
  );
};

export default TermsAndConditions;
