import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDevice } from '../Components/Context/deviceContext'
import axios from 'axios'

const AddressCard = styled.div`
  border: 1px solid #faba49;
  border-radius: 10px;
  padding: 15px;
  margin-top: 5vh;
  background-color: #fff;
`
const AddressGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin-left: 7%;
  margin-top: 2vh;
  margin-bottom: 2vh;
  margin-right: 7%;
`

const Button = styled.button`
  display: block;
  width: 100%;
  border: 1px solid #faba49;
  background-color: ${(props) => (props.active ? '#FABA49' : '#fff')};
  color: ${(props) => (props.active ? '#000' : '#000')};
  cursor: pointer;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  text-align: center; /* Center text inside button */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */

  &:hover {
    background-color: ${(props) => (props.active ? '#FABA49' : '#FCD184')};
  }
`
const Input = styled.input`
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 30px;
  background-color: #ffffff;
  border: 1px solid #ff8c00;
  font-size: 16px;

  &:focus {
    background-color: #fff7d4;
    border: 1px solid #ff8c00;
    outline: none;
  }

  &:disabled {
    background-color: #fff;
    cursor: not-allowed;
  }
`

const Savedaddress = () => {
  const { phoneNumber } = useDevice()
  const [addresses, setAddresses] = useState([])
  const [newAddress, setNewAddress] = useState({
    street: '',
    city: '',
    state: '',
    postal_code: '',
    country: '',
  })
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const openDialog = () => {
    setIsDialogOpen(true)
  }

  // Function to close the dialog
  const closeDialog = () => {
    setIsDialogOpen(false)
    // Reset new address fields if needed
    setNewAddress({
      street: '',
      city: '',
      state: '',
      postal_code: '',
      country: '',
    })
  }

  // Function to handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target
    setNewAddress((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleSubmitAddress = async () => {
    // Check for missing required fields
    if (!phoneNumber) {
      alert('Please enter your phone number.')
      return
    }

    if (!newAddress.street) {
      alert('Please enter your street address.')
      return
    }

    if (!newAddress.city) {
      alert('Please enter your city.')
      return
    }

    if (!newAddress.state) {
      alert('Please enter your state.')
      return
    }

    if (!newAddress.postal_code) {
      alert('Please enter your postal code.')
      return
    }

    if (!newAddress.country) {
      alert('Please enter your country.')
      return
    }

    try {
      const response = await axios.post(
        'https://www.dsctech.in/add-address/',
        {
          phone: phoneNumber,
          street: newAddress.street,
          city: newAddress.city,
          state: newAddress.state,
          postal_code: newAddress.postal_code,
          country: newAddress.country,
        },
        {
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
          },
        },
      )

      console.log('Address added:', response.data)
      // Optionally fetch addresses
      fetchAddresses()
      // Close dialog
      closeDialog()
    } catch (error) {
      console.error('Error adding address:', error)
      // Handle error state if needed
    }
  }

  const handleBack = () => {
    window.history.back()
  }

  useEffect(() => {
    if (phoneNumber) {
      fetchAddresses()
    }
  }, [phoneNumber])

  const fetchAddresses = async () => {
    try {
      const response = await fetch(
        `https://www.dsctech.in/addresses/${phoneNumber}/`,
      )
      const data = await response.json()
      setAddresses(data.addresses)
    } catch (error) {
      console.error('Error fetching addresses:', error)
    }
  }

  return (
    <div style={{ height: '100%', width: '100'}}>
        <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
        <button
        onClick={handleBack} // Call handleBack on button click
        style={{
          marginTop: '6vh',
          padding: '15px',
          height: '6vh',
          width:"20%",
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '30px',
          border: '1px solid #ffffff',
          backgroundColor: '#FADAA1',
          color: 'black',
          cursor: 'pointer',
          marginLeft: '7%',
        }}
      >
        Back
      </button>

        <Button
        onClick={openDialog}
        style={{
          height: '6vh',
          marginTop: '6vh',
          borderRadius: '30px',
          width:'35%',
          marginRight: '7%',
          padding: '5px',
          backgroundColor: '#FADAA1',
          color: 'black',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '14px',
        }}
      >
        Add Address
      </Button>
        </div>
      <AddressGrid>
        {addresses.length > 0 ? (
          addresses
            .slice()
            .reverse()
            .map((
              address, // Use slice() to avoid mutating the original array
            ) => (
              <AddressCard key={address.id}>
                <p>
                  <strong>Street:</strong> {address.street}
                </p>
                <p>
                  <strong>City:</strong> {address.city}
                </p>
                <p>
                  <strong>State:</strong> {address.state}
                </p>
                <p>
                  <strong>Postal Code:</strong> {address.postal_code}
                </p>
                <p>
                  <strong>Country:</strong> {address.country}
                </p>
              </AddressCard>
            ))
        ) : (
          <p>No addresses found for this phone number.</p>
        )}
      </AddressGrid>
      
      <div style={{ height: '10vh' }} />
      {isDialogOpen && (
        <>
          {/* Overlay with blur effect */}
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
              backdropFilter: 'blur(5px)', // Adjust the blur intensity
              zIndex: 1, // Ensure overlay is above other content
            }}
          />

          {/* Dialog Container */}
          <div
            style={{
              position: 'fixed',
              top: '55%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: '#ffffff',
              padding: '20px',
              borderRadius: '10px',
              boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
              zIndex: 2, // Ensure dialog is above the overlay
              width: '80%', // Set a width for the dialog
            }}
          >
            <h4>Add New Address</h4>

            {/* Container for Input Fields */}
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'start',
                width: '100%',
                gap:'5%'
              }}
            >
              <Input
                style={{width:"100%"}}
                type="text"
                placeholder="Street"
                name="street"
                value={newAddress.street}
                onChange={handleInputChange}
              />
              <Input
              style={{width:"100%"}}
                type="text"
                placeholder="City"
                name="city"
                value={newAddress.city}
                onChange={handleInputChange}
              />
              <Input
              style={{width:"100%"}}
                type="text"
                placeholder="State"
                name="state"
                value={newAddress.state}
                onChange={handleInputChange}
              />
              <Input
              style={{width:"100%"}}
                type="text"
                placeholder="Postal Code"
                name="postal_code"
                value={newAddress.postal_code}
                onChange={handleInputChange}
              />
              <Input
              style={{width:"100%"}}
                type="text"
                placeholder="Country"
                name="country"
                value={newAddress.country}
                onChange={handleInputChange}
              />
            </div>

            {/* Submit and Cancel Buttons */}
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                onClick={handleSubmitAddress}
                style={{
                  marginTop: '10px',
                  padding: '10px',
                  borderRadius: '20px',
                  backgroundColor: '#FADAA1',
                  color: 'black',
                  cursor: 'pointer',
                  marginRight: '10px', // Space between buttons
                }}
              >
                Submit
              </Button>
              <Button
                onClick={closeDialog}
                style={{
                  marginTop: '10px',
                  padding: '10px',
                  borderRadius: '20px',
                  backgroundColor: '#ffffff',
                  color: 'black',
                  cursor: 'pointer',
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default Savedaddress
