import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import aboutus from "../Components/Assets/aboutus.png";
import Driver from "../Components/Assets/Driver.png";
import phone from "../Components/Assets/phone.png";
import time from "../Components/Assets/time.png";
import phonecontact from "../Components/Assets/phone.svg";
import mail from "../Components/Assets/mail.svg";
import location from "../Components/Assets/locationcontact.svg";
import tyre from '../Components/Assets/tyre.png'
import { useNavigate } from 'react-router-dom';
import arrow from "../Components/Assets/arrow.svg";
import Footer from '../Components/fotter';

const HideContainer = styled.div`
  @media (max-width: 1200px) {
    display: none;
  }
`;

const Container = styled.div`
  min-height: 100vh;
`;

const InnerContainer = styled.div`
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  @media (max-width: 1200px) {
    width: 88%;
  }
`;

const Heading = styled.div`
  font-size: 2vw;
  font-weight: 400;
  @media (min-width: 768px) and (max-width: 1200px) {
      font-size: 3.8vw;
      letter-spacing:  0.5vw;
    }
      @media (max-width: 767px) {
        font-size:3.4vw;
        letter-spacing:  0.5vw;
    }
`;

const DateInput = styled.input`
    font-size: 1.2vw;
    padding: 0.5vw 1.3vw;
    margin-top: 1vh;
    border-radius: 2vw;
    border: 2px solid #FABA49; 
    background-color: white;

    &:focus {
        border: 1px solid #FABA49; 
        background-color: #ffebcc; 
    }

    @media (min-width: 768px) and (max-width: 1200px) {
    font-size: 4.5vw;
     padding: 1.7vw 2vw;
    }
      @media (max-width: 767px) {
      font-size: 4.5vw;
        padding: 1.7vw 4vw;
        border-radius: 6vw;
    }
`;

const DateInput1 = styled.textarea`
    font-size: 1.2vw;
    padding: 0.5vw 1.3vw;
    margin-top: 1vh;
    border-radius: 2vw;
    border: 2px solid #FABA49; 
    background-color: white;
    resize: none; /* Prevent resizing */

    &:focus {
        border: 1px solid #FABA49; 
        background-color: #ffebcc; 
    }

    @media (min-width: 768px) and (max-width: 1200px) {
        font-size: 4.5vw;
        padding: 1.7vw 2vw;
    }

    @media (max-width: 767px) {
        font-size: 4.5vw;
        padding: 1.7vw 4vw;
        border-radius: 6vw;
    }
`;

const Button1 = styled.button`
  position: relative; /* Ensure the button is positioned relative to its container */
  padding: 10px;
  background-color: #FABA49;
  color: #000;
  font-size: 16px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  overflow: hidden; /* Ensure no content overflows the button */
  display: flex; /* Use flex to align text properly */
  align-items: center; /* Center text vertically */
  justify-content: center; /* Center text horizontally */

  img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover; /* Ensure image covers the button */
    z-index: 1; /* Ensure image is behind the text */
    opacity: 0.1;
  }

  span {
    position: relative;
    z-index: 2; /* Ensure text is above the image */
  }

  @media (min-width: 768px) and (max-width: 1200px) {
       border-radius: 0px;
       padding: 15px;
    }
      @media (max-width: 767px) {
        border-radius: 7vw;
        padding: 15px;
    }
`;

const Spinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.3);
  border-top: 4px solid #000;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;


const Aboutus = () => {
    const navigate = useNavigate();
    const [hiddenClass, setHiddenClass] = useState('');
    const [hiddenClassmobile, setHiddenClassmobile] = useState('');
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });


    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 1200) {
                setHiddenClass('hidden');
            } else {
                setHiddenClass('');
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Initial check

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const handleResizemobile = () => {
            if (window.innerWidth < 500) {
                setHiddenClassmobile('hidden');
            } else {
                setHiddenClassmobile('');
            }
        };

        window.addEventListener('resize', handleResizemobile);
        handleResizemobile(); // Initial check

        return () => {
            window.removeEventListener('resize', handleResizemobile);
        };
    }, []);

    const handleClick = () => {
        navigate(-1);
    };

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
    };

    const handleSubmit = async () => {
        setLoading(true); // Show loading spinner
        try {
            const response = await fetch('https://www.dsctech.in/send-message/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });
            if (response.ok) {
                alert("Message sent successfully!");
                setFormData({ name: '', email: '', message: '' }); // Clear form data
            } else {
                alert("Failed to send message.");
            }
        } catch (error) {
            alert("An error occurred. Please try again.");
        }
        setLoading(false); // Hide loading spinner
    };
    

    return (
        <div>
            <Container >
            <InnerContainer>
                {hiddenClass ? (
                    <div style={{ width: '100%' }}>
                        <div onClick={handleClick} style={{ height: hiddenClassmobile ? '5vh' : '7vh', width: hiddenClassmobile ? '5vh' : '7vh', borderRadius: '50%', backgroundColor: '#FABA49', alignItems: "center", justifyContent: "center", display: "flex", marginTop: '6vh' }}>
                            <img
                                src={arrow}
                                alt='arrow'
                                height='50%'
                                style={{
                                    transform: 'rotate(-180deg)'
                                }}
                            />
                        </div>
                        <div style={{ marginTop: '6vh' }}>
                            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <img src={aboutus} alt='' width='70%' />
                            </div>
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'start', marginTop: '6vh' }}>
                                <div style={{
                                    fontSize: "6vw",
                                    letterSpacing: "0.2vw",
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}>About Us</div>
                                <div style={{ fontSize: '4.5vw', letterSpacing: '0.3vw', marginTop: '1vh', textAlign:'center' }}>For spotless car care, Hubio offers convenient home or office car washing services across the city, saving you valuable time. Our comprehensive services include car washes, cleaning, repairs, and more. Choose Hubio for a 100% solution to your car care needs.</div>
                            </div>
                            <div style={{ width: '100%', minHeight: '60vh', borderRadius: '5vw', marginTop: '6vh', border: '2px solid #FABA49', marginBottom: '6vh', display: 'flex', flexDirection:'column' }}>
                                <div style={{ height: 'auto', margin: '6%', width: '88%', backgroundColor: '#FFE6B9', borderRadius: '3vw', position: 'relative', overflow: 'hidden' }}>
                                    <div style={{ height: '30vh', width: '30vh', position: 'absolute', bottom: '-10vh', right: '-10vh', borderRadius: '50%', backgroundColor: '#FCD184', alignItems: 'center', justifyContent: 'center', display: 'flex' }} >
                                        <div style={{ height: '20vh', width: '20vh', borderRadius: '50%', backgroundColor: '#FFC153', }} />
                                    </div>
                                    <div style={{ height: '100%', width: 'auto', margin: '3vh', display: 'flex', flexDirection: 'column' }}>
                                        <div style={{ letterSpacing: '0.2vw', lineHeight: '0.8', fontSize: "6vw", }}>Contact Information</div>
                                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '3vh', height: '4vh' }}>
                                            <div style={{ width: '12%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                                                <img src={phonecontact} alt='' height='80%' />
                                            </div>
                                            <div style={{ fontSize: '4.5vw', lineHeight: '0.7', marginBottom: '0.1vh' }}>
                                                9876543210
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '3vh', height: '4vh' }}>
                                            <div style={{ width: '12%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                                                <img src={mail} alt='' height='60%' />
                                            </div>
                                            <div style={{ fontSize: '4.5vw', lineHeight: '0.7', marginBottom: '0.8vh' }}>
                                                contact@hubio.co.in
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'start', marginTop: '3vh', height: 'auto', marginBottom:'15vh' }}>
                                            <div style={{ width: '14%', display: 'flex', alignItems: 'start', justifyContent: 'start', height: '4vh' }}>
                                                <img src={location} alt='' height='80%' />
                                            </div>
                                            <div style={{ lineHeight: '0.7', fontSize: '4.5vw', lineHeight: '4vh', marginTop: '-1vh', marginLeft: '3.5%' }}>
                                            MAA MAJJI GOWRI CAR CARE SERVICES, 14, Seethammapeta Main Rd, Dwaraka Nagar, Visakhapatnam, Andhra Pradesh 530016
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ height: 'auto', marginRight: '8%', marginTop: '2.3%', marginBottom: '2.3%', marginLeft: '8%', width: '88%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                    <div style={{ display: 'flex', flexDirection: "column" }}>
                                        <div style={{ fontSize: '4.5vw' }}>Name</div>
                                        <DateInput
                                            type="text"
                                            id="name"
                                            placeholder="Name"
                                            value={formData.name}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: "column" , marginTop: '2vh'}}>
                                        <div style={{ fontSize: '4.5vw' }}>Email</div>
                                        <DateInput
                                           type="email"
                                           id="email"
                                           placeholder="Email"
                                           value={formData.email}
                                           onChange={handleInputChange}
                                        />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: "column", marginTop: '2vh' }}>
                                        <div style={{ fontSize: '4.5vw' }}>Message</div>
                                        <DateInput1
                                            id="message"
                                            placeholder="Message"
                                            rows="3"
                                            value={formData.message}
                                            onChange={handleInputChange}
                                        />
                                    </div>

                                    <div style={{ display: 'flex', gap: '1vw', marginTop: '3vh', marginBottom:'2vh' }}>
                                        <Button1 type="button" style={{ width: '100%', textAlign: 'center' }} onClick={handleSubmit} disabled={loading} >
                                            <img src={tyre} alt="Tyre" />
                                            {loading ? <Spinner /> : <span>Submit</span>}
                                        </Button1>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                ) : (
                    <HideContainer>
                        <div style={{ display: 'flex', marginTop: '3%' }}>
                            <div style={{ width: '45%', display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: '9%', alignItems: 'start' }}>
                                <Heading style={{ letterSpacing: '0.2vw' }}>About Us</Heading>
                                <div style={{ letterSpacing: '0.1vw', marginTop: '1%', fontSize: '1.2vw' }}>For spotless car care, Hubio offers convenient home or office car washing services across the city, saving you valuable time. Our comprehensive services include car washes, cleaning, repairs, and more. Choose Hubio for a 100% solution to your car care needs.</div>
                            </div>
                            <div style={{ width: '28%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '9%', marginLeft: 'auto' }}>
                                <img src={aboutus} alt='' width='100%' />
                            </div>
                        </div>
                        <div style={{ width: '100%', height: '21vh', borderRadius: '3vw', backgroundColor: '#FFE6B9', marginTop: '7vh' }}>
                            <div style={{ width: 'auto', marginLeft: '9%', marginRight: '9%', height: '100%', justifyContent: 'space-between', alignItems: 'center', display: 'flex' }}>
                                <div style={{ display: 'flex', height: '50%', alignItems: 'center' }}>
                                    <img src={Driver} alt='' height='100%' style={{ marginRight: '1.5vw' }} />
                                    <div style={{ fontSize: '1.2vw' }}>
                                        Driver <br /> at doorstep
                                    </div>
                                </div>
                                <div style={{ display: 'flex', height: '50%', alignItems: 'center' }}>
                                    <img src={phone} alt='' height='100%' style={{ marginRight: '1.5vw' }} />
                                    <div style={{ fontSize: '1.2vw' }}>
                                    24/7 <br /> Customer Support
                                    </div>
                                </div>
                                <div style={{ display: 'flex', height: '50%', alignItems: 'center' }}>
                                    <img src={time} alt='' height='100%' style={{ marginRight: '1.5vw' }} />
                                    <div style={{ fontSize: '1.2vw' }}>
                                    Flexible <br /> Scheduling
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ width: '100%', height: '60vh', borderRadius: '3vw', marginTop: '7vh', border: '2px solid #FABA49', marginBottom: '7vh', display: 'flex' }}>
                            <div style={{ height: 'auto', margin: '2.3%', width: '45%', backgroundColor: '#FFE6B9', borderRadius: '1.8vw', position: 'relative', overflow: 'hidden' }}>
                                <div style={{ height: '30vh', width: '30vh', position: 'absolute', bottom: '-10vh', right: '-10vh', borderRadius: '50%', backgroundColor: '#FCD184', alignItems: 'center', justifyContent: 'center', display: 'flex' }} >
                                    <div style={{ height: '20vh', width: '20vh', borderRadius: '50%', backgroundColor: '#FFC153', }} />
                                </div>
                                <div style={{ height: '100%', width: 'auto', margin: '7vh', display: 'flex', flexDirection: 'column' }}>
                                    <Heading style={{ letterSpacing: '0.2vw', lineHeight: '0.8' }}>Contact Information</Heading>
                                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '3vh', height: '4vh' }}>
                                        <div style={{ width: '7%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                                            <img src={phonecontact} alt='' height='80%' />
                                        </div>
                                        <div style={{ fontSize: '1.2vw', lineHeight: '0.7', marginBottom: '0.1vh' }}>
                                            9876543210
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '3vh', height: '4vh' }}>
                                        <div style={{ width: '7%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                                            <img src={mail} alt='' height='60%' />
                                        </div>
                                        <div style={{ fontSize: '1.2vw', lineHeight: '0.7', marginBottom: '0.8vh' }}>
                                            contact@hubio.co.in
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'start', marginTop: '3vh', height: 'auto' }}>
                                        <div style={{ width: '9%', display: 'flex', alignItems: 'start', justifyContent: 'start', height: '4vh' }}>
                                            <img src={location} alt='' height='80%' />
                                        </div>
                                        <div style={{ lineHeight: '0.7', fontSize: '1.2vw', lineHeight: '4vh', marginTop: '-1vh' , zIndex:'1', marginLeft:'2vh'}}>
                                        MAA MAJJI GOWRI CAR CARE SERVICES, 14, Seethammapeta Main Rd, Dwaraka Nagar, Visakhapatnam, Andhra Pradesh 530016
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ height: 'auto', marginRight: '6%', marginTop: '2.3%', marginBottom: '2.3%', marginLeft: '3%', width: '44.5%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex', flexDirection: "column" }}>
                                    <div style={{ fontSize: '1.2vw' }}>Name</div>
                                    <DateInput
                                         type="text"
                                         id="name"
                                         placeholder="Name"
                                         value={formData.name}
                                         onChange={handleInputChange}
                                    />
                                </div>
                                <div style={{ display: 'flex', flexDirection: "column" }}>
                                    <div style={{ fontSize: '1.2vw' }}>Email</div>
                                    <DateInput
                                       type="email"
                                       id="email"
                                       placeholder="Email"
                                       value={formData.email}
                                       onChange={handleInputChange}
                                    />
                                </div>
                                <div style={{ display: 'flex', flexDirection: "column" }}>
                                    <div style={{ fontSize: '1.2vw' }}>Message</div>
                                    <DateInput1
                                        id="message"
                                        placeholder="Message"
                                        rows="3"
                                        value={formData.message}
                                        onChange={handleInputChange}
                                    />
                                </div>

                                <div style={{ display: 'flex', gap: '1vw', marginTop: '1.8vh' }}>
                                    <Button1 type="button" onClick={handleSubmit} disabled={loading} style={{ width: '100%', textAlign: 'center' }}>
                                        <img src={tyre} alt="Tyre" />
                                        {loading ? <Spinner /> : <span>Submit</span>}
                                    </Button1>
                                </div>
                            </div>
                        </div>
                    </HideContainer>
                )}
            </InnerContainer>
        </Container >
        {hiddenClass ? (<></>): (<Footer/>)}
        </div>
    )
}

export default Aboutus
