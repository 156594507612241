// PatnerProfile.js
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDevice } from '../Components/Context/deviceContext'
import { useNavigate } from 'react-router-dom'
import { LoadingSpinner } from '../Components/LoadingSpinner '
import arrow from '../Components/Assets/arrow.svg'
import Footer from '../Components/fotter'

// Styled components
const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ProfileContainer = styled.div`
  width: 100%;
  margin: 50px auto;
  padding: 3vw;
  border: 1.5px solid #faba49;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 2vw;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Heading = styled.h1`
  margin: 0;
  font-size: 2vw;
  color: #000;
`

const Info = styled.p`
  margin: 5px 0;
  font-size: 1.2vw;
  color: #000;
`

const Image = styled.img`
  width: 100%;
  max-width: 400px;
  height: auto;
  border-radius: 8px;
`

const ServicesList = styled.ul`
  list-style-type: none;
  padding: 0;
`

const Button = styled.button`
  margin-top: 2vh;
  padding: 10px 20px;
  background-color: #fadaa1;
  border: none;
  border-radius: 3vw;
  cursor: pointer;

  &:hover {
    background-color: #fabe49;
  }
`

const PatnerProfile = () => {
  const { phoneNumber } = useDevice()
  const [carShopData, setCarShopData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const navigate = useNavigate()
  const [hiddenClass, setHiddenClass] = useState('')
  const [hiddenClassmobile, setHiddenClassmobile] = useState('')

  const handleClick = () => {
    navigate(-1)
  }

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1200) {
        setHiddenClass('hidden')
      } else {
        setHiddenClass('')
      }
    }

    window.addEventListener('resize', handleResize)
    handleResize() // Initial check

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    const handleResizemobile = () => {
      if (window.innerWidth < 500) {
        setHiddenClassmobile('hidden')
      } else {
        setHiddenClassmobile('')
      }
    }

    window.addEventListener('resize', handleResizemobile)
    handleResizemobile() // Initial check

    return () => {
      window.removeEventListener('resize', handleResizemobile)
    }
  }, [])

  useEffect(() => {
    const fetchCarShopData = async () => {
      try {
        const response = await fetch(
          `https://www.dsctech.in/carshops/owner/${phoneNumber}/`,
        )
        if (!response.ok) {
          throw new Error('Failed to fetch car shop data')
        }
        const data = await response.json()
        setCarShopData(data)
      } catch (err) {
        setError(err.message)
      } finally {
        setLoading(false)
      }
    }

    fetchCarShopData()
  }, [phoneNumber])

  if (loading) {
    return <LoadingSpinner />
  }

  if (error) {
    return <div>Error: {error}</div>
  }

  return (
    <div>
      <Container>
      {hiddenClass ? (
        <div style={{ width: '88%', marginLeft: 'auto', marginRight: 'auto' }}>
          <div
            onClick={handleClick}
            style={{
              height: hiddenClassmobile ? '5vh' : '7vh',
              width: hiddenClassmobile ? '5vh' : '7vh',
              borderRadius: '50%',
              backgroundColor: '#FABA49',
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              marginTop: '6vh',
            }}
          >
            <img
              src={arrow}
              alt="arrow"
              height="50%"
              style={{
                transform: 'rotate(-180deg)',
              }}
            />
          </div>
          <div>
            <div style={{ height: '10%', marginBottom: '2vh', marginTop:'6vh' , marginLeft:'1vw'}}>
              {carShopData.upload_carshop_image && (
                <Image
                  src={`https://www.dsctech.in${carShopData.upload_carshop_image}`}
                  alt={`${carShopData.shop_name} Image`}
                />
              )}
            </div>
            <div style={{fontSize:"6vw", fontWeight:'600'}}>{carShopData.shop_name}</div>
            <div style={{fontSize:'5vw'}}>
              <strong>Owner Name:</strong> {carShopData.owner_name}
            </div >
            <div style={{fontSize:'5vw'}} >
              <strong>Phone:</strong> {carShopData.phone1}
            </div>
            <div style={{fontSize:'5vw'}}>
              <strong>Address:</strong> {carShopData.address}
            </div>
            <div style={{fontSize:'5vw'}}>
              <strong>Latitude:</strong> {carShopData.latitude}
            </div>
            <div style={{fontSize:'5vw'}}>
              <strong>Longitude:</strong> {carShopData.longitude}
            </div>
            <div style={{fontSize:'5vw'}}>
              <strong>Opening Time:</strong> {carShopData.opening_time}
            </div>
            <div style={{fontSize:'5vw'}}>
              <strong>Closing Time:</strong> {carShopData.closing_time}
            </div>

            <div style={{ marginTop: '2vh', marginBottom: '0vh',fontSize:'5vw' }}>
              <strong>Services:</strong>
            </div>
            {carShopData.services.length > 0 ? (
              <ServicesList>
                {carShopData.services.map((service, index) => (
                  <div key={index}  style={{fontSize:'5vw'}}>
                    {service.service_name} - {service.cost} -{' '}
                    {service.description}
                  </div>
                ))}
              </ServicesList>
            ) : (
              <div style={{fontSize:'5vw'}} >No services available.</div>
            )}
            <Button onClick={() => navigate('/addownerdetais')}>Edit</Button>
          </div>
        </div>
      ) : (
        <div>
          {carShopData ? (
            <ProfileContainer>
              <div style={{ height: '20%', marginBottom: '2vh' }}>
                {carShopData.upload_carshop_image && (
                  <Image
                    src={`https://www.dsctech.in${carShopData.upload_carshop_image}`}
                    alt={`${carShopData.shop_name} Image`}
                  />
                )}
              </div>
              <Heading>{carShopData.shop_name}</Heading>
              <Info>
                <strong>Owner Name:</strong> {carShopData.owner_name}
              </Info>
              <Info>
                <strong>Phone:</strong> {carShopData.phone1}
              </Info>
              <Info>
                <strong>Address:</strong> {carShopData.address}
              </Info>
              <Info>
                <strong>Latitude:</strong> {carShopData.latitude}
              </Info>
              <Info>
                <strong>Longitude:</strong> {carShopData.longitude}
              </Info>
              <Info>
                <strong>Opening Time:</strong> {carShopData.opening_time}
              </Info>
              <Info>
                <strong>Closing Time:</strong> {carShopData.closing_time}
              </Info>

              <div style={{ marginTop: '2vh', marginBottom: '0vh' }}>
                <strong>Services:</strong>
              </div>
              {carShopData.services.length > 0 ? (
                <ServicesList>
                  {carShopData.services.map((service, index) => (
                    <Info key={index}>
                      {service.service_name} - {service.cost} -{' '}
                      {service.description}
                    </Info>
                  ))}
                </ServicesList>
              ) : (
                <Info>No services available.</Info>
              )}
              <Button onClick={() => navigate('/addownerdetais')}>Edit</Button>
            </ProfileContainer>
          ) : (
            <Info>No car shop data available.</Info>
          )}
        </div>
      )}
    </Container>
    {hiddenClass ? (<></>): (<Footer/>)}
    </div>
  )
}

export default PatnerProfile
